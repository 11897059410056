import React from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { MdOutlinePictureAsPdf } from "react-icons/md";

import { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Dialog, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CustomerCreation from "../../Forms/CustomerCreation";
import VendorCreation from "../../Forms/VendorCreation";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import { api } from "../../api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VendorReport = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editVendor, setVendor] = useState();
  const [vendorDetail, setVendorDetail] = useState([]);
  const [isActiveVendor, setIsActiveVendor] = useState();
  const [editData, setEditData] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
    density: "compact",
  });

  const handleOpen = (data) => {
    setOpen(true);
    setEditData(data);
  };
  const handleClose = () => setOpen(false);

  const handleEdit = (rowData) => {
    //  console.log(rowData);
    setVendor(rowData);
    setVendorDetail(rowData);
  };
  // console.log(editVendor?.item?.customer_id)

  // const findVendor=async()=>{
  //   if(editVendor?.vendor_id){
  //     const id=editVendor.vendor_id;
  //   const vendor=await axios.get(`${api.api}/vendor/findOneVendor`,{params:{id}});
  //   console.log(vendor);
  //   setVendorDetail(vendor.data.vendor);
  //   }else{
  //     // console.log(editVendor);
  //   }
  // }

  const handleDelete = async (rowData) => {
    setIsActiveVendor(rowData.is_active);
    const status = rowData.is_active == true ? false : true;
    setIsActiveVendor(status);
    const id = rowData.vendor_id;
    // console.log(rowData);
    // console.log(id);
    const vendorActive = await axios.post(`${api.api}/vendor/isActiveVendor`, {
      status,
      id,
    });
    // console.log(vendorActive);
    setVendorDetail(rowData.vendor_id);
  };

  const vendorData = async () => {
    const apiData = await axios.get(`${api.api}/vendor/allVendor`);
    const value = apiData.data.vendor;
    //   const database=value.map(item=>{
    //     return ({item,'reward':item?.rewards[0] ||[]})
    //   })
    setData(value);
    // console.log(value);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    // columnHelper.accessor('vendor_id', {
    //   header: 'ID',
    //   size: 40,
    // }),
    columnHelper.accessor("name", {
      header: "Vendor Name",
      size: 40,
    }),
    columnHelper.accessor("phone_number", {
      header: "Mobile No",
      size: 40,
    }),
    columnHelper.accessor("address", {
      header: "Address",
      size: 40,
    }),
    //   columnHelper.accessor('item.city', {
    //     header: 'City',
    //   }),
    //   columnHelper.accessor('reward.total_points', {
    //     header: 'Reward Points',
    //     size: 220,
    //   }),
    columnHelper.accessor("is_active", {
      accessor: "action",
      header: "Action",
      size: 40,
      Cell: ({ row }, rowIndex) => {
        // console.log(row);
        return (
          <Box>
            {/* {row.original.item.is_active === true &&  */}
            <IconButton
              variant="outlined"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
            >
              <VisibilityIcon
                className="text-primary fs-6"
                onClick={() => handleOpen(row)}
              />
            </IconButton>
            {row.original.is_active === true && (
              <IconButton
                variant="outlined"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                onClick={() => handleEdit(row.original)}
              >
                <EditIcon className="fs-6" />
              </IconButton>
            )}
            <IconButton
              variant="outlined"
              data-toggle="tooltip"
              data-placement="bottom"
              title={row.original.is_active == true ? "Delete" : "Active"}
              onClick={() => handleDelete(row.original)}
            >
              {row.original.is_active === true ? (
                <CancelIcon className="text-danger fs-6" />
              ) : (
                <CheckCircleIcon className="text-success fs-6" />
              )}
            </IconButton>
          </Box>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const value = rowData.map((item) => {
      return {
        ID: item.vendor_id,
        "Vendor Name": item.name,
        "Mobile No": item.phone_number,
        Address: item.address,
      };
    });
    const csv = generateCsv(csvConfig)(value);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const value = data.map((item) => {
      return {
        ID: item.vendor_id,
        "Vendor Name": item.name,
        "Mobile No": item.phone_number,
        Address: item.address,
      };
    });
    const csv = generateCsv(csvConfig)(value);
    download(csvConfig)(csv);
  };

  const handleExportRowsPDF = (rows) => {
    const rowData = rows.map((row) => row.original);
    const value = rowData.map((item) => {
      return {
        ID: item.vendor_id,
        "Vendor Name": item.name,
        "Mobile No": item.phone_number,
        Address: item.address,
      };
    });
    const doc = new jsPDF();
    // const doc = new jsPDF({
    //   orientation: 'landscape', // Set the orientation to landscape
    // });
    const tableData = value.map((row) => Object.values(row));
    const tableHeaders = value.length > 0 ? Object.keys(value[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });
    // console.log(rowData);
    doc.save("Vendor_Report.pdf");
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    // enableRowSelection: true,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          Export
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          Filter
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRowsPDF(table.getPrePaginationRowModel().rows)
          }
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          Filter
        </Button>
        <Button>
          <Link to="/home/vendorCreation">Create</Link>
        </Button>
      </Box>
    ),
  });
  //

  // console.log(vendorDetail);
  useEffect(() => {
    vendorData();
  }, []);

  useEffect(() => {
    vendorData();
  }, [vendorDetail]);

  useEffect(() => {
    vendorData();
  }, [isActiveVendor]);

  let navigate = useNavigate();
  useEffect(() => {
    if (editVendor == undefined) {
      navigate("/home/vendorsReport");
    }
  }, [editVendor]);

  return (
    <div>
      {editVendor ? (
        <div>
          <div className="d-flex col-12 justify-content-end">
            <button
              className="btn btn-primary col-2"
              onClick={() => {
                setVendor();
              }}
            >
              Back
            </button>
          </div>
          <VendorCreation
            vendorDetail={vendorDetail}
            setVendorDetail={setVendorDetail}
            back={setVendor}
          />{" "}
        </div>
      ) : (
        <div>
          <h1 className="text-center">Vendor</h1>
          <MaterialReactTable table={table} />
        </div>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <DialogContent>
              <button
                onClick={handleClose}
                className="btn text-dark bg-white btn-outline-light fs-5 col-2 p-2 m-0 mt-2"
                style={{ position: "absolute", top: "1px", right: "1px" }}
              >
                X
              </button>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Vendor name"
                type="text"
                fullWidth
                defaultValue={editData.original.name}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="Vendor Mobile No"
                type="email"
                fullWidth
                defaultValue={editData.original.phone_number}
                InputProps={{
                  readOnly: true,
                }}
              />
              {/* <TextField
            margin="dense"
            id="mobile"
            label="Customer Address"
            type="address"
            fullWidth
            defaultValue={row.original.item.address}
            InputProps={{
              readOnly: true,
            }}
          /> */}
              <TextField
                margin="dense"
                id="mobile"
                label="Vendor Address"
                type="city"
                fullWidth
                defaultValue={editData.original.address}
                InputProps={{
                  readOnly: true,
                }}
              />
              {/* <TextField
            margin="dense"
            id="mobile"
            label="Customer Reward Points"
            type="text"
            fullWidth
            defaultValue={row.original.item.city}
            InputProps={{
              readOnly: true,
            }}
          /> */}
              {/* <Button onClick={()=>{console.log(row.original.item)}}>click</Button> */}
            </DialogContent>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default VendorReport;
