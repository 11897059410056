import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { api } from "../../api";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { TextField } from "@mui/material";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes, getMinutes, getHours, format } from "date-fns";

const Expenses = ({ expensesDetail, back }) => {
  const [expenses, setExpenses] = useState([
    {
      bookingDate: "" ? "" : new Date(),
      expType: "",
      vehicleNumber: "",
      expAmount: "",
      expNotes: "",
    },
  ]);
  const [expenseType, setExpenseType] = useState();
  const [expensesError, setExpensesError] = useState({
    date: "",
    type: "",
    number: "",
    amount: "",
    notes: "",
  });

  const navigate = useNavigate();

  const [driverList, setDriverList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [expenseUpdate, setExpensesUpdate] = useState(false);

  const [show, setShow] = useState(false);
  const [inputVehicleNumber, setInputVehicleNumber] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    const database = await axios.get(`${api.api}/expenses/expensesSelection`);
    // console.log(database.data);
    // setDriverList(database?.data?.driver);
    setCustomerList(database?.data?.expenses);
    setVehicleList(database?.data?.vehicle);
    // if(expensesDetail.id>0){
    //   updateExpensesDetail();
    // }
  };

  const updateExpensesDetail = () => {
    // console.log(expensesDetail);
    // console.log(expensesDetail.Vehicle,expensesDetail['Vehicle'])
    if (expensesDetail?.id > 0) {
      // navigate("/home/ExpensesUpdate");
      let value = [...expenses];
      value[0].bookingDate = new Date(expensesDetail.expenses_date);
      value[0].expType = expensesDetail.expenses_type;
      value[0].vehicleNumber = expensesDetail.Vehicle;
      value[0].expAmount = expensesDetail.expenses_amount;
      value[0].expNotes = expensesDetail.expenses_notes;
      setExpenses(value);
      setInputVehicleNumber(expensesDetail.Vehicle?.vehicle_number);
      // console.log(expensesDetail.Vehicle?.vehicle_number,"update value");
      // console.log(expensesDetail.Vehicle);
    }
  };

  const createExpenseType = async () => {
    if (expenseType.length > 2) {
      const expenses = await axios.post(
        `${api.api}/expenses/createExpensesType`,
        { expenseType }
      );
      setExpenseType("");
      handleClose();
      fetchData();
    } else {
      alert("Please Enter Valid Expenses Type");
    }
  };

  const expensesSubmit = async () => {
    validation();
    const expenseDate = expenses[0].bookingDate;
    const expenseType = expenses[0].expType;
    const vehicleNo = expenses[0].vehicleNumber;
    const expenseAmount = expenses[0].expAmount;
    const expenseNote = expenses[0].expNotes;
    const id = expensesDetail?.id ? expensesDetail?.id : 0;
    // console.log(id);
    if (id > 0) {
      if (expenses[0].bookingDate.toString().length == 55) {
        if (expenses[0].expType.id > 0) {
          if (
            (expenses[0].expType.expenses == "Fuel Expenses" &&
              expenses[0].vehicleNumber.vehicle_id > 0) ||
            (expenses[0].expType.expenses == "Fastag Expenses" &&
              expenses[0].vehicleNumber.vehicle_id > 0)
          ) {
            if (Number(expenses[0].expAmount) > 0) {
              if (expenses[0].expNotes.length > 4) {
                const expenses = await axios.post(
                  `${api.api}/expenses/expensesUpdate`,
                  {
                    expenseDate,
                    expenseType,
                    vehicleNo,
                    expenseAmount,
                    expenseNote,
                    id,
                  }
                );
                setExpenses([
                  {
                    bookingDate: "",
                    expType: "",
                    vehicleNumber: "",
                    expAmount: "",
                    expNotes: "",
                  },
                ]);
                // console.log(expenses);
                alert("Success");
                back();
                navigate("/home/ExpensesReport");
              } else {
                alert("Please Enter Valid Details");
              }
            } else {
              alert("Please Enter Valid Details");
            }
          } else if (expenses[0].expType.expenses !== "Fuel Expenses") {
            if (expenses[0].expType.expenses !== "Fastag Expenses") {
              if (Number(expenses[0].expAmount) > 0) {
                if (expenses[0].expNotes.length > 4) {
                  // let vehicleNo="";
                  // console.log({expenseDate,expenseType,vehicleNo,expenseAmount,expenseNote,id});
                  const expenses = await axios.post(
                    `${api.api}/expenses/expensesUpdate`,
                    {
                      expenseDate,
                      expenseType,
                      vehicleNo,
                      expenseAmount,
                      expenseNote,
                      id,
                    }
                  );
                  setExpenses([
                    {
                      bookingDate: "",
                      expType: "",
                      vehicleNumber: "",
                      expAmount: "",
                      expNotes: "",
                    },
                  ]);
                  // console.log(expenses);
                  alert("Success");
                  back();
                  navigate("/home/ExpensesReport");
                } else {
                  alert("Please Enter Valid Details");
                }
              } else {
                alert("Please Enter Valid Details");
              }
            } else {
              alert("Please Enter Valid Details");
            }
          } else {
            alert("Please Enter Valid Details");
          }
        } else {
          alert("Please Enter Valid Details");
        }
      } else {
        alert("Please Enter Valid Details");
      }
    } else if (!id) {
      if (expenses[0].bookingDate.toString().length == 55) {
        if (expenses[0].expType.id > 0) {
          if (
            (expenses[0].expType.expenses == "Fuel Expenses" &&
              expenses[0].vehicleNumber.vehicle_id > 0) ||
            (expenses[0].expType.expenses == "Fastag Expenses" &&
              expenses[0].vehicleNumber.vehicle_id > 0)
          ) {
            if (Number(expenses[0].expAmount) > 0) {
              if (expenses[0].expNotes.length > 4) {
                const expenses = await axios.post(
                  `${api.api}/expenses/expensesBooking`,
                  {
                    expenseDate,
                    expenseType,
                    vehicleNo,
                    expenseAmount,
                    expenseNote,
                  }
                );
                setExpenses([
                  {
                    bookingDate: "",
                    expType: "",
                    vehicleNumber: "",
                    expAmount: "",
                    expNotes: "",
                  },
                ]);
                // console.log(expenses);
                alert("Success");
                navigate("/home/ExpensesReport");
              } else {
                alert("Please Enter Valid Details");
              }
            } else {
              alert("Please Enter Valid Details");
            }
          } else if (expenses[0].expType.expenses !== "Fuel Expenses") {
            if (expenses[0].expType.expenses !== "Fastag Expenses") {
              if (Number(expenses[0].expAmount) > 0) {
                if (expenses[0].expNotes.length > 4) {
                  // let vehicleNo="";
                  const expenses = await axios.post(
                    `${api.api}/expenses/expensesBooking`,
                    {
                      expenseDate,
                      expenseType,
                      vehicleNo,
                      expenseAmount,
                      expenseNote,
                    }
                  );
                  setExpenses([
                    {
                      bookingDate: "",
                      expType: "",
                      vehicleNumber: "",
                      expAmount: "",
                      expNotes: "",
                    },
                  ]);
                  // console.log(expenses);
                  alert("Success");
                  navigate("/home/ExpensesReport");
                } else {
                  alert("Please Enter Valid Details");
                }
              } else {
                alert("Please Enter Valid Details");
              }
            } else {
              alert("Please Enter Valid Details");
            }
          } else {
            alert("Please Enter Valid Details");
          }
        } else {
          alert("Please Enter Valid Details");
        }
      } else {
        alert("Please Select Expense Date");
      }
    }
    // const expenses=await axios.post('',)
  };

  const handleCustomer = (e, n) => {
    // console.log(e,n,"handle expense");
    if (n && n.id) {
      let value = [...expenses];
      value[0].expType = n;
      setExpenses(value);
    } else {
      let value = [...expenses];
      value[0].expType = "";
      setExpenses(value);
    }
  };

  const handleVehicle = (e, n, event) => {
    // if(!e) return
    if (n && n.vehicle_id) {
      // console.log("inside if")
      let value = [...expenses];
      value[0].vehicleNumber = n;
      setExpenses(value);
    } else {
      // console.log("inside else")
      let value = [...expenses];
      value[0].vehicleNumber = "";
      setExpenses(value);
    }
  };

  const advanceValue = () => {
    if (expenses[0].paymentType == "NoPayment") {
      if (expenses[0].expAmount > 0) {
        let value = [...expenses];
        value[0].expAmount = 0;
        setExpenses(value);
      }
    }
  };

  // console.log(expensesDetail);

  const validation = () => {
    const expenseDate = expenses[0].bookingDate;
    const expenseType = expenses[0].expType;
    const vehicleNo = expenses[0].vehicleNumber;
    const expenseAmount = expenses[0].expAmount;
    const expenseNote = expenses[0].expNotes;
    const id = expensesDetail?.id ? expensesDetail?.id : 0;

    let value = { ...expensesError }; // Initialize the changes object

    if (!(expenses[0].bookingDate.toString().length === 55)) {
      value.date = "date";
    } else {
      value.date = "";
    }

    if (!(expenses[0].expType?.id > 0)) {
      value.type = "type";
    } else {
      value.type = "";
    }

    if (!(expenses[0].vehicleNumber?.vehicle_id > 0)) {
      value.number = "number";
    } else {
      value.number = "";
    }

    if (!(expenses[0].expAmount > 0)) {
      value.amount = "amount";
    } else {
      value.amount = "";
    }

    if (!(expenses[0].expNotes.trim().length >= 5)) {
      value.notes = "notes";
    } else {
      value.notes = "";
    }

    setExpensesError(value); // Update the state once with all changes
  };

  // console.log(expensesError);

  useEffect(() => {
    updateExpensesDetail();
    fetchData();
  }, []);

  // useEffect(()=>{
  //   updateExpensesDetail();
  // },[expensesDetail])

  // console.log(expenses[0].vehicleNumber);
  //  console.log(customerList,"customerList");
  //  console.log(vehicleList,"list vehicle")
  //  console.log(inputVehicleNumber,"input Vehicle")

  const value = localStorage.getItem("taxinow")
    ? localStorage.getItem("taxinow")
    : "";

  useEffect(() => {
    if (localStorage.getItem("taxinow")) {
    } else {
      navigate("/home/customerReport");
    }
  }, [value]);

  // console.log(expenses);

  return (
    <div className="col-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h1 className="page-title text-center fs-1">
            {expensesDetail?.id ? "Expenses Update" : "Expenses"}
          </h1>
          <div className="forms-sample pt-2 ">
            <div className="form-group row mx-1">
              <label
                for="exampleInputPassword4"
                className="text-black fs-5 p-0"
              >
                Expenses Date
              </label>
              {/* <input
                type="date"
                className="form-control fs-6"
                id="exampleInputPassword4"
                value={expenses[0].bookingDate}
                onChange={(e) => {
                  let value = [...expenses];
                  value[0].bookingDate = e.target.value;
                  setExpenses(value);
                }}
              /> */}
              <DatePicker
                className="form-control fs-6"
                selected={expenses[0].bookingDate}
                onChange={(date) => {
                  let value = [...expenses];
                  value[0].bookingDate = date;
                  setExpenses(value);
                }}
                showTimeSelect={false}
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy"
              />
              {expensesError.date == "date" && (
                <p className="text-danger">Please Select Date </p>
              )}
            </div>
            <div className="form-group">
              <label for="exampleInputName1" className="text-black fs-5">
                Expenses Type
                <button
                  className="mx-2"
                  style={{
                    backgroundColor: "#19c719e8",
                    color: "white",
                    border: "1px solid green",
                  }}
                  onClick={handleShow}
                >
                  +
                </button>
                <Modal show={show} onHide={() => handleClose()}>
                  <Modal.Header closeButton>
                    <Modal.Title>Create Expenses Type</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Expenses Type</Form.Label>
                        <Form.Control
                          type="text"
                          className="pl-2"
                          autoFocus
                          value={expenseType}
                          onChange={(e) => {
                            setExpenseType(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
                    <button
                      className="btn col-4 col-xl-3"
                      variant="primary"
                      onClick={() => {
                        createExpenseType();
                      }}
                    >
                      Submit
                    </button>
                  </Modal.Footer>
                </Modal>
              </label>
              <Autocomplete
                className="form-control fs-6"
                id="exampleInputName1"
                sx={{
                  display: "inline-block",
                  "& input": {
                    width: 200,
                    bgcolor: "background.paper",
                    color: (theme) =>
                      theme.palette.getContrastText(
                        theme.palette.background.paper
                      ),
                  },
                }}
                options={customerList}
                onChange={(event, newValue) => handleCustomer(event, newValue)}
                value={expenses[0].expType.expenses}
                getOptionLabel={(option) => option.expenses || ""}
                inputValue={expenses[0].expType.expenses}
                onInputChange={(event, newValue) =>
                  handleCustomer(event, newValue)
                }
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      id="exampleInputName1"
                      style={{ border: "none" }}
                      {...params.inputProps}
                      value={expenses[0].expType.expenses}
                    />
                  </div>
                )}
              />
              {expensesError.type == "type" && (
                <p className="text-danger">
                  Please Select Valid Expenses Type{" "}
                </p>
              )}
            </div>
            {(expenses[0].expType?.expenses == "TOLL CHARGES" ||
              expenses[0].expType?.expenses == "General Expenses" ||
              expenses[0].expType?.expenses == "VECHILE MAINTAINCE" ||
              expenses[0]?.expType?.expenses == "VECHILE INSURANCE") && (
              <div className="form-group">
                <label for="exampleInputName1" className="text-black fs-5">
                  Vehicle No
                </label>
                <Autocomplete
                  className="form-control fs-6"
                  id="exampleInputName1"
                  sx={{
                    display: "inline-block",
                    "& input": {
                      width: 200,
                      bgcolor: "background.paper",
                      color: (theme) =>
                        theme.palette.getContrastText(
                          theme.palette.background.paper
                        ),
                    },
                  }}
                  options={vehicleList}
                  onChange={(event, newValue) =>
                    handleVehicle(event, newValue, "onchange event")
                  }
                  value={expenses[0].vehicleNumber}
                  getOptionLabel={(option) => option.vehicle_number || ""}
                  inputValue={inputVehicleNumber}
                  onInputChange={(event, newValue) =>
                    setInputVehicleNumber(newValue)
                  }
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        type="text"
                        id="exampleInputName1"
                        style={{ border: "none" }}
                        {...params.inputProps}
                        value={inputVehicleNumber}
                      />
                    </div>
                  )}
                />
                {expensesError.number == "number" && (
                  <p className="text-danger">Please Select Vehicle No </p>
                )}
                {/* <Autocomplete
        value={expenses[0].vehicleNumber}

        onChange={(event, newValue) =>handleVehicle(event, newValue,"onchange event")}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
        inputValue={inputVehicleNumber}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        onInputChange={(event, newValue) => setInputVehicleNumber(newValue)}
        getOptionLabel={(option) => option.vehicle_number || ""}

        id="controllable-states-demo"
        options={vehicleList}
        sx={{ width: 300 }}
        renderInput={(params) =>{
// console.log(params,"params")
        return <TextField {...params} label="Controllable" />}
        }
      /> */}
                {expenses[0].vehicleNumber?.vehicle_model && (
                  <p className="text-success">
                    Vehicle Model:
                    <span className="text-black">
                      {expenses[0].vehicleNumber?.vehicle_model}
                    </span>
                  </p>
                )}
              </div>
            )}
            {/* {expenses[0].expType?.expenses == "Fuel Expenses" && <div className="form-group">
              <label for="exampleInputName1" className="text-black fs-5">
                Vehicle No
              </label>
               <Autocomplete
                className="form-control fs-6"
                id="exampleInputName1"
                sx={{
                  display: "inline-block",
                  "& input": {
                    width: 200,
                    bgcolor: "background.paper",
                    color: (theme) =>
                      theme.palette.getContrastText(
                        theme.palette.background.paper
                      ),
                  },
                }}
                options={vehicleList}
                onChange={(event, newValue) =>handleVehicle(event, newValue)}
                value={expenses[0].vehicleNumber.vehicle_number}
                getOptionLabel={(option) => option.vehicle_number || ""}
                
               inputValue={expenses[0].vehicleNumber.vehicle_number}
               onInputChange={(event, newValue) => handleVehicle(event, newValue)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      id="exampleInputName1"
                      style={{ border: "none" }}
                      {...params.inputProps}
                      value={expenses[0].vehicleNumber.vehicle_number}
                    />
                  </div>
                )}
              />
               {expenses[0].vehicleNumber?.vehicle_model&& <p className="text-success">Vehicle Model:<span className="text-black">{expenses[0].vehicleNumber?.vehicle_model}</span></p>}
            </div>} */}
            <div className="form-group">
              <label for="exampleInputPassword4" className="text-black fs-5">
                Expenses Amount
              </label>
              <input
                type="number"
                className="form-control fs-6 text-start"
                id="exampleInputPassword4"
                value={expenses[0].expAmount}
                onChange={(e) => {
                  let value = [...expenses];
                  const number = (value[0].expAmount = e.target.value);
                  value[0].expAmount = number.replace(
                    /[^0-9]|(?<=\d)-(?=\d)/g,
                    ""
                  );
                  setExpenses(value);
                }}
                disabled={expenses[0].paymentType === "NoPayment"}
              />
              {expensesError.amount == "amount" && (
                <p className="text-danger">Please Enter Expenses Amount </p>
              )}
            </div>
            <div className="form-group">
              <label for="exampleInputEmail3" className="text-black fs-5">
                Notes
              </label>
              <input
                type="text"
                className="form-control fs-6"
                id="exampleInputEmail3"
                value={expenses[0].expNotes}
                onChange={(e) => {
                  let value = [...expenses];
                  value[0].expNotes = e.target.value;
                  setExpenses(value);
                }}
              />
              {expensesError.notes == "notes" && (
                <p className="text-danger">Please Enter Notes </p>
              )}
            </div>
            <div className="d-flex col-12  justify-content-center">
              <div className="col-5 col-xl-3">
                <button
                  // type="submit"
                  onClick={() => {
                    expensesSubmit();
                  }}
                  className="btn btn-primary mr-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
