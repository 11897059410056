import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { api } from "../api";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { setHours, setMinutes } from 'date-fns';
import { setHours, setMinutes, getMinutes, getHours, format } from "date-fns";

const AdvancePayment = ({ paymentDetails, setEditPayment, back }) => {
  const [payment, setPayment] = useState([
    {
      bookingId: "",
      customerId: "",
      paymentDate: "",
      paymentType: "",
      cash_advance: "",
      bank_advance: "",
      cashPayment: "",
      bankPayment: "",
      customerName: "",
      billAmount: "",
      advancePaid: "",
      balanceAmount: "",
      payableAmount: "",
      outstandingAmount: "",
    },
  ]);
  const [paymentError, setPaymentError] = useState({
    date: "",
    type: "",
    amount: "",
  });

  const navigate = useNavigate();
  const [paymentId, setPaymentId] = useState("");

  // console.log(paymentDetails);

  const updateValue = () => {
    if (paymentDetails?.item) {
      // console.log(paymentDetails);
      let value = [...payment];

      value[0].bookingId = paymentDetails?.item?.booking_id;
      value[0].customerId = paymentDetails?.item?.customer.customer_id;
      value[0].customerName = paymentDetails?.item?.customer.customer_name;
      value[0].billAmount = paymentDetails?.item?.bill_amount;
      value[0].cash_advance = paymentDetails?.payment?.cash_advance;
      value[0].bank_advance = paymentDetails?.payment?.bank_advance;
      value[0].cashPayment = paymentDetails?.payment?.cash_payment;
      value[0].bankPayment = paymentDetails?.payment?.bank_payment;
      value[0].advancePaid = paymentDetails?.item?.advance;
      value[0].balanceAmount = paymentDetails?.payment?.to_be_paid;
      value[0].payableAmount = "";
      value[0].outstandingAmount = new Date(paymentDetails?.item?.dropping_time)
        .toISOString()
        .slice(0, 16);
      setPayment(value);
    }
  };

  const PaymentSubmit = async () => {
    // console.log("work");
    if (Number(payment[0]?.bookingId) > 0) {
      // console.log("came");
      const id = payment[0].bookingId;
      const customerId = payment[0].customerId;
      const paymentDate = payment[0].paymentDate;
      const paymentType = payment[0].paymentType;
      const customerName = payment[0].customerName;
      const billAmount = payment[0].billAmount;
      const cashAdvance = payment[0].cash_advance;
      const bank_advance = payment[0].bank_advance;
      const CashPayment = payment[0].cashPayment;
      const BankPayment = payment[0].bankPayment;
      const balanceAmount = payment[0].balanceAmount;
      const payableAmount = payment[0].payableAmount;
      const outstandingAmount = payment[0].outstandingAmount;

      if (outstandingAmount >= 0) {
        if (Number(cashAdvance) >= 0 && Number(bank_advance) >= 0) {
          // console.log("function");
          const customerPayment = await axios.post(
            `${api.api}/booking/updateBookingAdvance`,
            {
              paymentDate,
              payableAmount,
              billAmount,
              id,
              customerId,
              cashAdvance,
              bank_advance,
              CashPayment,
              BankPayment,
              balanceAmount,
              outstandingAmount,
            }
          );
          // console.log(customerPayment);
          back("");
          alert("Paid");
        }
      } else if (outstandingAmount < 0) {
        alert("Enter Valid Amount");
      }
    }
  };

  const balanceAmountCalculate = () => {
    if (payment[0].cashPayment >= 0 || payment[0].bankPayment >= 0) {
      navigate("/home/payment/updateAdvance");
      const cashAdvance = payment[0].cash_advance;
      const bankAdvance = payment[0].bank_advance;
      const cash = payment[0]?.cashPayment;
      const bank = payment[0]?.bankPayment;
      const balance = payment[0]?.billAmount;
      let value = [...payment];
      value[0].outstandingAmount =
        Number(balance) -
        Number(
          Number(cash) +
            Number(bank) +
            Number(cashAdvance) +
            Number(bankAdvance)
        );
      setPayment(value);
      // console.log(payable);
      // console.log(value);
    }
  };

  // const validation = () => {
  //   const id = payment[0].bookingId;
  //   const customerId = payment[0].customerId;
  //   const paymentDate = payment[0].paymentDate;
  //   const paymentType = payment[0].paymentType;
  //   const customerName = payment[0].customerName;
  //   const billAmount = payment[0].billAmount;
  //   const advancePaid = payment[0].advancePaid;
  //   const balanceAmount = payment[0].balanceAmount;
  //   const payableAmount = payment[0].payableAmount;
  //   const outstandingAmount = payment[0].outstandingAmount;

  //   let value = { ...paymentError };
  //   if (payment[0].paymentDate.toString().length == 55) {
  //     value.date = "";
  //   } else {
  //     value.date = "date";
  //   }

  //   if (payment[0].paymentType.length > 3) {
  //     value.type = "";
  //   } else {
  //     value.type = "type";
  //   }

  //   if (Number(payment[0].payableAmount) > 0) {
  //     value.amount = "";
  //   } else if (payment[0].outstandingAmount < 0) {
  //     value.amount = "amount";
  //   } else {
  //     value.amount = "amount";
  //   }

  //   if (Number(payment[0].cashPayment) > 0 || payment[0].bankPayment > 0) {
  //     value.amount = "";
  //   } else if (payment[0].outstandingAmount < 0) {
  //     value.amount = "amount";
  //   } else {
  //     value.amount = "amount";
  //   }
  //   setPaymentError(value);
  // };

  useEffect(() => {
    updateValue();
  }, [paymentDetails]);

  useEffect(() => {
    // fetchData();
    // if(payment[0].payableAmount>=0){
    balanceAmountCalculate();
    // }
  }, [paymentId]);

  useEffect(() => {
    if (payment[0].payableAmount >= 0) {
      balanceAmountCalculate();
    }
  }, [payment[0].bank_advance]);

  useEffect(() => {
    if (payment[0].payableAmount >= 0) {
      balanceAmountCalculate();
    }
  }, [payment[0].cash_advance]);

  // console.log(paymentError);

  return (
    <div className="col-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h1 className="page-title text-center">
            {paymentDetails?.customer_id
              ? "Booking Payment Update"
              : " Advance Update"}
          </h1>
          <div className="forms-sample pt-2">
            <div className="form-group row ">
              <label
                for="exampleInputName1"
                className="text-black fs-5 mt-2 me-2"
              >
                Invoice No
              </label>
              <div className="d-flex ">
                <input
                  className="form-control fs-6 col-3"
                  id="exampleInputName1"
                  type="number"
                  // value={paymentId} onChange={(e)=>{setPaymentId(e.target.value)}}

                  value={payment[0].bookingId}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].bookingId = e.target.value;
                    setPayment(value);
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="row ">
              <div className="form-group col-md-6">
                <label for="exampleInputName1" className="text-black fs-5">
                  Customer Name
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={payment[0].customerName}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].customerName = e.target.value;
                    setPayment(value);
                  }}
                  disabled={payment[0].customerName}
                />
                {payment[0].customerName?.phone_number && (
                  <p className="text-success">
                    Customer Mobile No:
                    <span className="text-black">
                      {payment[0].customerName?.phone_number}
                    </span>
                  </p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label for="exampleInputName1" className="text-black fs-5">
                  Bill Amount
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={payment[0].billAmount}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].billAmount = e.target.value;
                    setPayment(value);
                  }}
                  disabled={payment[0].billAmount}
                />
              </div>
            </div>
            <div className="row ">
              <div className="form-group col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Advance Cash Amount
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={payment[0].cash_advance}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].cash_advance = e.target.value.replace(
                      /[^0-9]|(?<=\d)-(?=\d)/g,
                      ""
                    );
                    setPayment(value);
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Advance Bank Amount
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={payment[0].bank_advance}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].bank_advance = e.target.value.replace(
                      /[^0-9]|(?<=\d)-(?=\d)/g,
                      ""
                    );
                    setPayment(value);
                  }}
                  // disabled={payment[0].advancePaid >= 0}
                />
              </div>
            </div>
            <div className="row ">
              <div className="form-group col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Cash Payment Amount
                </label>
                <input
                  type="number"
                  className="form-control fs-6"
                  id="exampleInputPassword4"
                  value={payment[0].cashPayment}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].cashPayment = e.target.value.replace(
                      /[^0-9]|(?<=\d)-(?=\d)/g,
                      ""
                    );
                    setPayment(value);
                  }}
                  disabled={payment[0].advancePaid >= 0}
                />
                {paymentError.amount == "amount" && (
                  <p className="text-danger p-0">
                    Please Enter Payable Amount{" "}
                  </p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Bank Payment Amount
                </label>
                <input
                  type="number"
                  className="form-control fs-6"
                  id="exampleInputPassword4"
                  value={payment[0].bankPayment}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].bankPayment = e.target.value.replace(
                      /[^0-9]|(?<=\d)-(?=\d)/g,
                      ""
                    );
                    setPayment(value);
                  }}
                  disabled={payment[0].advancePaid >= 0}
                />
                {paymentError.amount == "amount" && (
                  <p className="text-danger p-0">
                    Please Enter Payable Amount{" "}
                  </p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Outstanding Amount
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputPassword4"
                  value={payment[0].balanceAmount}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].balanceAmount = e.target.value;
                    setPayment(value);
                  }}
                  disabled={payment[0].balanceAmount >= 0}
                />
              </div>
              <div className="form-group col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Balance Amount Rs.
                </label>
                <input
                  type="text"
                  className={
                    payment[0].outstandingAmount >= 0
                      ? "form-control fs-4 px-2 text-success"
                      : "form-control fs-4 px-2 text-danger"
                  }
                  id="exampleInputPassword4"
                  value={payment[0].outstandingAmount}
                  onChange={(e) => {
                    let value = [...payment];
                    value[0].outstandingAmount = e.target.value;
                    setPayment(value);
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="d-flex col-12  justify-content-center">
              <div className="col-4">
                <button
                  // type="submit"
                  onClick={() => {
                    PaymentSubmit();
                  }}
                  className="btn btn-primary mr-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancePayment;
