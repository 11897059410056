import { useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
// import { data } from './makeData';

import { api } from "./api";
import axios from "axios";

const Test = () => {
  const [data, setData] = useState([]);

  const values = async () => {
    const vehicle = await axios.get(
      `${api.api}/booking/vehicleIncomeExpensesRpt`
    );
    const booking = vehicle?.data.booking;
    const expenses = vehicle?.data.expenses;

    // console.log(booking);
    // console.log(expenses);
    const database = booking?.map((item) => {
      const vehicleExpenses = expenses.find(
        (expense) => expense.vehicle_id === item.vehicle_id
      );
      // console.log(vehicleExpenses);

      return {
        Vehicel_No: item.vehicle_number,
        Vehicle_Type: item.vehicle_type,
        Vehicel_Model: item.vehicle_model,
        Income: Number(item?.bookings[0].total_bill_amount) || [],
        Booking_Count: Number(item?.bookings[0].booking_count) || [],
        expenses: vehicleExpenses?.expenses[0]?.total_expenses_amount || 0,
      };
    });

    setData(database);
  };

  useEffect(() => {
    values();
  }, []);

  // const averageSalary = useMemo(
  //   () => data.reduce((acc, curr) => acc + curr.Income, 0) ,0,
  //   [],
  // );
  const totalBooking = data.reduce(
    (acc, curr) => acc + parseFloat(curr.Income),
    0
  );

  const totalExpenses = data.reduce(
    (acc, curr) => acc + parseFloat(curr.expenses),
    0
  );

  const BookingCountTotal = data.reduce(
    (acc, curr) => acc + parseFloat(curr.Booking_Count),
    0
  );

  // const maxAge = useMemo(
  //   () => data.reduce((acc, curr) => Math.max(acc, curr.Booking_Count), 0),
  //   [],
  // );

  const columns = useMemo(
    () => [
      {
        header: "Vehicle ",
        accessorKey: "Vehicel_No",
        enableGrouping: true,
      },
      {
        header: "Vehicle Type",
        accessorKey: "Vehicle_Type",
        enableGrouping: true,
      },
      {
        header: "Booking Count",
        accessorKey: "Booking_Count",
        aggregationFn: "min",
        enableGrouping: false,
        Cell: ({ row }, rowIndex) => {
          return Number(row.original.Booking_Count);
        },
        AggregatedCell: ({ cell, table }) => (
          <>
            Oldest by{" "}
            {table.getColumn(cell.row.groupingColumnId ?? "").columnDef.header}:{" "}
            <Box
              sx={{ color: "info.main", display: "inline", fontWeight: "bold" }}
            >
              {cell.getValue()}
            </Box>
          </>
        ),
        Footer: () => (
          <Stack>
            Total Booking:
            <Box color="info.main">{Math.round(BookingCountTotal)}</Box>
          </Stack>
        ),
      },
      {
        header: "Expenses",
        accessorKey: "expenses",
        aggregationFn: "mean",
        AggregatedCell: ({ cell, table }) => (
          <>
            {table.getColumn(cell.row.groupingColumnId ?? "").columnDef.header}:{" "}
            <Box sx={{ color: "success.main", fontWeight: "bold" }}>
              {cell.getValue()?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Box>
          </>
        ),
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </>
        ),
        Footer: () => (
          <Stack>
            Total Expenses:
            <Box color="warning.main">
              {totalExpenses?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Income",
        accessorKey: "Income",
        aggregationFn: "mean",
        AggregatedCell: ({ cell, table }) => (
          <>
            {table.getColumn(cell.row.groupingColumnId ?? "").columnDef.header}:{" "}
            <Box sx={{ color: "success.main", fontWeight: "bold" }}>
              {cell.getValue()?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Box>
          </>
        ),
        //customize normal cell render on normal non-aggregated rows
        Cell: ({ cell }) => (
          <>
            {cell.getValue().toLocaleString("en-US", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </>
        ),
        Footer: () => (
          <Stack>
            Total Income:
            <Box color="success.main">
              {totalBooking.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [totalBooking, BookingCountTotal]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    displayColumnDefOptions: {
      "mrt-row-expand": {
        enableResizing: false,
      },
    },
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    // enableFullScreenToggle: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    // positionToolbarAlertBanner: 'bottom',
    enableFullScreenToggle: false,
    enableGlobalFilter: true,
    enableStickyHeader: true,
    enableGlobalFilter: false,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: "compact",
      expanded: false, //expand all groups by default
      // grouping: ["Vehicle_Type",'Vehicel_No'], //an array of columns to group by by default (can be multiple)
      pagination: { pageIndex: 0, pageSize: 20 },
      // sorting: [{ id: 'Vehicel_No', desc: false }], //sort by state by default
    },
    muiToolbarAlertBannerChipProps: { color: "primary" },
    muiTableContainerProps: { sx: { maxHeight: 700 } },
  });

  return (
    <>
      <h1 className="text-center">Own Income & Expenses </h1>
      <MaterialReactTable table={table} />
    </>
  );
};

export default Test;
