import React, { useEffect, useState } from "react";
import "../../Style/Login_Style/Login_Style.css";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import axios from "axios";
import UseIdleTimeout from "../Idle_Timer";

const Login = () => {
  const [log, setLog] = useState({ userName: "", Password: "" });
  const [showPassword, setShowPassword] = useState(false);

  let navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const userName = log.userName;
      const userPassword = log.Password;

      // console.log(userName.length > 2 && userPassword.length > 2);
      const login = await axios.post(`${api.api}/login`, {
        userName,
        userPassword,
      });
      localStorage.setItem("taxinow", JSON.stringify(login.data.token));
      localStorage.setItem("login", JSON.stringify(login.data.user));
      const expiration = Date.now() + 1080000;
      localStorage.setItem("session", JSON.stringify({ expiration }));
      //   console.log(localStorage.getItem("taxinow"));
      navigate("/home/dashboard");
      // console.log(log);
    } catch (error) {
      // console.error("Login failed:", error);
      alert("Invalid User Name or Password");
    }
  };

  const session = localStorage.getItem("session");

  useEffect(() => {
    if (localStorage.getItem("taxinow")) {
      navigate("/home/dashboard");
      localStorage.setItem("Date", JSON.stringify(new Date()));
    } else {
      navigate("/login");
    }
  }, []);

  // console.log(showPassword);
  return (
    <div className="loginpage row d-flex" style={{ height: "100vh" }}>
      <div className="container-fluid m-0 p-0 row d-flex align-items-center justify-content-center px-5 mt-5">
        <div className="row main-content bg-success text-center p-0 m-0">
          <div className="col-md-4 text-center company__info">
            <span className="company__logo">
              <h2>
                <span className="fa fa-android"></span>
              </h2>
            </span>
            <h4 className="company_title">
              <img
                src={require("../../Images/Car_Logo.jpg")}
                style={{ height: "100%", width: "100%" }}
                alt="Go Taxi"
              />
            </h4>
          </div>
          <div className="col-md-8 col-xs-12 col-sm-12 login_form pt-4">
            <div className="container-fluid">
              <div className="row mt-3">
                <h2>Log In</h2>
              </div>
              <div className="row">
                <div control="" className="form-group">
                  <div className="row">
                    <input
                      type="text"
                      name="username"
                      value={log.userName}
                      onChange={(e) => {
                        let value = { ...log };
                        value.userName = e.target.value;
                        setLog(value);
                      }}
                      id="username"
                      className="form__input"
                      placeholder="Username"
                    />
                  </div>
                  <div className="row d-flex align-items-center">
                    <input
                      type={showPassword ? "Text" : "password"}
                      name="password"
                      value={log.Password}
                      onChange={(e) => {
                        let value = { ...log };
                        value.Password = e.target.value;
                        setLog(value);
                      }}
                      id="password"
                      className="form__input col-10"
                      placeholder="Password"
                    />
                    {showPassword == true ? (
                      <MdOutlineRemoveRedEye
                        className="col-2"
                        onClick={() => {
                          setShowPassword(false);
                        }}
                      />
                    ) : (
                      <FaRegEyeSlash
                        className="col-2"
                        onClick={() => {
                          setShowPassword(true);
                        }}
                      />
                    )}
                  </div>

                  <div className="row d-flex justify-content-center">
                    <input
                      type="submit"
                      value="Submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                      className="btn col-4"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <p>Don't have an account? <a href="#">Register Here</a></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid text-center footer"> */}
      <div className="d-sm-flex justify-content-center justify-content-center">
        <span className=" text-center text-sm-left d-block d-sm-inline-block text-white">
          Copyright {new Date().getFullYear()}{" "}
          <a href="https://techovirish.com" target="_blank">
            Techo Virish Software Solution
          </a>
          . All rights reserved.
        </span>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Login;
