import React, { useEffect, useState } from "react";
import "../../Style/Home_Style/Home_Style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import PerfectScrollbar from "perfect-scrollbar";
import { GiMoneyStack } from "react-icons/gi";
import { IoBarChartSharp } from "react-icons/io5";
import { BsFillFuelPumpDieselFill } from "react-icons/bs";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { MdMenuOpen } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { ImPriceTags } from "react-icons/im";
import { FaFileExport } from "react-icons/fa6";
import { FaSignOutAlt } from "react-icons/fa";
import { IoCarSport } from "react-icons/io5";

import CustomerCreation from "../Forms/CustomerCreation";
import DriverCreation from "../Forms/DriverCreation";
import VendorCreation from "../Forms/VendorCreation";
import VehicleCreation from "../Forms/VehicleCreation";
import Dashboard from "../Dashboard/Dashboard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SiSimpleanalytics } from "react-icons/si";
import CustomerReport from "../Reports/CustomerReport/CustomerReports";
import VendorReport from "../Reports/VendorReport/VendorReport";
import DriverReport from "../Reports/DriverReport/DriverReport";
import VehicleReport from "../Reports/VehicleReport/VehicleReport";
import VendorPayment from "../Reports/VendorReport/VendorPayment";
import { MdHail } from "react-icons/md";
import { FaCarRear } from "react-icons/fa6";
import { FaRegLifeRing } from "react-icons/fa";
import { GrUserSettings } from "react-icons/gr";
import { BsFillCarFrontFill } from "react-icons/bs";
import BookingCreate from "../Booking/Booking";
import { RiGridFill } from "react-icons/ri";
import { IoMdContacts } from "react-icons/io";

import { api } from "../api";
import Booking from "../Booking/BookingReport";
import PaymentUpdate from "../Booking/Payment";
import PaymentReport from "../Booking/PaymentReport";
import Invoice from "../Invoice/invoice";
import DriverAttendance from "../Attendance/DriverAttendance";
import ExpensesReport from "../Reports/Expenses/ExpensesReport";
import Expenses from "../Reports/Expenses/Expenses";
import "../../Style/Home_Style/BreadCrumbs.css";
import RentVehicleReport from "../Reports/VehicleReport/RentVehicleReport";
import QuotationReport from "../Reports/Quotation/QuotationReport";
import Quotation from "../Reports/Quotation/Quotation";
import { IoIosArrowDropdown } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { RiPriceTag2Fill } from "react-icons/ri";
import { RiPriceTag2Line } from "react-icons/ri";
import ConsolidateReport from "../Reports/ConsolidateReport/ConsolidateReport";
import { HiOutlineDocumentReport } from "react-icons/hi";
import ExpensesOneDayReport from "../Reports/Expenses/ExpensesOneDayReport";
import OwnConsolidateReport from "../Reports/ConsolidateReport/OwnConsolidateReport";
import RentBooking from "../Booking/RentBooking";
import OwnBooking from "../Booking/OwnBooking";
import Test from "../test";
import { PiContactlessPaymentFill } from "react-icons/pi";
import AdvanecBooking from "../Booking/Advance";
import AdvancePayment from "../Booking/AdvancePayment";
import { BiSolidSelectMultiple } from "react-icons/bi";
import { PiCarFill } from "react-icons/pi";
import { PiCarProfile } from "react-icons/pi";
import OwnConfirmBooking from "../ConfirmOrder/OwnBooking";
import RentConfirmBooking from "../ConfirmOrder/RentBooking";
import { IoSettings } from "react-icons/io5";
import { BiSolidReport } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";
import OrderCreation from "../Orders/OrderCreation";
import { MdContacts } from "react-icons/md";
import OrderReports from "../Orders/OrderReport";

const Home = () => {
  const [asaidNav, setAsaidNav] = useState(false);
  const [rightAsaidNav, setRightAsaidNav] = useState(false);
  const [pageNavigation, setPageNavigation] = useState(false);
  const [logOut, setLogOut] = useState();
  const [toggle, setToggle] = useState(false);
  const [expensesToggle, setExpensesToggle] = useState(false);
  const [bookingToggle, setBookingToggle] = useState(false);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [dayToDayToggle, setDayToDayToggle] = useState(false);
  const [locate, setLocate] = useState();
  const [Settings, setSettings] = useState(false);
  const [reports, setReports] = useState(false);
  const [paymentNav, setPaymentNav] = useState(false);

  // console.log(bookingToggle);

  const navigate = useNavigate();
  const navigation = useLocation();

  let back = "value";

  function getComponent(setChangeValue, changeValue) {
    let back = "value";
    const session = localStorage.getItem("session");
    const { expiration } = session ? JSON.parse(session) : null;
    if (expiration < Date.now() || expiration == null) {
      navigate("/login");
      localStorage.removeItem("taxinow");
    } else {
      const expiration = Date.now() + 1080000;
      const value = localStorage.getItem("taxinow")
        ? localStorage.setItem("session", JSON.stringify({ expiration }))
        : "";
    }
    if (navigation?.pathname == "/home/OwnVehicleIncomeReport") {
      return <Test />;
    }
    if (navigation?.pathname == "/home/dashboard") {
      return <Dashboard />;
    }
    if (navigation?.pathname == "/home/customerCreation") {
      return <CustomerCreation />;
    }
    if (navigation?.pathname == "/home/vehicleCreation") {
      return <VehicleCreation />;
    }
    if (navigation?.pathname == "/home/vendorCreation")
      return <VendorCreation />;

    if (navigation?.pathname == "/home/driverCreation") {
      return <DriverCreation />;
    }
    if (navigation?.pathname == "/home/customerReport") {
      return <CustomerReport />;
    }
    if (navigation?.pathname == "/home/customerUpdate") {
      return <CustomerReport />;
    }
    if (navigation?.pathname == "/home/vendorsReport") {
      return <VendorReport />;
    }
    if (navigation?.pathname == "/home/vendorsUpdate") {
      return <VendorReport />;
    }
    if (navigation?.pathname == "/home/driverReport") {
      return <DriverReport />;
    }
    if (navigation?.pathname == "/home/driverUpdate") {
      return <DriverReport />;
    }
    if (navigation?.pathname == "/home/OwnVehicleReport") {
      return <VehicleReport />;
    }
    if (navigation?.pathname == "/home/OwnVehicleUpdate") {
      return <VehicleReport />;
    }
    if (navigation?.pathname == "/home/RentVehicleReport") {
      return <RentVehicleReport />;
    }
    if (navigation?.pathname == "/home/RentVehicleUpdate") {
      return <RentVehicleReport />;
    }
    if (navigation?.pathname == "/home/RentVehicleUpdate/Attendance") {
      return <RentVehicleReport />;
    }
    if (navigation?.pathname == "/home/vehicleUpdate") {
      return <VehicleReport />;
    }
    if (navigation?.pathname == "/home/bookingCreate") {
      return <BookingCreate />;
    }
    if (navigation?.pathname == "/home/bookingReport") {
      return <Booking />;
    }
    if (navigation?.pathname == "/home/bookingUpdate") {
      return <Booking />;
    }
    if (navigation?.pathname == "/home/bookingReport/invoice") {
      return <Booking />;
    }
    if (navigation?.pathname == "/home/bookingReport/Advance") {
      return <AdvanecBooking />;
    }
    if (navigation?.pathname == "/home/payment/updateAdvance") {
      return <AdvanecBooking />;
    }
    if (navigation?.pathname == "/home/bookingReport/Own") {
      return <OwnBooking />;
    }
    if (navigation?.pathname == "/home/bookingReport/Own/Update") {
      return <OwnBooking />;
    }
    if (navigation?.pathname == "/home/confirmbookingReport/Own/Update") {
      return <OwnConfirmBooking />;
    }
    if (navigation?.pathname == "/home/confirmbookingReport/Rent/Update") {
      return <RentConfirmBooking />;
    }

    if (navigation?.pathname == "/home/bookingReport/Rent") {
      return <RentBooking />;
    }
    if (navigation?.pathname == "/home/bookingReport/Rent/Update") {
      return <RentBooking />;
    }
    if (navigation?.pathname == "/home/PaymentUpdate") {
      return <PaymentUpdate />;
    }
    if (navigation?.pathname == "/home/PaymentReport") {
      return <PaymentReport />;
    }
    if (navigation?.pathname == "/home/Paymentreport") {
      return <PaymentReport />;
    }
    if (navigation?.pathname == "/home/paymentUpdate") {
      return <PaymentReport />;
    }
    if (navigation?.pathname == "/home/invoice") {
      return <Invoice />;
    }
    if (navigation?.pathname == "/home/attendance") {
      return <DriverAttendance />;
    }
    if (navigation?.pathname == "/home/ExpensesReport") {
      return <ExpensesReport />;
    }
    if (navigation?.pathname == "/home/ExpensesReport") {
      return <ExpensesReport />;
    }
    if (navigation?.pathname == "/home/ExpensesOneDayReport") {
      return <ExpensesOneDayReport />;
    }
    if (navigation?.pathname == "/home/Expensesupdate") {
      return <ExpensesOneDayReport back={back} />;
    }
    if (navigation?.pathname == "/home/ExpensesUpdate") {
      return <ExpensesReport back={back} />;
    }
    if (navigation?.pathname == "/home/ExpensesCreate") {
      return <Expenses />;
    }
    if (navigation?.pathname == "/home/driverAttendance") {
      return <DriverReport />;
    }
    if (navigation?.pathname == "/home/vendorPayment") {
      return <VendorPayment />;
    }
    if (navigation?.pathname == "/home/VendorPaymentUpdate") {
      return <VendorPayment />;
    }
    if (navigation?.pathname == "/home/QuotationReport") {
      return <QuotationReport />;
    }
    if (navigation?.pathname == "/home/CreateQuotation") {
      return <Quotation />;
    }
    if (navigation?.pathname == "/home/AllConsolidateReport") {
      return <ConsolidateReport />;
    }
    if (navigation?.pathname == "/home/OwnConsolidateReport") {
      return <OwnConsolidateReport />;
    }
    if (navigation?.pathname == "/home/order/orderCreation") {
      return <OrderCreation />;
    }
    if (navigation?.pathname == "/home/order/orderReports") {
      return <OrderReports />;
    }
  }

  const verifyLogin = () => {
    localStorage.removeItem("taxinow");
    localStorage.removeItem("login");

    if (localStorage.getItem("taxinow")) {
      navigate("/home/dashboard");
    } else {
      navigate("/login");
    }
    setLogOut("");
  };

  useEffect(() => {
    // console.log("nav");
    if (localStorage.getItem("taxinow")) {
    } else {
      navigate("/login");
    }
    // localStorage.setItem('Date',JSON.stringify(new Date));
  }, []);

  useEffect(() => {
    if (logOut == "Yes") {
      verifyLogin();
    }
  }, [logOut]);

  localStorage.getItem("taxinow")
    ? localStorage.getItem("taxinow")
    : navigate("/login");

  // useEffect(()=>{
  //   setPageNavigation(window.location.href);
  // },[])

  // console.log(pageNavigation);
  // console.log(`${api.api}`);

  return (
    <div
      className={
        asaidNav
          ? "container-scroller sidebar-fixed sidebar-icon-only"
          : "container-scroller"
      }
    >
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo mt-2" href="/home/dashboard">
            <img src={require("../../Images/logo.jpg")} alt="logo" />
          </a>
          <a className="navbar-brand brand-logo-mini" href="/home/dashboard">
            <img
              src={require("../../Images/logo.jpg")}
              alt="logo"
              style={{ height: "70%", width: "70%" }}
            />
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <button
            className="navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
          >
            <span className="mdi">
              <RiGridFill
                onClick={() => {
                  setAsaidNav(asaidNav ? false : true);
                }}
                style={{ color: "black" }}
              />
            </span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
            <li
              className="nav-item dropdown"
              variant="outlined"
              data-toggle="tooltip"
              data-placement="right"
              title="Log Out"
            >
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="messageDropdown"
                href="#"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="mdi">
                  <FaSignOutAlt onClick={() => setLogOut("Yes")} />
                </i>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="messageDropdown"
              >
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <i className="mdi mdi-lock-outline text-primary"></i>{" "}
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                      Lock screen
                    </h6>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <i className="mdi mdi-logout-variant text-primary"></i>{" "}
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                      Download
                    </h6>
                  </div>
                </a>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi">
              <RiGridFill
                style={{ color: "black" }}
                onClick={() => {
                  setRightAsaidNav(rightAsaidNav ? false : true);
                }}
              />
            </span>
          </button>
        </div>
      </nav>
      <div className="container-fluid page-body-wrapper">
        <nav
          className={
            rightAsaidNav
              ? "sidebar sidebar-offcanvas active"
              : "sidebar sidebar-offcanvas"
          }
          id="sidebar"
        >
          <ul className="nav mt-3">
            <li className="nav-item">
              <Link className="nav-link" to="/home/dashboard">
                <i className="mdi  menu-icon">
                  {" "}
                  <MdDashboard />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname.toLowerCase().includes("dashboard")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Dashboard
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/home/order/orderReports">
                <i className="mdi  menu-icon">
                  {" "}
                  <MdContacts />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname
                      .toLowerCase()
                      .includes("/home/order/")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Orders
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/home/QuotationReport">
                <i className="mdi  menu-icon">
                  {" "}
                  <BsFillCarFrontFill />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname.toLowerCase().includes("quotation")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Quotation
                </span>
              </Link>
            </li>
            {/* <li className="nav-item">
                <Link className="nav-link" to="/home/bookingReport">
                  <i className="mdi  menu-icon"> <BsFillCarFrontFill /></i>
                  <span className="menu-title" style={window.location.pathname.toLowerCase().includes("booking")?{color:"#ffde00eb"}:{}}>Booking</span>
                </Link>
              </li> */}
            <li className="nav-item">
              <Link
                className="nav-link d-flex"
                onClick={() => {
                  setConfirmToggle(confirmToggle == false ? true : false);
                }}
              >
                <i className="mdi  menu-icon">
                  {" "}
                  <BsFillCarFrontFill />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname
                      .toLowerCase()
                      .includes("/home/bookingreport") ||
                    window.location.pathname
                      .toLowerCase()
                      .includes("/home/bookingreport/own")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Booking
                </span>{" "}
                &nbsp; &nbsp;
                <i>
                  {" "}
                  <FaChevronDown className="menu-arrow fs-6" />
                </i>
              </Link>
              <div
                className={confirmToggle ? "collapse show" : "collapse"}
                id="ui-basic"
              >
                <ul className="nav flux-column sub-menu ml-2 mb-0">
                  {/* <Link className="nav-link" to="/home/bookingReport/Advance">
                    <i className="mdi  menu-icon">
                      <PiContactlessPaymentFill />{" "}
                    </i>
                    <span
                      className="menu-title"
                      style={
                        window.location.pathname
                          .toLowerCase()
                          .includes("/home/bookingreport/advance")
                          ? { color: "#ffde00eb" }
                          : {}
                      }
                    >
                      Advance{" "}
                    </span>
                  </Link> */}
                  <Link className="nav-link" to="/home/bookingReport/Own">
                    <i className="mdi  menu-icon">
                      <IoCarSport />{" "}
                    </i>
                    <span
                      className="menu-title"
                      style={
                        window.location.pathname
                          .toLowerCase()
                          .includes("/home/bookingreport/own")
                          ? { color: "#ffde00eb" }
                          : {}
                      }
                    >
                      Booking Own{" "}
                    </span>
                  </Link>
                  <Link className="nav-link" to="/home/bookingReport/Rent">
                    <i className="mdi  menu-icon">
                      <IoMdContacts />{" "}
                    </i>
                    <span
                      className="menu-title"
                      style={
                        window.location.pathname
                          .toLowerCase()
                          .includes("/home/bookingreport/rent")
                          ? { color: "#ffde00eb" }
                          : {}
                      }
                    >
                      Booking Rent
                    </span>
                  </Link>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link d-flex"
                onClick={() => {
                  setBookingToggle(bookingToggle == false ? true : false);
                }}
              >
                <i className="mdi  menu-icon">
                  {" "}
                  <BiSolidSelectMultiple />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname
                      .toLowerCase()
                      .includes("/home/confirmbookingreport/own/update") ||
                    window.location.pathname
                      .toLowerCase()
                      .includes("/home/confirmbookingreport/rent/update")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Booking Confirm
                </span>{" "}
                &nbsp; &nbsp;
                <i>
                  {" "}
                  <FaChevronDown className="menu-arrow fs-6" />
                </i>
              </Link>
              <div
                className={bookingToggle ? "collapse show" : "collapse"}
                id="ui-basic"
              >
                <ul className="nav flux-column sub-menu ml-2 mb-0">
                  <Link
                    className="nav-link"
                    to="/home/confirmbookingReport/Own/Update"
                  >
                    <i className="mdi  menu-icon">
                      <PiCarFill />{" "}
                    </i>
                    <span
                      className="menu-title"
                      style={
                        window.location.pathname
                          .toLowerCase()
                          .includes("/home/confirmbookingreport/own/update")
                          ? { color: "#ffde00eb" }
                          : {}
                      }
                    >
                      Own Confirm{" "}
                    </span>
                  </Link>
                  <Link
                    className="nav-link"
                    to="/home/confirmbookingReport/Rent/Update"
                  >
                    <i className="mdi  menu-icon">
                      <PiCarProfile />{" "}
                    </i>
                    <span
                      className="menu-title"
                      style={
                        window.location.pathname
                          .toLowerCase()
                          .includes("/home/confirmbookingreport/rent/update")
                          ? { color: "#ffde00eb" }
                          : {}
                      }
                    >
                      Rent Confirm
                    </span>
                  </Link>
                </ul>
              </div>
            </li>

            {/* Payment */}
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => {
                  setPaymentNav(paymentNav ? false : true);
                }}
              >
                <i className="mdi  menu-icon">
                  {" "}
                  <GiReceiveMoney />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname
                      .toLowerCase()
                      .includes("customerreport")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Payments
                </span>
              </Link>
            </li>

            <div
              style={{ display: paymentNav ? "block" : "none" }}
              className="ml-2"
            >
              <li className="nav-item">
                <Link className="nav-link" to="/home/PaymentReport">
                  <i className="mdi  menu-icon">
                    {" "}
                    <ImPriceTags />
                  </i>
                  <span
                    className="menu-title"
                    style={
                      window.location.pathname
                        .toLowerCase()
                        .includes("paymentreport")
                        ? { color: "#ffde00eb" }
                        : {}
                    }
                  >
                    Booking Payment
                  </span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/home/vendorPayment">
                  <i className="mdi  menu-icon">
                    {" "}
                    <ImPriceTags />
                  </i>
                  <span
                    className="menu-title"
                    style={
                      window.location.pathname
                        .toLowerCase()
                        .includes("vendorpayment")
                        ? { color: "#ffde00eb" }
                        : {}
                    }
                  >
                    Vendor Payment
                  </span>
                </Link>
              </li>
            </div>

            {/* Expenses */}

            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => {
                  setExpensesToggle(expensesToggle == false ? true : false);
                }}
              >
                <i className="mdi  menu-icon">
                  {" "}
                  <FaFileExport />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname.toLowerCase().includes("expenses")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Expenses
                </span>{" "}
                &nbsp;
                <i>
                  {" "}
                  <FaChevronDown className="menu-arrow fs-6" />
                </i>
              </Link>
              <div
                className={expensesToggle ? "collapse show" : "collapse"}
                id="ui-basic"
              >
                <ul className="nav flux-column sub-menu ml-2 mb-0">
                  <Link className="nav-link" to="/home/ExpensesReport">
                    <i className="mdi  menu-icon">
                      <RiPriceTag2Fill />{" "}
                    </i>
                    <span
                      className="menu-title"
                      style={
                        window.location.pathname
                          .toLowerCase()
                          .includes("expensesreport")
                          ? { color: "#ffde00eb" }
                          : {}
                      }
                    >
                      All Expenses{" "}
                    </span>
                  </Link>
                  <Link className="nav-link" to="/home/ExpensesOneDayReport">
                    <i className="mdi  menu-icon">
                      <HiOutlineDocumentReport />{" "}
                    </i>
                    <span
                      className="menu-title"
                      style={
                        window.location.pathname
                          .toLowerCase()
                          .includes("expensesonedayreport")
                          ? { color: "#ffde00eb" }
                          : {}
                      }
                    >
                      Day Expenses
                    </span>
                  </Link>
                </ul>
              </div>
            </li>

            {/* Reports */}

            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => {
                  setReports(reports ? false : true);
                }}
              >
                <i className="mdi  menu-icon">
                  {" "}
                  <BiSolidReport />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname
                      .toLowerCase()
                      .includes("customerreport")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Reports
                </span>
              </Link>
            </li>

            <div
              style={{ display: reports ? "block" : "none" }}
              className="ml-2"
            >
              <li className="nav-item">
                <Link
                  className="nav-link"
                  onClick={() => {
                    setDayToDayToggle(dayToDayToggle == false ? true : false);
                  }}
                >
                  <i className="mdi  menu-icon">
                    {" "}
                    <FaFileExport />
                  </i>
                  <span
                    className="menu-title"
                    style={
                      window.location.pathname
                        .toLowerCase()
                        .includes("ownconsolidate")
                        ? { color: "#ffde00eb" }
                        : window.location.pathname
                            .toLowerCase()
                            .includes("allconsolidatereport")
                        ? { color: "#ffde00eb" }
                        : {}
                    }
                  >
                    Day To Day Report
                  </span>{" "}
                  &nbsp;
                  <i>
                    {" "}
                    <FaChevronDown className="menu-arrow fs-6" />
                  </i>
                </Link>
                <div
                  className={dayToDayToggle ? "collapse show" : "collapse"}
                  id="ui-basic"
                >
                  <ul className="nav flux-column sub-menu ml-2 mb-0">
                    <Link className="nav-link" to="/home/AllConsolidateReport">
                      <i className="mdi  menu-icon">
                        {" "}
                        <ImPriceTags />
                      </i>
                      <span
                        className="menu-title"
                        style={
                          window.location.pathname
                            .toLowerCase()
                            .includes("allconsolidatereport")
                            ? { color: "#ffde00eb" }
                            : {}
                        }
                      >
                        Own & Rent Report
                      </span>
                    </Link>
                    <Link className="nav-link" to="/home/OwnConsolidateReport">
                      <i className="mdi  menu-icon">
                        {" "}
                        <ImPriceTags />
                      </i>
                      <span
                        className="menu-title"
                        style={
                          window.location.pathname
                            .toLowerCase()
                            .includes("ownconsolidate")
                            ? { color: "#ffde00eb" }
                            : {}
                        }
                      >
                        Own Report
                      </span>
                    </Link>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/home/OwnVehicleIncomeReport">
                  <i className="mdi  menu-icon">
                    {" "}
                    <ImPriceTags />
                  </i>
                  <span
                    className="menu-title "
                    style={
                      window.location.pathname
                        .toLowerCase()
                        .includes("ownVehicleincomereport")
                        ? { color: "#ffde00eb" }
                        : {}
                    }
                  >
                    Own Vehicle Report
                  </span>
                </Link>
              </li>
            </div>

            {/* //Settings */}

            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => {
                  setSettings(Settings ? false : true);
                }}
              >
                <i className="mdi  menu-icon">
                  {" "}
                  <IoSettings />
                </i>
                <span
                  className="menu-title"
                  style={
                    window.location.pathname
                      .toLowerCase()
                      .includes("customerreport")
                      ? { color: "#ffde00eb" }
                      : {}
                  }
                >
                  Settings
                </span>
              </Link>
            </li>
            <div
              style={{ display: Settings ? "block" : "none" }}
              className="ml-2"
            >
              <li className="nav-item">
                <Link className="nav-link" to="/home/customerReport">
                  <i className="mdi  menu-icon">
                    {" "}
                    <MdHail />
                  </i>
                  <span
                    className="menu-title"
                    style={
                      window.location.pathname
                        .toLowerCase()
                        .includes("customerreport")
                        ? { color: "#ffde00eb" }
                        : {}
                    }
                  >
                    Customer
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link d-flex"
                  onClick={() => {
                    setToggle(toggle == false ? true : false);
                  }}
                >
                  <i className="mdi  menu-icon">
                    {" "}
                    <FaCarRear />
                  </i>
                  <span
                    className="menu-title"
                    style={
                      window.location.pathname
                        .toLowerCase()
                        .includes("vehicle") ||
                      window.location.pathname
                        .toLowerCase()
                        .includes("rentvehiclereport")
                        ? { color: "#ffde00eb" }
                        : {}
                    }
                  >
                    Vehicle
                  </span>{" "}
                  &nbsp; &nbsp;
                  <i>
                    {" "}
                    <FaChevronDown className="menu-arrow fs-6" />
                  </i>
                </Link>
                <div
                  className={toggle ? "collapse show" : "collapse"}
                  id="ui-basic"
                >
                  <ul className="nav flux-column sub-menu ml-2 mb-0">
                    <Link className="nav-link" to="/home/OwnVehicleReport">
                      <i className="mdi  menu-icon">
                        <IoCarSport />{" "}
                      </i>
                      <span
                        className="menu-title"
                        style={
                          window.location.pathname
                            .toLowerCase()
                            .includes("ownvehicle")
                            ? { color: "#ffde00eb" }
                            : {}
                        }
                      >
                        Own Vehicle{" "}
                      </span>
                    </Link>
                    <Link className="nav-link" to="/home/RentVehicleReport">
                      <i className="mdi  menu-icon">
                        <IoMdContacts />{" "}
                      </i>
                      <span
                        className="menu-title"
                        style={
                          window.location.pathname
                            .toLowerCase()
                            .includes("rentvehicle")
                            ? { color: "#ffde00eb" }
                            : {}
                        }
                      >
                        Rent Vehicle
                      </span>
                    </Link>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/home/driverReport">
                  <i className="mdi  menu-icon">
                    {" "}
                    <FaRegLifeRing />
                  </i>
                  <span
                    className="menu-title"
                    style={
                      window.location.pathname.toLowerCase().includes("driver")
                        ? { color: "#ffde00eb" }
                        : {}
                    }
                  >
                    Driver
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/home/vendorsReport">
                  <i className="mdi  menu-icon">
                    {" "}
                    <GrUserSettings />
                  </i>
                  <span
                    className="menu-title"
                    style={
                      window.location.pathname.toLowerCase().includes("vendors")
                        ? { color: "#ffde00eb" }
                        : {}
                    }
                  >
                    Vendor
                  </span>
                </Link>
              </li>
            </div>
            {/* <li className="nav-item">
                <a className="nav-link" href="pages/samples/widgets.html">
                  <i className="mdi mdi-widgets menu-icon"></i>
                  <span className="menu-title">Widgets</span>
                </a>
              </li> */}
            {/* <li className="nav-item"> */}
            {/* <a className="nav-link" data-toggle="collapse" href="#ui-advanced" aria-expanded="false" aria-controls="ui-advanced">
                  <i className="mdi variant menu-icon"><IoDocumentText /></i>
                  <span className="menu-title">Creation</span> */}
            {/* <i className="menu-arrow"></i> */}
            {/* </a> */}
            {/* <div className="collapse" id="ui-advanced">
                  <ul className="nav flex-column sub-menu">
                    <Link className="nav-item" to='/customerCreation'> <a className="nav-link" >Customer Creation</a></Link>
                    <Link className="nav-item" to='/vehicleCreation'> <a className="nav-link" >Vehicle Creation</a></Link>
                    <Link className="nav-item" to='/vendorCreation'> <a className="nav-link" >Vendor Creation</a></Link>
                    <Link className="nav-item" to='/driverCreation'> <a className="nav-link" >Driver Creation</a></Link>
                  </ul>
                </div> */}
            {/* </li> */}
            {/* <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                  <i className="mdi menu-icon"><SiSimpleanalytics/></i>
                  <span className="menu-title">Reports</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="ui-basic">
                  <ul className="nav flex-column sub-menu"> */}
            {/* <Link className="nav-item" to='/home/customerReport'> <a className="nav-link" >Customer Reports</a></Link>
                    <Link className="nav-item" to='/home/VehicleReport'> <a className="nav-link" >Vehicle Reports</a></Link>
                    <Link className="nav-item" to='/home/driverReport'> <a className="nav-link">Driver Reports</a></Link>
                    <Link className="nav-item" to='/home/vendorReport'> <a className="nav-link">Vendor Reports</a></Link> */}
            {/* <li className="nav-item"> <a className="nav-link" href="pages/ui-features/dropdowns.html">Dropdowns</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/ui-features/modals.html">Modals</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/ui-features/progress.html">Progress bar</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/ui-features/pagination.html">Pagination</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/ui-features/tabs.html">Tabs</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Typography</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/ui-features/tooltips.html">Tooltip</a></li> */}
            {/* </ul>
                </div>
              </li> */}
            {/* <li className="nav-item">
                <a className="nav-link" href="pages/ui-features/popups.html">
                  <i className="mdi mdi-forum menu-icon"></i>
                  <span className="menu-title">Popups</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/ui-features/notifications.html">
                  <i className="mdi mdi-bell-ring menu-icon"></i>
                  <span className="menu-title">Notifications</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#icons" aria-expanded="false" aria-controls="icons">
                  <i className="mdi mdi-contacts menu-icon"></i>
                  <span className="menu-title">Icons</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="icons">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/icons/mdi.html">Material</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/icons/flag-icons.html">Flag icons</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/icons/font-awesome.html">Font Awesome</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/icons/simple-line-icon.html">Simple line icons</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/icons/themify.html">Themify icons</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#forms" aria-expanded="false" aria-controls="forms">
                  <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                  <span className="menu-title">Forms</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="forms">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/forms/basic_elements.html">Form Elements</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/forms/advanced_elements.html">Advanced Forms</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/forms/validation.html">Validation</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/forms/wizard.html">Wizard</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/forms/text_editor.html">
                  <i className="mdi mdi-file-document menu-icon"></i>
                  <span className="menu-title">Text editors</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/forms/code_editor.html">
                  <i className="mdi mdi-code-not-equal-variant menu-icon"></i>
                  <span className="menu-title">Code editors</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#charts" aria-expanded="false" aria-controls="charts">
                  <i className="mdi mdi-chart-bar menu-icon"></i>
                  <span className="menu-title">Charts</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="charts">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/charts/chartjs.html">ChartJs</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/charts/morris.html">Morris</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/charts/flot-chart.html">Flot</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/charts/google-charts.html">Google charts</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/charts/sparkline.html">Sparkline js</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/charts/c3.html">C3 charts</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/charts/chartist.html">Chartist</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/charts/justGage.html">JustGage</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#tables" aria-expanded="false" aria-controls="tables">
                  <i className="mdi mdi-table-large menu-icon"></i>
                  <span className="menu-title">Tables</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="tables">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/tables/basic-table.html">Basic table</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/tables/data-table.html">Data table</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/tables/js-grid.html">Js-grid</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/tables/sortable-table.html">Sortable table</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#maps" aria-expanded="false" aria-controls="maps">
                  <i className="mdi mdi-map-marker-radius menu-icon"></i>
                  <span className="menu-title">Maps</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="maps">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/maps/google-maps.html">Google Maps</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/maps/mapael.html">Mapael</a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/maps/vector-map.html">Vector map</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
                  <i className="mdi mdi-lock menu-icon"></i>
                  <span className="menu-title">User Pages</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="auth">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/login.html"> Login </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/login-2.html"> Login 2 </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/mutli-level-login.html"> Multi step login </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/register.html"> Register </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/register-2.html"> Register 2 </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/lock-screen.html"> Lockscreen </a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#error" aria-expanded="false" aria-controls="error">
                  <i className="mdi mdi-security menu-icon"></i>
                  <span className="menu-title">Error pages</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="error">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/error-404.html"> 404 </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/error-500.html"> 500 </a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#general-pages" aria-expanded="false" aria-controls="general-pages">
                  <i className="mdi mdi-medical-bag menu-icon"></i>
                  <span className="menu-title">General Pages</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="general-pages">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/blank-page.html"> Blank Page </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/profile.html"> Profile </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/faq.html"> FAQ </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/faq-2.html"> FAQ 2 </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/news-grid.html"> News grid </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/timeline.html"> Timeline </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/user-listing.html"> User listing </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/portfolio.html"> Portfolio </a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" href="#e-commerce" aria-expanded="false" aria-controls="e-commerce">
                  <i className="mdi mdi-shopping menu-icon"></i>
                  <span className="menu-title">E-commerce</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="e-commerce">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/invoice.html"> Invoice </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/pricing-table.html"> Pricing Table </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/orders.html"> Orders </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/emailer.html"> Email template </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/product-catalogue.html"> Product catalogue </a></li>
                    <li className="nav-item"> <a className="nav-link" href="pages/samples/project-list.html"> project list </a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/apps/kanban-board.html">
                  <i className="mdi mdi-tablet-dashboard menu-icon"></i>
                  <span className="menu-title">Kanban Board</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/apps/email.html">
                  <i className="mdi mdi-email menu-icon"></i>
                  <span className="menu-title">E-mail</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/apps/calendar.html">
                  <i className="mdi mdi-calendar-today menu-icon"></i>
                  <span className="menu-title">Calendar</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/apps/todo.html">
                  <i className="mdi mdi-playlist-edit menu-icon"></i>
                  <span className="menu-title">Todo list</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/apps/tickets.html">
                  <i className="mdi mdi-ticket menu-icon"></i>
                  <span className="menu-title">Tickets</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/apps/chat.html">
                  <i className="mdi mdi-chat menu-icon"></i>
                  <span className="menu-title">Chat</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/apps/gallery.html">
                  <i className="mdi mdi-image-filter-frames menu-icon"></i>
                  <span className="menu-title">Gallery</span>
                </a>
              </li>
              <li className="nav-item documentation-link">
                <a className="nav-link" href="http://www.bootstrapdash.com/demo/material-dash-bootstrap/documentation/documentation.html">
                  <i className="mdi mdi-file-document-box menu-icon"></i>
                  <span className="menu-title">Documentation</span>
                </a>
              </li>
              <li className="nav-item">
                <div className="profile-actions">
                  <a href="javascript:;">Settings</a>
                  <span className="divider"></span>
                  <a href="javascript:;">Logout</a>
                </div>
              </li>
              <li className="nav-item">
                <div className="mdc-card premium-card">
                  <div className="d-flex align-items-center">
                    <div className="mdc-card icon-card box-shadow-0">
                      <i className="mdi mdi-shield-outline"></i>
                    </div>
                    <div>
                      <p className="mt-0 mb-1 ml-2 font-weight-bold text-small">SaaS service</p>
                      <p className="mt-0 mb-0 ml-2 tx-10">Ad goes here</p>
                    </div>
                  </div>
                  <p className="tx-8 mt-3 mb-3">lorem imsum dolor sit amet</p>
                  <button className="btn btn-light w-100">Get Started</button>
                </div>
              </li> */}
          </ul>
        </nav>

        <div className="main-panel">
          {/* <!-- a simple div with some links --> */}
          <div className="breadcrumb">
            <Link
              to="/home/dashboard"
              style={
                window.location.pathname.toLowerCase().includes("home")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Home
            </Link>
            <Link
              to="/home/order/orderReports"
              style={
                window.location.pathname.toLowerCase().includes("/home/order/")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Order Report
            </Link>
            <Link
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("/home/order/ordercreation")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Order Create
            </Link>
            <Link
              to="/home/dashboard"
              style={
                window.location.pathname.toLowerCase().includes("dashboard")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Dashboard
            </Link>
            <Link
              to="/home/customerReport"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("customerreport") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("customercreation") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("customerupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Customer Report
            </Link>
            <Link
              to="/home/customerCreation"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("customercreation")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Create Customer
            </Link>
            <Link
              to="/home/customerUpdate"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("customerupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Customer Update
            </Link>

            <Link
              to="/home/OwnVehicleReport"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("vehiclereport") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("vehiclecreation") ||
                window.location.pathname.toLowerCase().includes("vehicleupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Vehicle Report
            </Link>
            <Link
              to="/home/vehicleCreation"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("vehiclecreation")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Create Vehicle
            </Link>
            <Link
              to="/home/vehicleUpdate"
              style={
                window.location.pathname.toLowerCase().includes("vehicleupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Vehicle Update
            </Link>

            <Link
              to="/home/driverReport"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("driverreport") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("drivercreation") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("driverupdate") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("driverattendance")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Driver Report
            </Link>
            <Link
              to="/home/driverCreation"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("drivercreation")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Create Driver
            </Link>
            <Link
              to="/home/driverUpdate"
              style={
                window.location.pathname.toLowerCase().includes("driverupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Driver Update
            </Link>
            <Link
              to="/home/driverReport"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("driverattendance")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Driver Attendance
            </Link>

            <Link
              to="/home/vendorReport"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("vendorreport") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("vendorcreation") ||
                window.location.pathname.toLowerCase().includes("vendorupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Vendor Report
            </Link>
            <Link
              to="/home/vendorCreation"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("vendorcreation")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Create Vendor
            </Link>
            <Link
              to="/home/vendorUpdate"
              style={
                window.location.pathname.toLowerCase().includes("vendorupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Vendor Update
            </Link>

            <Link
              to="/home/bookingReport/Own"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("bookingreport") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("bookingcreate") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("bookingupdate") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("/home/payment/updateadvance")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Booking Report
            </Link>
            <Link
              to="/home/bookingCreate"
              style={
                window.location.pathname.toLowerCase().includes("bookingcreate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Create Booking
            </Link>
            <Link
              to="/home/bookingUpdate"
              style={
                window.location.pathname.toLowerCase().includes("bookingupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Booking Update
            </Link>

            <Link
              // to="/home/bookingReport/Advance"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("/home/bookingreport/advance") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("/home/payment/updateadvance")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Advance
            </Link>

            <Link
              // to="/home/bookingReport/Advance"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("/home/payment/updateadvance")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Advance Update
            </Link>

            <Link
              to="/home/PaymentReport"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("paymentreport") ||
                window.location.pathname.toLowerCase().includes("paymentupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Payment Report
            </Link>
            <Link
              to="/home/paymentUpdate"
              style={
                window.location.pathname.toLowerCase().includes("paymentupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Payment Update
            </Link>

            <Link
              to={"/home/ExpensesReport"}
              onClick={() => {
                let back = "";
              }}
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("expensesreport") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("expensescreate") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("expensesupdate") ||
                window.location.pathname
                  .toLowerCase()
                  .includes("/home/expensesonedayreport")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Expense Report
            </Link>
            <Link
              to="/home/ExpensesCreate"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("expensescreate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Create Expense
            </Link>
            <Link
              to="/home/ExpensesUpdate"
              style={
                window.location.pathname
                  .toLowerCase()
                  .includes("expensesupdate")
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Expense Update
            </Link>
            {/* <a href="#">Checkout</a>
  <a href="#" className="active">Checkout</a> */}
          </div>

          <div className="content-wrapper">
            <div className="container-fluid">{getComponent()}</div>
          </div>

          <footer className="footer">
            <div className="footer-inner-wraper">
              <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block text-black">
                  Copyright {new Date().getFullYear()}{" "}
                  <a href="https://techovirish.com" target="_blank">
                    Techo Virish Software Solution
                  </a>
                  . All rights reserved.
                </span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                  Hand-crafted & made with{" "}
                  <i className="mdi mdi-heart text-danger"></i>
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Home;
