import * as ReactDOM from "react-dom";
import * as React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "./invoice_Style.css";
import { CiLocationOn } from "react-icons/ci";
import { IoMdCall } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { FaAddressCard } from "react-icons/fa";
import { LiaPrayingHandsSolid } from "react-icons/lia";
import { Col } from "react-bootstrap";

function Invoice({ invoiceDetail }) {
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  const originalDate = new Date(invoiceDetail?.item?.createdAt);
  const formattedDate = originalDate.toLocaleDateString("en-US", {
    month: "short", // Short month name
    day: "numeric", // Day of the month
    year: "numeric", // Full year
  });
  const bookingDate = new Date(invoiceDetail?.item?.booking_date);
  const bookedDate = bookingDate.toLocaleDateString("en-GB", {
    day: "2-digit", // Two-digit day
    month: "2-digit", // Two-digit month
    year: "numeric", // Full year
  });

  // console.log(invoiceDetail);
  // console.log(invoiceDetail?.item.trip_day);

  return (
    <div>
      <div>
        <Col
          ref={printRef}
          xxl={4}
          xl={4}
          md={8}
          sm={12}
          // style={{ maxWidth: "100%" }}
          className="card card-xl-60 "
        >
          <div className="card mx-2">
            <div className="mb-0 mt-2">
              <div className="row col-12 d-flex align-items-baseline p-0 m-0">
                {/* <h1 className="text-center mt-4">
                  TAXINIYAS SERVICES PRIVATE LIMITED
                </h1> */}
                <img
                  class="m-0 p-0"
                  src={require("../../../../Images/Quotation.webp")}
                />
                {/* <hr style={{ borderTop: "2px solid black" }} /> */}
              </div>

              <div
              // className="container"
              >
                <div className="col-md-12">
                  <div className="text-center mt-1">
                    <h5
                      style={{ fontSize: "50%" }}
                      className="text-center p-0 m-0"
                    >
                      {invoiceDetail?.item.header_notes}
                    </h5>
                  </div>
                </div>

                <div className="d-flex row mt-1">
                  <div className="col-xl-8 col-8">
                    <ul className="list-unstyled m-0 p-0">
                      <li className="text-muted d-flex">
                        <h5
                          style={{ color: "black", fontSize: "60%" }}
                          className=" p-0 m-0 "
                        >
                          Trip:{" "}
                          <span className=" text-black">
                            {invoiceDetail?.item.location}
                          </span>
                        </h5>
                      </li>
                      <li className="text-muted d-flex  p-0 m-0">
                        <h5 style={{ color: "black", fontSize: "60%" }}>
                          Trip Days: {invoiceDetail?.item.trip_day} Day
                        </h5>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-4 col-4">
                    <h5
                      className="fw-bold   p-0 m-0"
                      style={{ color: "black", fontSize: "40%" }}
                    >
                      Quotation
                    </h5>
                    <ul className="list-unstyled justify-content-end  p-0 m-0">
                      <li
                        className="text  p-0 m-0"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        <i
                          className="fas fa-circle"
                          style={{ color: "#84B0CA" }}
                        ></i>{" "}
                        <span
                          className="fw-bold  p-0 m-0"
                          style={{ color: "black" }}
                        >
                          No:{" "}
                        </span>
                        {invoiceDetail?.item.id}
                      </li>
                      <li
                        className="text  p-0 m-0"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        <i
                          className="fas fa-circle"
                          style={{ color: "#84B0CA" }}
                        ></i>{" "}
                        <span
                          className="fw-bold  p-0 m-0"
                          style={{ color: "black" }}
                        >
                          Date:{" "}
                        </span>
                        {formattedDate}
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <hr style={{ borderTop: "2px solid black" }} /> */}
                <div className="row  mx-1 justify-content-center table  p-0 m-0 w-sm-80 ">
                  <table className="table  table-borderless  p-0 m-0">
                    <thead
                      style={{ backgroundColor: "#84B0CA" }}
                      className="text-white"
                    >
                      <tr>
                        {/* <th scope="col" className="text-center fs-6">
                          DATE
                        </th> */}
                        <th
                          scope="col"
                          className="text-center py-1"
                          style={{ fontSize: "40%" }}
                        >
                          Particulars
                        </th>
                        <th
                          scope="col"
                          className="text-center py-1"
                          style={{ fontSize: "40%" }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="col-12">
                        <td
                          className="text-center col-2 py-1"
                          style={{ fontSize: "40%" }}
                        >
                          {invoiceDetail?.item.trip_day + " "} Days *{" "}
                          {invoiceDetail?.item.per_day_amount}{" "}
                        </td>

                        <td
                          className="col-2  text-end py-1"
                          style={{ fontSize: "40%" }}
                        >
                          ₹
                          {Number(invoiceDetail?.item.trip_day) *
                            Number(invoiceDetail?.item.per_day_amount)}
                        </td>
                      </tr>
                      <tr className="col-12">
                        <td
                          className="text-center col-2 py-1"
                          style={{ fontSize: "40%" }}
                        >
                          {invoiceDetail?.item.trip_km + " "} KM * Rs.
                          {invoiceDetail?.item.per_km_amount}{" "}
                        </td>

                        <td
                          className="col-2  text-end py-1"
                          style={{ fontSize: "40%" }}
                        >
                          ₹
                          {Number(invoiceDetail?.item.trip_km) *
                            Number(invoiceDetail?.item.per_km_amount)}
                        </td>
                      </tr>
                      <tr className="col-12">
                        <td
                          className="text-center col-2 py-1"
                          style={{ fontSize: "40%" }}
                        >
                          Permit{" "}
                        </td>

                        <td
                          className="col-2  text-end py-1"
                          style={{ fontSize: "40%" }}
                        >
                          ₹{Number(invoiceDetail?.item.permit)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex row">
                  <div className="col-xl-8 col-sm-8 col-6">
                    {/* <p className="ms-3 mt-3">
                      <h6>OUR BANK DETAILS</h6>
                      <div className="d-flex">
                        BANK NAME<h6 className="mt-1">: HDFC BANK</h6>
                      </div>
                      <div className="d-flex mt-1">
                        BRANCH<h6 className="mt-1">:DINDIGUL</h6>
                      </div>
                      <div className="d-flex mt-1">
                        IFS CODE<h6 className="mt-1">:HDFC0001850</h6>
                      </div>
                      <div className="d-flex mt-1">
                        ACCOUNT NO<h6 className="mt-1">:50200082343</h6>
                      </div>
                      <div className="d-flex mt-1">
                        ACCOUNT TYPE<h6 className="mt-1">: CASH & CREDIT</h6>
                      </div>
                    </p> */}
                  </div>
                  <div className="col-xl-4 col-sm-4 col-6">
                    <li className="text-muted  mt-1 d-flex justify-content-between ">
                      <td
                        className="text-black font-weight-bold"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        SubTotal
                      </td>
                      <td
                        className="text-black font-weight-bold"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        ₹{" "}
                        {Number(invoiceDetail?.item.trip_day) *
                          Number(invoiceDetail?.item.per_day_amount) +
                          Number(invoiceDetail?.item.trip_km) *
                            Number(invoiceDetail?.item.per_km_amount) +
                          Number(invoiceDetail?.item.permit)}
                      </td>
                    </li>
                    <li className="text-muted  mt-1 d-flex justify-content-between">
                      <td
                        className="text-black"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        Discount
                      </td>
                      <td
                        className="text-black"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        ₹ {Number(invoiceDetail?.item.discount)}{" "}
                      </td>
                    </li>
                    <hr
                      className="m-0"
                      style={{ borderTop: "1px solid black" }}
                    />

                    <li className="text-muted  mt-1 d-flex justify-content-between">
                      <td
                        className="text-black font-weight-bold"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        Grand Total
                      </td>
                      <td
                        className="text-black font-weight-bold"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        ₹{" "}
                        {Number(invoiceDetail?.item.trip_day) *
                          Number(invoiceDetail?.item.per_day_amount) +
                          Number(invoiceDetail?.item.trip_km) *
                            Number(invoiceDetail?.item.per_km_amount) +
                          Number(invoiceDetail?.item.permit) -
                          Number(invoiceDetail?.item.discount)}
                      </td>
                    </li>

                    <li className="text-muted  mt-1 d-flex justify-content-between">
                      <td
                        className="text-black"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        CGST 6%
                      </td>
                      <td
                        className="text-black"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        ₹ {Number(invoiceDetail?.item.cgst)}
                      </td>
                    </li>
                    <li className="text-muted  mt-1 d-flex justify-content-between">
                      <td
                        className="text-black"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        SGST 6%
                      </td>
                      <td
                        className="text-black"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        ₹ {Number(invoiceDetail?.item.sgst)}
                      </td>
                    </li>

                    <hr
                      className="m-0"
                      style={{ borderTop: "1px solid black" }}
                    />
                    <li className="text-muted  mt-1 d-flex justify-content-between">
                      <td
                        className="text-black font-weight-bold "
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        Net Total
                      </td>
                      <td
                        className="text-black font-weight-bold"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        ₹{" "}
                        {Number(invoiceDetail?.item.trip_day) *
                          Number(invoiceDetail?.item.per_day_amount) +
                          Number(invoiceDetail?.item.trip_km) *
                            Number(invoiceDetail?.item.per_km_amount) +
                          Number(invoiceDetail?.item.permit) -
                          Number(invoiceDetail?.item.discount) +
                          Number(invoiceDetail?.item.sgst) * 2}
                      </td>
                    </li>

                    <li className="text-muted  mt-1 d-flex justify-content-between">
                      <td
                        className="text-black"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        Advance
                      </td>
                      <td
                        className="text-black"
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        ₹ {Number(invoiceDetail?.item.advance)}
                      </td>
                    </li>
                    <hr
                      className="m-0"
                      style={{ borderTop: "1px solid black" }}
                    />
                    <p className="text-black m-0 justify-content-between d-flex">
                      <h5
                        className="text-black p-0 m-0 font-weight-bold "
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        {" "}
                        Total Amount
                      </h5>
                      <h5
                        className=" p-0 m-0 font-weight-bold "
                        style={{ color: "black", fontSize: "50%" }}
                      >
                        ₹{" "}
                        {Number(invoiceDetail?.item.trip_day) *
                          Number(invoiceDetail?.item.per_day_amount) +
                          Number(invoiceDetail?.item.trip_km) *
                            Number(invoiceDetail?.item.per_km_amount) +
                          Number(invoiceDetail?.item.permit) -
                          Number(invoiceDetail?.item.discount) +
                          Number(invoiceDetail?.item.sgst) * 2 -
                          Number(invoiceDetail?.item.advance)}
                      </h5>
                    </p>
                  </div>
                </div>
                <hr
                  className="m-0 p-0"
                  style={{ borderTop: "1px solid black" }}
                />
                <h6
                  className="row d-flex justify-content-center p-0 m-0"
                  style={{ color: "black", fontSize: "40%" }}
                >
                  {invoiceDetail?.item.notes}
                </h6>
                <h6
                  className=" d-flex justify-content-center"
                  style={{ color: "black", fontSize: "40%" }}
                >
                  <LiaPrayingHandsSolid /> THANKING YOU
                </h6>

                <div className="row p-0 m-0">
                  <div className="col-6 p-0 m-0"></div>
                  <div className="col-6 p-0 m-0">
                    <h6
                      className="text-right"
                      style={{ color: "black", fontSize: "40%" }}
                    >
                      MANAGING DIRECTOR
                    </h6>
                    <p
                      className="text-right p-0 m-0"
                      style={{ color: "black", fontSize: "40%" }}
                    >
                      R.SARAN RAJ
                    </p>
                    <p
                      className="text-right p-0 m-0"
                      style={{ color: "black", fontSize: "40%" }}
                    >
                      9789690070
                    </p>
                    <h6
                      className="text-right p-0 m-0"
                      style={{ color: "black", fontSize: "40%" }}
                    >
                      TAXINIYAS SERVICES PRIVATE TIMITED
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>
      <div className="d-flex col-xl-6 col-12 justify-content-center">
        <button type="button" className="btn col-3" onClick={handleDownloadPdf}>
          Print
        </button>
      </div>
    </div>
  );
}

export default Invoice;
