import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../api";
import Autocomplete from "@mui/material/Autocomplete";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineLibraryAdd } from "react-icons/md";
import Modal from "react-bootstrap/Modal";

import CustomerCreation from "../Forms/CustomerCreation";

const OrderCreation = ({ billingDetails, setEditBilling, back }) => {
  const [lgShow, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [billing, setBilling] = useState([
    {
      bookingDate: formatDate(new Date()),
      customerName: "",
      customerMobile: "",
      pickupLocation: "",
      dropLocation: "",
      km: "",
      tripDays: "",
      advanceAmount: "",
      orderAmount: "",
      ac_vehicle: 0,
      notes: "",
    },
  ]);
  const [billingError, setBillingError] = useState([
    {
      customerName: "",
      customerMobile: "",
      pickupLocation: "",
      dropLocation: "",
      bookingDate: formatDate(new Date()),
      orderAmount: "",
      km: "",
      advanceAmount: "",
      notes: "",
      ac_vehicle: 0,
    },
  ]);

  const [buttonDisable, setButtonDisable] = useState(false);

  const [bookingExpenses, setBookingExpenses] = useState([
    { diesel: "", driverBata: "", expensesNote: "" },
  ]);

  const navigate = useNavigate();

  const [customerList, setCustomerList] = useState([]);

  const updateValue = () => {
    // console.log(billingDetails);
    if (billingDetails) {
      let newData = [...billing];

      newData[0].bookingDate = formatDate(new Date(billingDetails?.orderDate));
      newData[0].customerName = billingDetails?.customer
        ? billingDetails.customer
        : "";
      newData[0].customerMobile = billingDetails?.customer
        ? billingDetails.customer
        : "";
      newData[0].pickupLocation = billingDetails?.trip_start;
      newData[0].dropLocation = billingDetails?.trip_end;
      newData[0].km = billingDetails?.trip_km;
      newData[0].tripDays = billingDetails?.trip_days;
      newData[0].advanceAmount = billingDetails?.order_advance;
      newData[0].orderAmount = billingDetails?.total_amount;
      newData[0].ac_vehicle = billingDetails?.ac;
      newData[0].notes = billingDetails?.notes;
      setBilling(newData);

      // Uncomment and adjust the code below based on your requirements
      // if (billingDetails?.item.vehicle.vehicle_type === "Rent") {
      //   navigate("/home/bookingReport/Rent/Update");
      // } else if (billingDetails?.item.vehicle.vehicle_type === "Own") {
      //   navigate("/home/bookingReport/Own/Update");
    }
    // console.log(value);
    // }
  };

  const fetchData = async () => {
    const database = await axios.get(`${api.api}/booking/allSubSelection`);
    setCustomerList(database?.data?.customer);
  };

  const bookingForm = async () => {
    if (!billingDetails) {
      if (
        new Date(billing[0].bookingDate).toString().length == 55 &&
        billing[0].bookingDate?.toString() !==
          "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
      ) {
        // console.log(billing);
        const bookTaxi = await axios.post(`${api.api}/orders/createOrder`, {
          billing,
        });
        alert("Successfully Created");
        // navigate("/home/bookingReport");
        setBilling([
          {
            bookingDate: formatDate(new Date()),
            customerName: "",
            customerMobile: "",
            pickupLocation: "",
            dropLocation: "",
            km: "",
            tripDays: "",
            advanceAmount: "",
            orderAmount: "",
            ac_vehicle: 0,
            notes: "",
          },
        ]);
        navigate("/home/order/orderReports");
      } else {
        alert("Enter Valid Order Date");
      }
    } else if (billingDetails) {
      if (
        new Date(billing[0].bookingDate).toString().length == 55 &&
        billing[0].bookingDate?.toString() !==
          "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
      ) {
        const id = billingDetails.id;
        const bookTaxi = await axios.post(`${api.api}/orders/updateOrder`, {
          billing,
          id,
        });
        alert("Successfully Updated");
        // navigate("/home/bookingReport");
        setBilling([
          {
            bookingDate: formatDate(new Date()),
            customerName: "",
            customerMobile: "",
            pickupLocation: "",
            dropLocation: "",
            km: "",
            tripDays: "",
            advanceAmount: "",
            orderAmount: "",
            ac_vehicle: 0,
            notes: "",
          },
        ]);
        back("");
      } else {
        alert("Enter Valid Order Date");
      }
    }
  };

  const handleCustomer = (e, n) => {
    if (n && n?.customer_id) {
      let value = [...billing];
      value[0].customerMobile = n;
      value[0].customerName = n;
      setBilling(value);
    } else {
      let value = [...billing];
      value[0].customerMobile = "";
      value[0].customerName = "";
      setBilling(value);
    }
    // console.log(n);
  };

  const handleMobile = (e, n) => {
    if (n && n.customer_id) {
      let value = [...billing];
      value[0].customerMobile = n;
      value[0].customerName = n;
      setBilling(value);
    } else {
      let value = [...billing];
      value[0].customerMobile = "";
      value[0].customerName = "";
      setBilling(value);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    updateValue();
  }, [billingDetails]);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function getYesterdayDate() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const year = yesterday.getFullYear();
    const month = (yesterday.getMonth() + 1).toString().padStart(2, "0");
    const day = yesterday.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}T00:00`;
  }

  const value = localStorage.getItem("taxinow")
    ? localStorage.getItem("taxinow")
    : "";

  useEffect(() => {
    if (localStorage.getItem("taxinow")) {
    } else {
      navigate("/home/customerReport");
    }
  }, [value]);

  useEffect(() => {
    setButtonDisable(false);
  }, [billing]);

  // console.log(billingDetails);

  return (
    <div className="col-12 grid-margin stretch-card">
      <>
        <Modal
          size="lg"
          className="px-5 mx-5"
          show={lgShow}
          onHide={handleClose}
          animation={false}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <CustomerCreation />
          </Modal.Body>
        </Modal>
      </>
      <div className="card">
        {!billingDetails && (
          <div className="d-flex row justify-content-end">
            <div className="col-3">
              <Link
                to={"/home/order/orderReports"}
                className="btn btn-primary col-12"
              >
                Back
              </Link>
            </div>
          </div>
        )}
        <div className="card-body">
          <h1 className="page-title text-center fs-1">
            {billingDetails?.id ? "Taxi Order Update" : "Taxi Order"}
          </h1>
          <div className="forms-sample pt-2">
            <div className="row justify-content-between mx-1">
              <div className="form-group col-12 col-md-6 row">
                <label
                  for="exampleInputPassword4"
                  className="text-black fs-5 text-start p-0"
                >
                  Order Date & Time
                </label>
                <input
                  type="datetime-local"
                  className="form-control fs-6"
                  value={billing[0].bookingDate}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].bookingDate = e.target.value;
                    setBilling(value);
                    // console.log(e);
                  }}
                  defaultValue={billing[0].bookingDate}
                  min={getYesterdayDate()}
                />

                {billingError[0].bookingDate == "bookingDate" && (
                  <p className="text-danger p-0">
                    Please Enter Valid Booking Date{" "}
                  </p>
                )}
              </div>
            </div>

            <div className="row justify-content-between ">
              <div className="form-group col-md-6">
                <label for="exampleInputName1" className="text-black fs-5 ">
                  Customer Name
                  <MdOutlineLibraryAdd
                    className="fs-5 text-primary ml-2"
                    onClick={handleShow}
                  />
                </label>
                <Autocomplete
                  className="form-control fs-6"
                  id="exampleInputName1"
                  sx={{
                    display: "inline-block",
                    "& input": {
                      width: 200,
                      bgcolor: "background.paper",
                      color: (theme) =>
                        theme.palette.getContrastText(
                          theme.palette.background.paper
                        ),
                    },
                  }}
                  options={customerList}
                  onChange={(event, newValue) =>
                    handleCustomer(event, newValue)
                  }
                  value={billing[0].customerName.customer_name}
                  getOptionLabel={(option) => option.customer_name || ""}
                  inputValue={billing[0].customerName.customer_name}
                  onInputChange={(event, newValue) =>
                    handleCustomer(event, newValue)
                  }
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        type="text"
                        id="exampleInputName1"
                        style={{ border: "none" }}
                        {...params.inputProps}
                        value={billing[0].customerName.customer_name}
                      />
                    </div>
                  )}
                />
                {billing[0].customerMobile?.phone_number && (
                  <p className="text-success">
                    Customer Name:
                    <span className="text-black">
                      {billing[0].customerMobile?.phone_number}
                    </span>
                  </p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label for="exampleInputName1" className="text-black fs-5 ">
                  Customer Mobile No
                </label>
                <Autocomplete
                  className="form-control fs-6"
                  id="exampleInputName1"
                  sx={{
                    display: "inline-block",
                    "& input": {
                      width: 200,
                      bgcolor: "background.paper",
                      color: (theme) =>
                        theme.palette.getContrastText(
                          theme.palette.background.paper
                        ),
                    },
                  }}
                  options={customerList}
                  onChange={(event, newValue) => handleMobile(event, newValue)}
                  value={billing[0].customerMobile.phone_number}
                  getOptionLabel={(option) => option.phone_number || ""}
                  inputValue={billing[0].customerMobile.phone_number}
                  onInputChange={(event, newValue) =>
                    handleMobile(event, newValue)
                  }
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        type="text"
                        id="exampleInputName1"
                        style={{ border: "none" }}
                        {...params.inputProps}
                        value={billing[0].customerMobile.phone_number}
                      />
                    </div>
                  )}
                />
                {billing[0].customerMobile?.phone_number && (
                  <p className="text-success">
                    Customer Name:
                    <span className="text-black">
                      {billing[0].customerMobile?.customer_name}
                    </span>
                  </p>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group  col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Pickup Location
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={billing[0].pickupLocation}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].pickupLocation = e.target.value;
                    setBilling(value);
                  }}
                  disabled={billingDetails?.customer_id}
                />
              </div>
              <div className="form-group  col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Drop Location
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputPassword4"
                  value={billing[0].dropLocation}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].dropLocation = e.target.value;
                    setBilling(value);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group  col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Trip Km
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={billing[0].km}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].km = e.target.value
                      .replace(/[eE]/g, "")
                      .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "")
                      .replace(/^0/, "")
                      .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "");
                    setBilling(value);
                  }}
                  disabled={billingDetails?.customer_id}
                />
              </div>
              <div className="form-group  col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Trip Days
                </label>
                <input
                  type="text"
                  className="form-control fs-6 text-start"
                  id="exampleInputPassword4"
                  value={billing[0].tripDays}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].tripDays = e.target.value
                      .replace(/[eE]/g, "")
                      .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "")
                      .replace(/^0/, "")
                      .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "");
                    setBilling(value);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group  col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Advance Amount
                </label>
                <input
                  type="text"
                  className="form-control fs-6 text-start"
                  id="exampleInputPassword4"
                  value={billing[0].advanceAmount}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].advanceAmount = e.target.value
                      .replace(/[eE]/g, "")
                      .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "")
                      .replace(/^0/, "")
                      .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "");
                    setBilling(value);
                  }}
                />
              </div>
              <div className="form-group  col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Order Amount
                </label>
                <input
                  type="text"
                  className="form-control fs-6 text-start"
                  id="exampleInputPassword4"
                  value={billing[0].orderAmount}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].orderAmount = e.target.value
                      .replace(/[eE]/g, "")
                      .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "")
                      .replace(/^0/, "")
                      .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "");
                    setBilling(value);
                  }}
                />
              </div>
            </div>

            <div className="row form-group  col-md-6 ">
              <label for="exampleInput" className="text-black fs-5">
                A/C Vehicle
              </label>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    // name="inlineRadioOptions"
                    id="1"
                    onChange={(e) => {
                      setBilling([{ ...billing[0], ac_vehicle: 1 }]);
                    }}
                    checked={billing[0].ac_vehicle == 1}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    A/C
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    // name="inlineRadioOptions"
                    id="0"
                    onChange={(e) => {
                      setBilling([{ ...billing[0], ac_vehicle: 0 }]);
                    }}
                    checked={billing[0].ac_vehicle == 0}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    Non A/C
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group  ">
              <label for="exampleInputPassword4" className="text-black fs-5">
                Order Notes
              </label>
              <input
                type="text"
                className="form-control fs-6 text-start"
                id="exampleInputPassword4"
                value={billing[0].notes}
                onChange={(e) => {
                  let value = [...billing];
                  const number = e.target.value;
                  value[0].notes = number;
                  setBilling(value);
                }}
              />
            </div>

            <div className="d-flex col-12  justify-content-center">
              <div className="col-4">
                <button
                  // type="submit"
                  onClick={buttonDisable ? "" : () => bookingForm()}
                  className="btn btn-primary mr-2 w-50 w-100-sm w-100-md w-100-lg w-100-xl"
                  disabled={buttonDisable ? "disabled" : ""}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCreation;
