import React from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { MdOutlinePictureAsPdf } from "react-icons/md";

import { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Dialog, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CustomerCreation from "../../Forms/CustomerCreation";
import VendorCreation from "../../Forms/VendorCreation";
import VehicleCreation from "../../Forms/VehicleCreation";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import { api } from "../../api";
import Expenses from "./Expenses";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExpensesOneDayReport = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editExpenses, setExpenses] = useState();
  const [expensesDetail, setExpensesDetail] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [editData, setEditData] = useState({});

  const handleOpen = (data) => {
    setOpen(true);
    setEditData(data);
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const handleEdit = (rowData) => {
    const check = localStorage.getItem("login");
    // console.log(check.toLowerCase().includes("regular"));
    if (check.toLowerCase().includes("regular")) {
      navigate("/home/Expensesupdate");
      //  console.log(rowData);
      setExpenses(rowData);
      setExpensesDetail(rowData);
    } else {
      alert("Expenses Edit Only for Authorized Person");
    }
  };

  const handleDelete = async (rowData) => {
    const status = rowData.is_active == true ? false : true;
    const id = rowData.vehicle_id;
    setExpensesDetail(status);
    // console.log(rowData);
    // console.log(id);
    // console.log(status);
    const vehicleActive = await axios.post(
      `${api.api}vehicle/isActiveVehicle`,
      { status, id }
    );
    // console.log(vehicleActive);
    setExpensesDetail(rowData.vendor_id);
  };

  const expensesData = async () => {
    const apiData = await axios.get(`${api.api}/expenses/expensesToDayData`);
    // console.log(apiData);
    const value = apiData.data?.expenses;
    const newValues = value
      ?.map((item) => {
        if (
          (item.booking_id > 0 && item.order_status) ||
          (!item.booking_id && !item.order_status)
        ) {
          return item;
        } else {
          return null;
        }
      })
      .filter(Boolean);

    // console.log(value);
    if (value) {
      const database = value?.map((item) => {
        return {
          id: item.id,
          expenses_date: item.expenses_date,
          expenses_type_expenses: item?.expenses_type.expenses,
          vehicle_vehicle_number: item?.Vehicle?.vehicle_number,
          vehicle_vehicle_model: item?.Vehicle?.vehicle_model,
          vehicle_vehicle_type: item?.Vehicle?.vehicle_type,
          // "expenses_id": item.expenses_id,
          // "vehicle_id": item.vehicle_id,
          expenses_amount: item?.expenses_amount,
          expenses_notes: item?.expenses_notes,
          // "is_Active": item.is_Active,
          // "created_at": item.created_at,
          // "updated_at": item.updated_at,
          // Flatten expenses_type properties
          // "expenses_type_id": item.expenses_type.id,
          // "expenses_type_expenses": item.expenses_type.expenses,
          // "expenses_type_is_Active": item.expenses_type.is_Active,
          // "expenses_type_created_at": item.expenses_type.created_at,
          // "expenses_type_updated_at": item.expenses_type.updated_at,
          // Flatten Vehicle properties
          // "vehicle_vehicle_id": item.Vehicle?.vehicle_id,
          // "vehicle_vehicle_model": item.Vehicle?.vehicle_model,
          // "vehicle_vehicle_number": item.Vehicle?.vehicle_number,
          // "vehicle_vehicle_type": item.Vehicle?.vehicle_type,
          // "vehicle_insurance": item.Vehicle?.insurance,
          // "vehicle_fc": item.Vehicle?.fc,
          // "vehicle_is_active": item.Vehicle?.is_active,
          // "vehicle_createdAt": item.Vehicle?.createdAt,
          // "vehicle_updatedAt": item.Vehicle?.updatedAt,
        };
      });

      setExportData(database);
      setData(newValues);
    } else {
      setData([]);
    }

    //   console.log(database);
  };

  // console.log(data);
  // console.log(exportData);
  const columnHelper = createMRTColumnHelper();

  const columns = [
    // columnHelper.accessor('id', {
    //   header: 'No',
    //   size: 40,
    // }),
    columnHelper.accessor("expenses_date", {
      id: "Date", // Unique ID for the column
      header: "Date", // Header title for the column
      filterVariant: "date-range", // Filter variant for filtering dates by range
      size: 20, // Size or width of the column (assuming in pixels)
      accessorFn: (originalRow) => new Date(originalRow.expenses_date), // Function to convert to Date for sorting and filtering
      Cell: ({ cell }) => cell.getValue().toLocaleDateString(), // Function to format cell value as localized date string
    }),
    columnHelper.accessor("expenses_type.expenses", {
      header: "Expenses",
      size: 40,
    }),
    columnHelper.accessor("Vehicle.vehicle_number", {
      header: "Vehicle No",
      size: 40,
    }),
    columnHelper.accessor("expenses_amount", {
      header: "Amount",
      size: 40,
    }),
    columnHelper.accessor("expenses_notes", {
      header: "Note",
      size: 40,
      Cell: ({ row }, rowIndex) => {
        return <Box>{row.original.expenses_notes?.slice(0, 20) + "..."}</Box>;
      },
    }),
    columnHelper.accessor("is_active", {
      accessor: "action",
      header: "Action",
      size: 40,
      Cell: ({ row }, rowIndex) => {
        // console.log(row);
        return (
          <Box>
            <IconButton
              variant="outlined"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
            >
              <VisibilityIcon
                className="text-primary fs-6"
                onClick={() => handleOpen(row)}
              />
            </IconButton>
            {!row.original.booking_id && (
              <IconButton
                variant="outlined"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                onClick={() => handleEdit(row.original)}
              >
                <EditIcon className="fs-6" />
              </IconButton>
            )}
          </Box>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(exportData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    // console.log(rows.original);
    const rowData = rows.map((row) => row.original);
    const value = rowData.map((n) => {
      // console.log(n);
      return {
        "Payment Inv_No": n.id,
        "Expenses Date": n.expenses_date,
        Expenses: n.expenses_type.expenses,
        "Vehicle No": n.Vehicle?.vehicle_number ? n.Vehicle.vehicle_number : "",
        "Expense Amount": n.expenses_amount,
        "Expenses Notes": n.expenses_notes,
      };
    });
    // console.log(value);
    const csv = generateCsv(csvConfig)(value);
    download(csvConfig)(csv);
  };

  const handleExportRowsPDF = (rows) => {
    const rowData = rows.map((row) => row.original);
    // console.log(rowData);
    const value = rowData.map((n) => {
      return {
        "Payment Inv_No": n.id,
        "Expenses Date": n.expenses_date,
        Expenses: n.expenses_type.expenses,
        "Vehicle No": n.Vehicle?.vehicle_number ? n.Vehicle.vehicle_number : "",
        Expense_Amount: n.expenses_amount ? Number(n.expenses_amount) : 0,
        "Expenses Notes": n.expenses_notes,
      };
    });

    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
      // format: [200, 500],
    });
    const totalExpensesAmount = value.reduce(
      (sum, el) => sum + el.Expense_Amount,
      0
    );
    // console.log(totalExpensesAmount);
    const tableData = value.map((row) => Object.values(row));
    const tableHeaders = value.length > 0 ? Object.keys(value[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      foot: [["", "Total", "", "", "Rs." + totalExpensesAmount]],
      columnStyles: {
        5: { halign: "right" },
        5: { halign: "right" },
      },
      headStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      footStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 12,
      },
      showFoot: "lastPage",
    });
    // console.log(tableData);
    doc.save("Payment_Report.pdf");
  };

  const handleExportAllRowsPDF = (rows) => {
    const rowData = data.map((row) => row);
    // console.log(rowData);
    const value = rowData.map((n) => {
      return {
        "Payment Inv_No": n.id,
        "Expenses Date": n.expenses_date,
        Expenses: n.expenses_type.expenses,
        "Vehicle No": n.Vehicle?.vehicle_number ? n.Vehicle.vehicle_number : "",
        Expense_Amount: n.expenses_amount ? Number(n.expenses_amount) : 0,
        "Expenses Notes": n.expenses_notes,
      };
    });

    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
      // format: [200, 500],
    });
    const totalExpensesAmount = value.reduce(
      (sum, el) => sum + el.Expense_Amount,
      0
    );
    // console.log(totalExpensesAmount);
    const tableData = value.map((row) => Object.values(row));
    const tableHeaders = value.length > 0 ? Object.keys(value[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      foot: [["", "Total", "", "", "Rs." + totalExpensesAmount]],
      columnStyles: {
        5: { halign: "right" },
        5: { halign: "right" },
      },
      headStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      footStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 12,
      },
      showFoot: "lastPage",
    });
    // console.log(tableHeaders);
    doc.save("Payment_Report.pdf");
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    // enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    // enableGlobalFilter: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,
    rowPinningDisplayMode: "select-sticky",
    initialState: {
      pagination: { pageSize: 100, pageIndex: 0 },
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          Filter
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportAllRowsPDF()}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          Filter
        </Button>
        <Button>
          <Link to="/home/ExpensesCreate">Create</Link>
        </Button>
      </Box>
    ),
  });
  //

  // console.log(expensesDetail);
  useEffect(() => {
    expensesData();
  }, []);

  useEffect(() => {
    // console.log("Edit")
    if (expensesDetail == undefined) {
      navigate("/home/ExpensesOneDayReport");
    }
  }, [editExpenses]);

  useEffect(() => {
    // if(expensesDetail.id <1){
    navigate("/home/ExpensesOneDayReport");
    // }
  }, []);

  // console.log(editExpenses);

  useEffect(() => {
    expensesData();
  }, [expensesDetail]);

  useEffect(() => {
    expensesData();
  }, [editExpenses]);

  // useEffect(()=>{
  //     expensesData();
  //   },[expensesDetail])

  // console.log(editData.original);
  return (
    <div>
      {editExpenses ? (
        <div>
          <div className="d-flex col-12 justify-content-end">
            <button
              className="btn btn-primary col-2"
              onClick={() => {
                setExpenses();
                navigate("/home/Expensesupdate");
              }}
            >
              Back
            </button>
          </div>
          <Expenses expensesDetail={expensesDetail} back={setExpenses} />{" "}
        </div>
      ) : (
        <div>
          <h1 className="text-center">Today Expenses </h1>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MaterialReactTable table={table} />
          </LocalizationProvider>
        </div>
      )}

      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <DialogContent>
              <button
                onClick={handleClose}
                className="btn text-dark bg-white btn-outline-light fs-5 col-2 p-2 m-0 mt-2"
                style={{ position: "absolute", top: "1px", right: "1px" }}
              >
                X
              </button>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Expenses Date"
                type="text"
                fullWidth
                defaultValue={editData.original.expenses_date}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="Expenses"
                type="email"
                fullWidth
                defaultValue={editData.original.expenses_type?.expenses}
                InputProps={{
                  readOnly: true,
                }}
              />
              {/* <TextField
            margin="dense"
            id="mobile"
            label="Customer Address"
            type="address"
            fullWidth
            defaultValue={row.original.item.address}
            InputProps={{
              readOnly: true,
            }}
          /> */}
              <TextField
                margin="dense"
                id="mobile"
                label="Vehicle No"
                type="city"
                fullWidth
                defaultValue={
                  editData.original.Vehicle?.vehicle_number
                    ? editData.original.Vehicle.vehicle_number
                    : "Null"
                }
                InputProps={{
                  readOnly: true,
                }}
              />
              {/* <TextField
            margin="dense"
            id="mobile"
            label="Expenses Amount"
            type="city"
            fullWidth
            defaultValue={editData.original.expenses_amount}
            InputProps={{
              readOnly: true,
            }}
          /> */}
              <TextField
                margin="dense"
                id="mobile"
                label="Vehicle No"
                type="city"
                fullWidth
                defaultValue={editData.original.expenses_amount}
                InputProps={{
                  readOnly: true,
                }}
              />
              <h4>Notes:</h4>
              <p>{editData.original.expenses_notes}</p>
              {/* <Button onClick={()=>{console.log(row.original.item)}}>click</Button> */}
            </DialogContent>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ExpensesOneDayReport;
