import React from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { MdOutlinePictureAsPdf } from "react-icons/md";

import { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Dialog, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import { api } from "../api";
import BookingCreate from "./Booking";
import { TbFileInvoice } from "react-icons/tb";
import Invoice from "../Invoice/invoice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Booking = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editBilling, setBilling] = useState();
  const [customerDetail, setBillingDetail] = useState([]);
  const [editData, setEditData] = useState({});
  const [billInvoice, setBillInvoice] = useState(false);

  const handleOpen = (data) => {
    setOpen(true);
    setEditData(data);
  };
  const handleClose = () => setOpen(false);

  const handleEdit = (rowData) => {
    //  console.log(rowData);
    const check = localStorage.getItem("login");
    // console.log(check.toLowerCase().includes("regular"));
    if (check.toLowerCase().includes("regular")) {
      setBillInvoice(false);
      setBilling(rowData);
    } else {
      alert("Booking Edit Only for Authorized Person");
    }
  };

  const handleInvoice = (rowData) => {
    // console.log(rowData);
    setBilling(rowData);
    setBillInvoice(true);
  };

  const handleDelete = async (rowData) => {
    // console.log(rowData.item.is_active)
    const status = rowData.item.is_active == true ? false : true;
    const id = rowData.item.booking_id;
    const customerActive = await axios.post(
      `${api.api}/booking/updateBookingIsActive`,
      { status, id }
    );
    // console.log(id);
    // console.log(rowData);
    setBillingDetail(customerActive);
  };

  const billingData = async () => {
    const apiData = await axios.get(`${api.api}/booking/bookingReports`);
    // console.log(apiData.data.booking);
    const value = apiData.data.booking;
    const database = value.map((item) => {
      return {
        item,
        payment: item?.payments[0] || [],
        diesel: item?.expenses[0] || [],
        driverBata: item?.expenses[1] || [],
      };
    });
    setData(database);
    // console.log(database);
  };

  // console.log(data);
  const columnHelper = createMRTColumnHelper();

  const columns = [
    // columnHelper.accessor('item.booking_id', {
    //   header: 'Invoice No',
    //   size: 40,
    // }),
    columnHelper.accessor("item.booking_date", {
      header: "Trip Date",
      size: 20,
      Cell: ({ row }, rowIndex) => {
        // console.log(row);
        const formattedDate = row?.original?.item.booking_date
          ? new Date(row.original.item.booking_date).toLocaleDateString(
              undefined,
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )
          : "";
        return <span>{formattedDate}</span>;
      },
    }),
    columnHelper.accessor("item.customer.customer_name", {
      header: "Customer Name",
      size: 20,
    }),
    columnHelper.accessor("item.customer.phone_number", {
      header: "MobileNo",
      size: 20,
    }),
    columnHelper.accessor("item.vehicle.vehicle_number", {
      header: "Vehicle No",
      size: 20,
    }),
    // columnHelper.accessor('item.driver.driver_name', {
    //   header: 'Driver Name',
    //   size: 20,
    // }),
    // columnHelper.accessor('item.driver.phone_number', {
    //   header: 'MobileNo',
    //   size: 20,
    // }),
    // columnHelper.accessor('item.pickup_location', {
    //   header: 'Pickup Location',
    //   size: 40,
    // }),
    // columnHelper.accessor('item.booking_date', {
    //   header: 'Pickup Date',
    //   size: 40,
    // }),
    // columnHelper.accessor('item.dropoff_location', {
    //   header: 'Drop Location',
    //   size: 40,
    // }),
    columnHelper.accessor("item.km", {
      header: "Total KM",
      size: 40,
    }),
    // columnHelper.accessor('item.advance', {
    //   header: 'Advance Amount',
    //   size: 40,
    // }),
    columnHelper.accessor("item.bill_amount", {
      header: "Bill Amount",
      size: 40,
    }),
    columnHelper.accessor("payment.to_be_paid", {
      header: "Pending Amount",
      size: 40,
    }),
    columnHelper.accessor("diesel.expenses_amount", {
      header: "Diesel Expenses",
      size: 40,
    }),
    columnHelper.accessor("driverBata.expenses_amount", {
      header: "Driver Bata & Extra",
      size: 40,
    }),
    columnHelper.accessor("item.is_active", {
      accessor: "action",
      header: "Invoice",
      size: 40,
      Cell: ({ row }, rowIndex) => {
        // console.log(row);
        return (
          <Box className="p-0">
            <IconButton
              variant="outlined"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Edit"
              onClick={() => handleInvoice(row.original)}
            >
              <TbFileInvoice className="text-info" />
            </IconButton>
          </Box>
        );
      },
    }),
    columnHelper.accessor("item", {
      accessor: "action",
      header: "Action",
      size: 40,
      Cell: ({ row }, rowIndex) => {
        // console.log(row);
        return (
          <Box>
            <IconButton
              variant="outlined"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
            >
              <VisibilityIcon
                className="text-primary fs-6"
                onClick={() => handleOpen(row)}
              />
            </IconButton>
            {/* } */}
            {row.original.item.is_active === true && (
              <IconButton
                variant="outlined"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                onClick={() => handleEdit(row.original)}
              >
                <EditIcon className="fs-6" />
              </IconButton>
            )}
            <IconButton
              variant="outlined"
              data-toggle="tooltip"
              data-placement="bottom"
              title={row.original.item.is_active == true ? "Delete" : "Active"}
              onClick={() => handleDelete(row.original)}
            >
              {row.original.item.is_active === true ? (
                <CancelIcon className="text-danger fs-6" />
              ) : (
                <CheckCircleIcon className="text-success fs-6" />
              )}
            </IconButton>
          </Box>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const value = rowData.map((n) => {
      return n;
    });
    // console.log(value);
    const item = value.map((single) => {
      return {
        "Invoice No": single.item.booking_id,
        "Customer Name ": single.item.customer.customer_name,
        "Customer Mobile No ": single.item.customer.phone_number,
        "Customer Address ": single.item.customer.address,
        "Vehicle No": single.item.vehicle.vehicle_number,
        "Vehicle Type": single.item.vehicle.vehicle_type,
        "Driver Name ": single.item.driver.driver_name,
        "Driver Mobile No ": single.item.driver.phone_number,
        "Booking Date & Time": new Date(
          single.item.booking_date
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        "Drop Date & Time": new Date(single.item.dropping_time).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Pickup Location": single.item.pickup_location,
        "Drop Location": single.item.dropoff_location,
        "Total KM": single.item.km,
        "Invoice Amount": single.item.bill_amount,
        "Advance Payment Type": single.item.payment_mode,
        "Advance Amount": single.item.advance,
        "Customer Bill Payment Type ": single.payment.payment_mode,
        "Customer Paid Amount ": single.payment.our_amount,
        "Customer Outstanding Amount ": single.payment.to_be_paid,
        "GST ": single.item.gst,
        "CGST 6% ": single.payment.cgst == null ? 0 : single.payment.cgst,
        "SGST 6%": single.payment.sgst == null ? 0 : single.payment.sgst,
        "Diesel Expenses": single.diesel.expenses_amount,
        "Driver Bata & Extra Charges": single.driverBata.expenses_amount,
      };
    });
    const csv = generateCsv(csvConfig)(item);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const value = data.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Invoice No": single.item.booking_id,
        "Customer Name ": single.item.customer.customer_name,
        "Customer Mobile No ": single.item.customer.phone_number,
        "Customer Address ": single.item.customer.address,
        "Vehicle No": single.item.vehicle.vehicle_number,
        "Vehicle Type": single.item.vehicle.vehicle_type,
        "Driver Name ": single.item.driver.driver_name,
        "Driver Mobile No ": single.item.driver.phone_number,
        "Booking Date & Time": new Date(
          single.item.booking_date
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        "Drop Date & Time": new Date(single.item.dropping_time).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Pickup Location": single.item.pickup_location,
        "Drop Location": single.item.dropoff_location,
        "Total KM": single.item.km,
        "Invoice Amount": single.item.bill_amount,
        "Advance Payment Type": single.item.payment_mode,
        "Advance Amount": single.item.advance,
        "Customer Bill Payment Type ": single.payment.payment_mode,
        "Customer Paid Amount ": single.payment.our_amount,
        "Customer Outstanding Amount ": single.payment.to_be_paid,
        "GST ": single.item.gst,
        "CGST 6% ": single.payment.cgst == null ? 0 : single.payment.cgst,
        "SGST 6%": single.payment.sgst == null ? 0 : single.payment.sgst,
        "Diesel Expenses": single.diesel.expenses_amount,
        "Driver Bata & Extra Charges": single.driverBata.expenses_amount,
      };
    });
    const csv = generateCsv(csvConfig)(item);
    download(csvConfig)(csv);
  };

  const handleExportRowsPDF = (rows) => {
    const rowData = rows.map((row) => row.original);
    // console.log(rowData);
    const value = rowData.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Invoice No": single.item.booking_id,
        "Customer Name ": single.item.customer.customer_name,
        "Customer Mobile No ": single.item.customer.phone_number,
        "Customer Address ": single.item.customer.address,
        "Vehicle No": single.item.vehicle.vehicle_number,
        "Vehicle Type": single.item.vehicle.vehicle_type,
        "Driver Name ": single.item.driver.driver_name,
        "Driver Mobile No ": single.item.driver.phone_number,
        "Booking Date & Time": new Date(
          single.item.booking_date
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        "Drop Date & Time": new Date(single.item.dropping_time).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Pickup Location": single.item.pickup_location,
        "Drop Location": single.item.dropoff_location,
        "Total KM": single.item.km,
        "Invoice Amount": single.item.bill_amount,
        "Advance Payment Type": single.item.payment_mode,
        "Advance Amount": single.item.advance,
        "Customer Bill Payment Type ": single.payment.payment_mode,
        "Customer Paid Amount ": single.payment.our_amount,
        "Customer Outstanding Amount ": single.payment.to_be_paid,
        "GST ": single.item.gst,
        "CGST 6% ": single.payment.cgst == null ? 0 : single.payment.cgst,
        "SGST 6%": single.payment.sgst == null ? 0 : single.payment.sgst,
        "Diesel Expenses": single.diesel.expenses_amount,
        "Driver Bata & Extra Charges": single.driverBata.expenses_amount,
      };
    });

    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
      format: [200, 600],
    });
    const tableData = item.map((row) => Object.values(row));
    const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });
    // console.log(tableHeaders);
    doc.save("Booking_Report.pdf");
  };

  const handleExportAllRowsPDF = (rows) => {
    const rowData = data.map((row) => row);
    // console.log(rowData);
    const value = rowData.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Invoice No": single.item.booking_id,
        "Customer Name ": single.item.customer.customer_name,
        "Customer Mobile No ": single.item.customer.phone_number,
        "Customer Address ": single.item.customer.address,
        "Vehicle No": single.item.vehicle.vehicle_number,
        "Vehicle Type": single.item.vehicle.vehicle_type,
        "Driver Name ": single.item.driver.driver_name,
        "Driver Mobile No ": single.item.driver.phone_number,
        "Booking Date & Time": new Date(
          single.item.booking_date
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        "Drop Date & Time": new Date(single.item.dropping_time).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Pickup Location": single.item.pickup_location,
        "Drop Location": single.item.dropoff_location,
        "Total KM": single.item.km,
        Invoice_Amount: single.item.bill_amount,
        "Advance Payment Type": single.item.payment_mode,
        Advance_Amount: single.item.advance,
        "Customer Bill Payment Type ": single.payment.payment_mode,
        Customer_Paid_Amount:
          single.payment.our_amount !== NaN
            ? Number(single.payment.our_amount)
            : 0,
        Customer_Outstanding_Amount: single.payment.to_be_paid,
        "GST ": single.item.gst,
        CGST: single.payment.cgst == null ? 0 : single.payment.cgst,
        SGST: single.payment.sgst == null ? 0 : single.payment.sgst,
        "Diesel Expenses": single.diesel.expenses_amount,
        "Driver Bata & Extra Charges": single.driverBata.expenses_amount,
      };
    });

    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
      format: [200, 600],
    });

    const totalAmount = item.reduce((sum, el) => sum + el.Invoice_Amount, 0);
    const totalAdvance = item.reduce((sum, el) => sum + el.Advance_Amount, 0);
    const totalCustomrPayment = item.reduce(
      (sum, el) => sum + Number(el.Customer_Paid_Amount),
      0
    );
    // const totalCustomrOutstanding=item.reduce((sum,el)=>sum+el.Customer_Outstanding_Amount,0);
    // const totalCGST=item.reduce((sum,el)=>sum+el.CGST,0);
    // console.log(totalAmount);
    const tableData = item.map((row) => Object.values(row));
    const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          "",
          "",
          "",
          "",
          "",
          totalAmount,
          "",
          totalAdvance,
          "",
          totalCustomrPayment,
          "",
          "",
          "",
          "",
        ],
      ],
      columnStyles: {
        4: { halign: "right" },
        5: { halign: "right" },
      },
      headStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      footStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 12,
      },
      showFoot: "lastPage",
    });
    // console.log(tableHeaders);
    doc.save("Booking_Report.pdf");
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 10, //adjust the size of the row select column
        grow: false, //new in v2.8 (default is false for this column)
      },
      "mrt-row-numbers": {
        size: 20,
        grow: true, //new in v2.8 (allow this column to grow to fill in remaining space)
      },
    },
    enableRowNumbers: true,
    // enableRowSelection: true,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          All
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          Filter
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportAllRowsPDF()}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          Filter
        </Button>
        <Button>
          <Link to="/home/bookingCreate">Create</Link>
        </Button>
      </Box>
    ),
  });

  // console.log(data)
  useEffect(() => {
    billingData();
  }, []);

  useEffect(() => {
    billingData();
  }, [editBilling]);

  useEffect(() => {
    billingData();
  }, [customerDetail]);

  // console.log(editBilling);

  let navigate = useNavigate();
  useEffect(() => {
    if (editBilling == undefined) {
      navigate("/home/bookingReport");
    }
  }, [editBilling]);

  return (
    <div>
      {editBilling?.item?.booking_id > 0 ? (
        <div>
          <div className="d-flex col-12 justify-content-end">
            <button
              className="btn btn-primary col-2"
              onClick={() => {
                setBilling();
                setBillInvoice("");
              }}
            >
              Back
            </button>
          </div>
          {billInvoice == true ? (
            <Invoice invoiceDetail={editBilling} />
          ) : (
            <BookingCreate
              billingDetails={editBilling}
              setEditBilling={setBilling}
              back={setBilling}
            />
          )}
        </div>
      ) : (
        <div>
          <h1 className="text-center">Booking</h1>
          <MaterialReactTable table={table} />
        </div>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Customer name"
                type="text"
                fullWidth
                defaultValue={editData.original.item.customer.customer_name}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="Customer Mobile No"
                type="email"
                fullWidth
                defaultValue={editData.original.item.customer.phone_number}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="Customer Address"
                type="address"
                fullWidth
                defaultValue={editData.original.item.customer.address}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="Customer City"
                type="city"
                fullWidth
                defaultValue={editData.original.item.customer.city}
                InputProps={{
                  readOnly: true,
                }}
              />
            </DialogContent>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Booking;
