import React from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { MdOutlinePictureAsPdf } from "react-icons/md";

import { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Dialog, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import { api } from "../api";
import OrderCreation from "./OrderCreation";
import { TbFileInvoice } from "react-icons/tb";
import Invoice from "../Invoice/invoice";
import { TbAirConditioning } from "react-icons/tb";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OrderReports = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editBilling, setBilling] = useState();
  const [customerDetail, setBillingDetail] = useState([]);
  const [editData, setEditData] = useState({});
  const [billInvoice, setBillInvoice] = useState(false);

  const handleOpen = (data) => {
    setOpen(true);
    setEditData(data);
  };
  const handleClose = () => setOpen(false);

  const handleEdit = (rowData) => {
    setBillInvoice(rowData);
    setBilling(rowData);
  };

  const handleInvoice = (rowData) => {
    setBilling(rowData);
    setBillInvoice(true);
  };

  const handleDelete = async (rowData) => {
    setEditData(true);
    const id = rowData.id;
    const status =
      rowData.orderStatus == false || rowData.orderStatus == null
        ? true
        : false;
    // console.log(row);

    const orderStatus = await axios.post(`${api.api}/orders/orderDelete`, {
      status,
      id,
    });
    setEditData("");
  };

  const orderConfirm = async (row) => {
    setEditData(true);
    const id = row.id;
    const status =
      row.orderStatus == false || row.orderStatus == null ? true : false;
    // console.log(row);

    const orderStatus = await axios.post(`${api.api}/orders/updateStatus`, {
      status,
      id,
    });
    setEditData("");
    // console.log(orderStatus);
  };

  const billingData = async () => {
    const apiData = await axios.get(`${api.api}/orders/ordersDashboard`);
    // console.log(apiData.data.order);
    const value = apiData.data.order;
    setData(value);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("orderDate", {
      header: "Trip Date",
      size: 20,
      Cell: ({ row }, rowIndex) => {
        const formattedDate = row?.original?.orderDate
          ? new Date(row.original.orderDate).toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "";
        return <span>{formattedDate}</span>;
      },
    }),
    columnHelper.accessor("customer.customer_name", {
      header: "Customer Name",
      size: 40,
    }),
    columnHelper.accessor("customer.phone_number", {
      header: "Mobile No",
      size: 40,
    }),
    columnHelper.accessor("trip_days", {
      header: "Trip Days",
      size: 20,
    }),
    columnHelper.accessor("ac", {
      header: "AC/Non-AC",
      size: 20,
      Cell: ({ row }, rowIndex) => {
        // console.log(row.original);
        return (
          <Box>
            <TbAirConditioning
              className={
                row.original.ac == false
                  ? "fs-3 text-danger"
                  : "fs-3 text-success"
              }
              data-toggle="tooltip"
              data-placement="bottom"
              title={row.original.ac == false ? "Non-AC" : "AC"}
            />
          </Box>
        );
      },
    }),
    columnHelper.accessor("orderStatus", {
      header: "Confirm",
      size: 20,
      Cell: ({ row }, rowIndex) => {
        // console.log(row.original);
        return (
          <Box>
            <input
              type="checkbox"
              checked={row.original.orderStatus == 1}
              onClick={() => {
                orderConfirm(row.original);
              }}
            />
          </Box>
        );
      },
    }),
    columnHelper.accessor("trip_start", {
      header: "Pickup Location",
      size: 20,
    }),
    columnHelper.accessor("trip_end", {
      header: "Drop Location",
      size: 20,
    }),

    columnHelper.accessor("trip_km", {
      header: "Trip Km",
      size: 20,
    }),
    columnHelper.accessor("order_advance", {
      header: "Advance",
      size: 20,
    }),
    columnHelper.accessor("total_amount", {
      header: "Trip Amount",
      size: 40,
    }),
    // columnHelper.accessor("is_active", {
    //   accessor: "is_active",
    //   header: "Action",
    //   size: 40,
    //   Cell: ({ row }, rowIndex) => {
    //     return (
    //       <Box>
    //         {row.original.is_active === true && (
    //           <IconButton
    //             variant="outlined"
    //             data-toggle="tooltip"
    //             data-placement="bottom"
    //             title="Edit"
    //             onClick={() => handleEdit(row.original)}
    //           >
    //             <EditIcon className="fs-6" />
    //           </IconButton>
    //         )}
    //         <IconButton
    //           variant="outlined"
    //           data-toggle="tooltip"
    //           data-placement="bottom"
    //           title={row.original.is_active == true ? "Delete" : "Active"}
    //           onClick={() => handleDelete(row.original)}
    //         >
    //           {row.original.is_active === true ? (
    //             <CancelIcon className="text-danger fs-6" />
    //           ) : (
    //             <CheckCircleIcon className="text-success fs-6" />
    //           )}
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const value = rowData.map((n) => {
      return n;
    });
    // console.log(value);
    const item = value.map((single) => {
      return {
        "Order Date & Time": new Date(single.orderDate).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Customer Name ": single?.customer?.customer_name,
        "Customer Mobile No ": single?.customer?.phone_number,
        "Customer Address ": single?.customer?.address,
        "Trip Days": single.trip_days,
        ac: single.ac == 0 ? "Non-AC" : "AC",
        "Pickup Location": single.trip_start,
        "Drop Location": single.trip_end,
        "Total KM": single.trip_km,
        "Advance Amount": single.order_advance,
        "Order Amount": single.total_amount,
      };
    });
    const csv = generateCsv(csvConfig)(item);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const value = data.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Order Date & Time": new Date(single.orderDate).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Customer Name ": single?.customer?.customer_name,
        "Customer Mobile No ": single?.customer?.phone_number,
        "Customer Address ": single?.customer?.address,
        "Trip Days": single.trip_days,
        ac: single.ac == 0 ? "Non-AC" : "AC",
        "Pickup Location": single.trip_start,
        "Drop Location": single.trip_end,
        "Total KM": single.trip_km,
        "Advance Amount": single.order_advance,
        "Order Amount": single.total_amount,
      };
    });
    const csv = generateCsv(csvConfig)(item);
    download(csvConfig)(csv);
  };

  const handleExportRowsPDF = (rows) => {
    const rowData = rows.map((row) => row.original);
    // console.log(rowData);
    const value = rowData.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Order Date & Time": new Date(single.orderDate).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Customer Name ": single?.customer?.customer_name,
        "Customer Mobile No ": single?.customer?.phone_number,
        "Customer Address ": single?.customer?.address,
        "Trip Days": single.trip_days,
        ac: single.ac == 0 ? "Non-AC" : "AC",
        "Pickup Location": single.trip_start,
        "Drop Location": single.trip_end,
        "Total KM": single.trip_km,
        "Advance Amount": single.order_advance,
        "Order Amount": single.total_amount,
      };
    });

    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
      format: [200, 600],
    });
    const tableData = item.map((row) => Object.values(row));
    const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });
    // console.log(tableHeaders);
    doc.save("Booking_Report.pdf");
  };

  const handleExportAllRowsPDF = (rows) => {
    const rowData = data.map((row) => row);
    // console.log(rowData);
    const value = rowData.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Order Date & Time": new Date(single.orderDate).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Customer Name ": single?.customer?.customer_name,
        "Customer Mobile No ": single?.customer?.phone_number,
        "Customer Address ": single?.customer?.address,
        "Trip Days": single.trip_days,
        ac: single.ac == 0 ? "Non-AC" : "AC",
        "Pickup Location": single.trip_start,
        "Drop Location": single.trip_end,
        "Total KM": single.trip_km,
        "Advance Amount": single.order_advance,
        "Order Amount": single.total_amount,
      };
    });

    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
      format: [200, 600],
    });

    const totalAmount = item.reduce((sum, el) => sum + el.Invoice_Amount, 0);
    const totalAdvance = item.reduce((sum, el) => sum + el.Advance_Amount, 0);
    const totalCustomrPayment = item.reduce(
      (sum, el) => sum + Number(el.Customer_Paid_Amount),
      0
    );
    // const totalCustomrOutstanding=item.reduce((sum,el)=>sum+el.Customer_Outstanding_Amount,0);
    // const totalCGST=item.reduce((sum,el)=>sum+el.CGST,0);
    // console.log(totalAmount);
    const tableData = item.map((row) => Object.values(row));
    const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      // foot: [
      //   [
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "Total",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     totalAmount,
      //     "",
      //     totalAdvance,
      //     "",
      //     totalCustomrPayment,
      //     "",
      //     "",
      //     "",
      //     "",
      //   ],
      // ],
      columnStyles: {
        4: { halign: "right" },
        5: { halign: "right" },
      },
      headStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      footStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 12,
      },
      showFoot: "lastPage",
    });
    // console.log(tableHeaders);
    doc.save("Booking_Report.pdf");
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 10, //adjust the size of the row select column
        grow: false, //new in v2.8 (default is false for this column)
      },
      "mrt-row-numbers": {
        size: 20,
        grow: true, //new in v2.8 (allow this column to grow to fill in remaining space)
      },
    },
    enableRowNumbers: true,
    // enableRowSelection: true,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          All
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          Filter
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportAllRowsPDF()}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          Filter
        </Button>
        <Button>
          <Link to="/home/order/orderCreation">Create</Link>
        </Button>
      </Box>
    ),
  });

  //   console.log(editData);

  useEffect(() => {
    billingData();
  }, []);

  useEffect(() => {
    billingData();
  }, [editBilling]);

  useEffect(() => {
    billingData();
  }, [customerDetail]);

  // console.log(editBilling);

  let navigate = useNavigate();
  useEffect(() => {
    billingData();
  }, [editData]);

  return (
    <div>
      {editBilling ? (
        <div>
          <div className="d-flex col-12 justify-content-end">
            <button
              className="btn btn-primary col-2"
              onClick={() => {
                setBilling();
                setBillInvoice("");
              }}
            >
              Back
            </button>
          </div>
          <OrderCreation
            billingDetails={editBilling}
            setEditBilling={setBilling}
            back={setBilling}
          />
        </div>
      ) : (
        <div>
          <h1 className="text-start text-success">Trip Orders Plans</h1>
          <MaterialReactTable table={table} />
        </div>
      )}
    </div>
  );
};

export default OrderReports;
