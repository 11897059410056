import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { api } from "../../api";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { setHours, setMinutes } from 'date-fns';
import { setHours, setMinutes, getMinutes, getHours, format } from "date-fns";

const Vendor_Payment_Form = ({ billingDetails, setEditBilling, back }) => {
  const [billing, setBilling] = useState([
    {
      vendor: "",
      paymentDate: "",
      billAmount: "",
      payment: "",
      balance: "",
      notes: "",
    },
  ]);
  const [billingError, setBillingError] = useState([
    {
      vendor: "",
      paymentDate: "",
      billAmount: "",
      payment: "",
      balance: "",
      notes: "",
    },
  ]);

  const [startDate, setStartDate] = useState(new Date());

  const navigate = useNavigate();

  const [driverList, setDriverList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [changeCustomerList, setChangeCustomerList] = useState([]);

  const updateValue = () => {
    if (billingDetails?.item) {
      navigate("/home/VendorPaymentUpdate");
      let value = [...billing];

      value[0].vendor = billingDetails?.vendor;
      value[0].paymentDate = new Date();
      value[0].billAmount = billingDetails?.item?.unpaid;
    }
  };

  const bookingForm = async () => {
    validation();
    const vendor = billing[0]?.vendor;
    const paymentDate = billing[0]?.paymentDate;
    const payment = billing[0]?.payment;
    const balance = balanceAmount();
    const notes = billing[0]?.notes;
    const billAmount = billing[0]?.billAmount;
    const id = billingDetails?.item?.id;

    if (balance >= 0) {
      if (paymentDate?.toString()?.length == 55) {
        // console.log("date")
        if (Number(payment) >= 1) {
          const bookTaxi = await axios.post(
            `${api.api}/vendorPayment/updatePayment`,
            { paymentDate, payment, balance, notes, id }
          );
          alert("Successfully Updated");
          back("");
        } else {
          alert("please Give Valid Information");
        }
      } else {
        alert("please Give Valid Information");
      }
    } else {
      alert("Your Enter Excess Payment Amount");
    }
  };

  const balanceAmount = (e) => {
    // Add 'e' as a parameter
    const bill = billing[0]?.billAmount;
    const pay = billing[0]?.payment;
    const balanceAmt = Number(bill) - Number(pay);
    return balanceAmt;
  };

  useEffect(() => {
    balanceAmount();
  }, [billing]);

  useEffect(() => {
    updateValue();
  }, [billingDetails]);

  const validation = () => {
    const paymentDate = billing[0].paymentDate;
    const payment = billing[0].payment;
    const notes = billing[0].notes;

    let value = [...billingError];

    if (!paymentDate || paymentDate.toString().length !== 55) {
      value[0].paymentDate = "paymentDate";
    } else {
      value[0].paymentDate = "";
    }

    if (payment > 0) {
      value[0].payment = "";
    } else {
      value[0].payment = "payment";
    }

    setBillingError(value);
  };

  // console.log(customerList);
  // console.log(billingError);

  return (
    <div className="col-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h1 className="page-title text-center fs-1">Vendor Payment Update</h1>
          <div className="forms-sample pt-2">
            <div className="row">
              <div className="form-group  col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Vendor Name
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={billing[0].vendor?.name}
                  // onChange={(e) => {
                  //   let value = [...billing];
                  //   value[0].vendor.name = e.target.value;
                  //   setBilling(value);
                  // }}
                  disabled
                />
                {billingError[0].pickupLocation == "pickup" && (
                  <p className="text-danger">
                    Please Enter Valid Pickup Location{" "}
                  </p>
                )}
              </div>
              <div className="form-group col-12 col-md-6  row">
                <label
                  for="exampleInputPassword4"
                  className="text-black fs-5 p-0"
                >
                  Payment Date
                </label>
                <DatePicker
                  className="form-control fs-6"
                  selected={billing[0].paymentDate}
                  onChange={(date) => {
                    let value = [...billing];
                    value[0].paymentDate = date;
                    setBilling(value);
                  }}
                  dateFormat="dd,MMMM, yyyy"
                  maxDate={new Date()}
                />
                {billingError[0].paymentDate == "paymentDate" && (
                  <p className="text-danger p-0">
                    Please Enter Valid Payment Date{" "}
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group  col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Balance Amount
                </label>
                <input
                  type="number"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={billing[0].billAmount}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].billAmount = e.target.value;
                    setBilling(value);
                  }}
                  disabled
                />
              </div>
              <div className="form-group col-12 col-md-6  row">
                <label
                  for="exampleInputPassword4"
                  className="text-black fs-5 p-0"
                >
                  Payment Amount
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={billing[0].payment}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].payment = e.target.value.replace(
                      /[^0-9]|(?<=\d)-(?=\d)/g,
                      ""
                    );
                    setBilling(value);
                  }}
                  disabled={billingDetails?.customer_id}
                />
                {billingError[0].payment == "payment" && (
                  <p className="text-danger p-0">
                    Please Enter Valid Payment Amount{" "}
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group  col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Outstanding Amount
                </label>
                <input
                  type="number"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={balanceAmount()}
                  onchange={(e) => {
                    let value = [...billing];
                    value[0].balance = e.target.value; // Fix the assignment
                    setBilling(value);
                  }}
                  disabled
                />
              </div>
              <div className="form-group col-12 col-md-6  row">
                <label
                  for="exampleInputPassword4"
                  className="text-black fs-5 p-0"
                >
                  Notes
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={billing[0].notes}
                  onChange={(e) => {
                    let value = [...billing];
                    value[0].notes = e.target.value;
                    setBilling(value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex col-12  justify-content-center">
              <div className="col-4">
                <button
                  // type="submit"
                  onClick={() => bookingForm()}
                  className="btn btn-primary mr-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor_Payment_Form;
