import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../api";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { setHours, setMinutes } from 'date-fns';
import { setHours, setMinutes, getMinutes, getHours, format } from "date-fns";

const VehicleCreation = ({ vehicleDetail, setVehicleDetail, back }) => {
  const [vehicle, setVehicle] = useState([
    {
      vehicleModel: "",
      vehicleNumber: "",
      vendor: "",
      vehicleType: "",
      vehicleInsurance: "",
      vehicleFC: "",
      pollutionDate: "",
    },
  ]);
  const [vendorList, setVendorList] = useState([]);
  const [error, setError] = useState({
    number: "",
    model: "",
    type: "",
    insurance: "",
    fc: "",
    vendor: "",
    pollutionDate: "",
  });

  const newVehicle = async () => {
    validation();
    if (vehicleDetail?.vehicle_id) {
      const vehicleModel = vehicle[0].vehicleModel;
      const vehicleNumber = vehicle[0].vehicleNumber.trim().toUpperCase();
      const vehicleType = vehicle[0].vehicleType;
      const vehicleInsurance = new Date(vehicle[0].vehicleInsurance);
      const vehicleFC = new Date(vehicle[0].vehicleFC);
      const id = vehicleDetail.vehicle_id;
      const pollution = vehicle[0].pollutionDate
        ? new Date(vehicle[0].pollutionDate)
        : null;
      const vendor = vehicle[0].vendor;

      if (
        vehicleModel?.trim().length >= 2 &&
        vehicleNumber?.trim().length >= 3 &&
        vehicleType?.trim().length >= 3 &&
        vehicleInsurance?.toString().length == 55 &&
        vehicleFC?.toString().length == 55 &&
        pollution?.toString().length == 55
      ) {
        if (
          vehicleInsurance &&
          vehicleInsurance.toString() !==
            "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
        ) {
          if (
            pollution &&
            pollution.toString() !==
              "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
          ) {
            const createVehicle = await axios.post(
              `${api.api}/vehicle/updateVehicle`,
              {
                vehicleModel,
                vehicleNumber,
                vehicleType,
                vehicleInsurance,
                vehicleFC,
                id,
                vendor,
                pollution,
              }
            );
            back();
            setVehicle([
              {
                vehicleModel: "",
                vehicleNumber: "",
                vehicleType: "",
                vehicleInsurance: "",
                vehicleFC: "",
                pollutionDate: "",
              },
            ]);
            // console.log(createVehicle);
            setVehicleDetail({
              vehicleModel: "",
              vehicleNumber: "",
              vehicleType: "",
              vehicleInsurance: "",
              vehicleFC: "",
              pollutionDate: "",
            });
            alert("Update Successfully");
          } else {
            alert("Please Give Valid Information");
          }
        } else {
          alert("Please Give Valid Information");
        }
      } else {
        alert("Please Give Valid Information");
      }
    } else {
      const vehicleModel = vehicle[0].vehicleModel;
      const vehicleNumber = vehicle[0].vehicleNumber.trim().toUpperCase();
      const vehicleType = vehicle[0].vehicleType;
      const vehicleInsurance = new Date(vehicle[0].vehicleInsurance);
      const vehicleFC = new Date(vehicle[0].vehicleFC);
      const pollution = vehicle[0].pollutionDate
        ? new Date(vehicle[0].pollutionDate)
        : null;
      const vendor = vehicle[0].vendor;
      // console.log(pollution);
      if (vehicleNumber?.trim().length >= 3) {
        if (vehicleModel?.trim().length >= 2) {
          if (vehicleType?.trim().length >= 3) {
            if (vehicleInsurance?.toString().length == 55) {
              if (vehicleFC?.toString().length == 55) {
                if (pollution?.toString().length == 55) {
                  if (vehicleType == "Rent") {
                    if (vendor?.vendor_id > 0) {
                      if (
                        vehicleInsurance &&
                        vehicleInsurance.toString() !==
                          "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
                      ) {
                        const createVehicle = await axios.post(
                          `${api.api}/vehicle/createVehicle`,
                          {
                            vehicleModel,
                            vehicleNumber,
                            vehicleType,
                            vehicleInsurance,
                            vehicleFC,
                            vendor,
                            pollution,
                          }
                        );
                        if (createVehicle.data.message == "success") {
                          setVehicle([
                            {
                              vehicleModel: "",
                              vehicleNumber: "",
                              vehicleType: "",
                              vehicleInsurance: "",
                              vehicleFC: "",
                              vendor: "",
                              pollutionDate: "",
                            },
                          ]);
                          alert("Successfully Created");
                          navigate("/home/RentVehicleReport");
                        } else if (createVehicle.data.message == "unsuccess") {
                          validation();
                          alert("Already Existing Vehicle");
                        }
                      }
                    } else {
                      alert("Please Select Vendor Name");
                      validation();
                    }
                  } else if (vehicleType == "Own") {
                    if (
                      vehicleInsurance &&
                      vehicleInsurance.toString() !==
                        "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
                    ) {
                      const createVehicle = await axios.post(
                        `${api.api}/vehicle/createVehicle`,
                        {
                          vehicleModel,
                          vehicleNumber,
                          vehicleType,
                          vehicleInsurance,
                          vehicleFC,
                          vendor,
                          pollution,
                        }
                      );

                      if (createVehicle.data.message == "unsuccess") {
                        alert("Already Existing Vehicle");
                      } else if (createVehicle.data.message == "success") {
                        // setError("");
                        setVehicle([
                          {
                            vehicleModel: "",
                            vehicleNumber: "",
                            vehicleType: "",
                            vehicleInsurance: "",
                            vehicleFC: "",
                            pollutionDate: "",
                          },
                        ]);
                        alert("Successfully Created");
                        navigate("/home/OwnVehicleReport");
                      }
                    }
                  } else {
                    alert("please Select Vehicle Type");
                    validation();
                  }
                } else {
                  alert("please Select Vehicle Type");
                  validation();
                }
              } else {
                // setError("fc")
                validation();
              }
            } else {
              // setError("vehicleInsurance")
              validation();
            }
          } else {
            // setError("vehicleType")
            validation();
          }
        } else {
          // setError("model")
          validation();
        }
      } else {
        // setError("vehicleNO")
        validation();
      }

      // if(vehicleModel.trim().length>=2 && vehicleNumber.trim().length >=3  && vehicleType.trim().length>=3 && vehicleInsurance.trim().length ==10 && vehicleFC.trim().length ==10){
      //   if(vehicleType == "Rent"){
      //     // console.log(vendor);
      //     if(vendor?.vendor_id >0){
      //       const createVehicle=await axios.post(`${api.api}/vehicle/createVehicle`,{vehicleModel,vehicleNumber,vehicleType,vehicleInsurance,vehicleFC,vendor});
      //       if(createVehicle.data.message == "success"){
      //         setVehicle([{vehicleModel:"",vehicleNumber:"",vehicleType:"",vehicleInsurance:"",vehicleFC:"",vendor:""}]);
      //         alert("Successfully Created");
      //       }else if(createVehicle.data.message == "unsuccess"){
      //         alert("Already Existing Vehicle");
      //       }
      //     }else{
      //       alert("Please Select Vendor Name")
      //     }
      //   }else if(vehicleType == "Own"){

      //     const createVehicle=await axios.post(`${api.api}/vehicle/createVehicle`,{vehicleModel,vehicleNumber,vehicleType,vehicleInsurance,vehicleFC,vendor});

      //     if(createVehicle.data.message == "unsuccess"){

      //       alert("Already Existing Vehicle");

      //     }else if(createVehicle.data.message == "success"){
      //       setVehicle([{vehicleModel:"",vehicleNumber:"",vehicleType:"",vehicleInsurance:"",vehicleFC:""}]);
      //     alert("Successfully Created");
      //     }
      //   }else{
      //     alert("please Select Vehicle Type")
      //   }
      // }else{
      //   alert("Please Give Valid Information")
      // }
    }
  };

  // console.log(vehicle);
  // console.log(vehicleDetail);
  // console.log(vendorList);
  // console.log(new Date(vehicle[0]?.vehicleInsurance));

  const vendorSelection = async () => {
    const vendor = await axios.get(`${api.api}/vendor/findIsActiveAllVendor`);
    setVendorList(vendor.data.vendor);
  };

  const navigation = useLocation();

  let navigate = useNavigate();
  const updateValue = () => {
    if (vehicleDetail?.vehicle_id > 0) {
      if (navigation?.pathname == "/vehicleReport") {
        navigate("/home/vehicleUpdate");
      }
      if (navigation?.pathname == "/home/RentVehicleReport") {
        navigate("/home/RentVehicleUpdate");
      }
      // navigate('/home/OwnVehicleUpdate');

      let value = [...vehicle];
      value[0].vehicleModel = vehicleDetail.vehicle_model;
      value[0].vehicleNumber = vehicleDetail.vehicle_number
        ?.trim()
        .toUpperCase();
      value[0].vendor = vehicleDetail.VendorVehicleMappings
        ? vehicleDetail.VendorVehicleMappings[0]?.vendor
        : "";
      value[0].vehicleType = vehicleDetail.vehicle_type;
      value[0].vehicleInsurance = new Date(vehicleDetail.insurance);
      value[0].vehicleFC = new Date(vehicleDetail.fc);
      value[0].pollutionDate = vehicleDetail.pollution
        ? new Date(vehicleDetail.pollution)
        : "";
      setVehicle(value);
    }
  };

  const handleVendor = (e, n) => {
    // console.log(n);
    if (n && n.name) {
      // console.log(n);
      // console.log(`Selected Driver ID: ${n.name}`);
      let value = [...vehicle];
      value[0].vendor = n;
      setVehicle(value);
    } else {
      let value = [...vehicle];
      value[0].vendor = "";
      setVehicle(value);
    }
  };

  const validation = () => {
    const vehicleModel = vehicle[0].vehicleModel;
    const vehicleNumber = vehicle[0].vehicleNumber?.trim().toUpperCase();
    const vehicleType = vehicle[0].vehicleType;
    const vehicleInsurance = new Date(vehicle[0].vehicleInsurance);
    const vehicleFC = new Date(vehicle[0].vehicleFC);
    const vendor = vehicle[0].vendor;
    const pollutionDate = vehicle[0]?.pollutionDate;

    let value = { ...error };
    if (vehicleNumber?.trim().length >= 3) {
      value.number = "";
    } else {
      value.number = "vehicleNO";
    }

    if (pollutionDate?.toString()?.length == 55) {
      if (
        vehicle[0]?.pollutionDate?.toString() ==
        "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
      ) {
        value.pollutionDate = "pollutionDate";
      } else {
        value.pollutionDate = "";
      }
    } else {
      value.pollutionDate = "pollutionDate";
    }
    if (vehicle[0]?.pollutionDate == null) {
      value.pollutionDate = "pollutionDate";
      // console.log("null value")
    }

    if (vehicleModel?.trim().length >= 2) {
      value.model = "";
    } else {
      value.model = "model";
    }

    if (vehicleType == "Rent") {
      value.type = "";
    } else if (vehicleType == "Own") {
      value.type = "";
    } else {
      value.type = "vehicleType";
    }

    if (vehicleType?.trim().length >= 3) {
      value.type = "";
    } else {
      value.type = "vehicleType";
    }

    if (vehicleInsurance?.toString().length == 55) {
      if (
        vehicle[0]?.vehicleInsurance?.toString() ==
        "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
      ) {
        value.insurance = "vehicleInsurance";
      } else {
        value.insurance = "";
      }
    } else {
      value.insurance = "vehicleInsurance";
    }
    if (vehicle[0].vehicleInsurance == null) {
      value.insurance = "vehicleInsurance";
      // console.log("null value")
    }

    if (vehicleFC?.toString().length == 55) {
      value.fc = "";
    } else {
      value.fc = "fc";
    }
    if (vendor?.vendor_id > 0) {
      value.vendor = "";
    } else {
      value.vendor = "vendor";
    }
    setError(value);

    // console.log(error);
  };

  const formatDate = (date) => {
    const formattedDate = date
      ? new Date(date).toISOString().split("T")[0]
      : "";
    return formattedDate;
  };

  useEffect(() => {
    vendorSelection();
  }, []);

  useEffect(() => {
    updateValue();
    formatDate();
  }, [vehicleDetail]);

  const value = localStorage.getItem("taxinow")
    ? localStorage.getItem("taxinow")
    : "";

  useEffect(() => {
    if (localStorage.getItem("taxinow")) {
    } else {
      navigate("/home/customerReport");
    }
  }, [value]);

  // console.log(vehicle[0].vehicleNumber.toUpperCase());
  // console.log(vehicle);
  // console.log(error);
  // console.log((vehicle[0].vehicleInsurance == null));
  // console.log(new Date("Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"))
  return (
    <div className="col-12 grid-margin stretch-card">
      <div className="card">
        <div className="d-flex row justify-content-end">
          <div className="col-3">
            <Link
              to={"/home/OwnVehicleReport"}
              onClick={() => {
                back && back();
              }}
              className="btn btn-primary col-12"
            >
              Back
            </Link>
          </div>
        </div>
        <div className="card-body">
          <h1 className="page-title text-center fs-1">Vehicle Creation</h1>
          {/* <p className="card-description"> Basic form elements </p> */}
          <div className="forms-sample pt-2">
            <div className="row">
              <div className="form-group col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Vehicle Number
                </label>
                <input
                  type="text"
                  className="form-control fs-6 pl-2"
                  id="exampleInputEmail3"
                  value={vehicle[0].vehicleNumber}
                  onChange={(e) => {
                    let value = [...vehicle];
                    value[0].vehicleNumber = e.target.value;
                    setVehicle(value);
                  }}
                  disabled={vehicleDetail?.vehicle_id > 0}
                />
                <p className="text-danger">
                  {error.number == "vehicleNO" && "Please Enter Vehicle No"}
                </p>
              </div>
              <div className="form-group  col-md-6">
                <label for="exampleInputName1" className="text-black fs-5">
                  Vehicle Model
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputName1"
                  value={vehicle[0].vehicleModel}
                  onChange={(e) => {
                    let value = [...vehicle];
                    value[0].vehicleModel = e.target.value;
                    setVehicle(value);
                  }}
                />
                <p className="text-danger">
                  {error.model == "model" && "Please Enter Vehicle Model"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Vehicle Type
                </label>
                <select
                  className="form-control fs-6 form-select px-2"
                  id="exampleSelectGender"
                  value={vehicle[0].vehicleType}
                  onChange={(e) => {
                    let value = [...vehicle];
                    value[0].vehicleType = e.target.value;
                    setVehicle(value);
                  }}
                >
                  <option value="">Select</option>
                  <option value="Own">Own</option>
                  <option value="Rent">Rent</option>
                </select>
                <p className="text-danger p-0">
                  {error.type == "vehicleType" && "Please Select Vehicle Type"}
                </p>
              </div>
              {vehicle[0].vehicleType == "Rent" && (
                <div className="form-group col-md-6">
                  <label
                    for="exampleInputPassword4"
                    className="text-black fs-5"
                  >
                    Vendor Name
                  </label>
                  <Autocomplete
                    className="form-control fs-6"
                    id="exampleInputName1"
                    sx={{
                      display: "inline-block",
                      "& input": {
                        width: 200,
                        bgcolor: "background.paper",
                        color: (theme) =>
                          theme.palette.getContrastText(
                            theme.palette.background.paper
                          ),
                      },
                    }}
                    options={vendorList}
                    onChange={(event, newValue) =>
                      handleVendor(event, newValue)
                    }
                    value={vehicle[0]?.vendor}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <input
                          type="text"
                          id="exampleInputName1"
                          style={{ border: "none" }}
                          {...params.inputProps}
                        />
                      </div>
                    )}
                  />
                  {vehicle[0].vendor?.phone_number && (
                    <p className="text-success">
                      Vendor Mobile No:
                      <span className="text-black">
                        {vehicle[0].vendor?.phone_number}
                      </span>
                    </p>
                  )}
                  <p className="text-danger">
                    {error.vendor == "vendor" && "Please Select Vendor"}
                  </p>
                </div>
              )}

              <div className="form-group m-1 row">
                <label
                  for="exampleInputPassword4"
                  className="text-black fs-5 p-0"
                >
                  Insurance Date
                </label>
                {/* <input type="date" className="form-control fs-6" id="exampleInputPassword4" value={formatDate(vehicle[0].vehicleInsurance)} onChange={(e)=>{let value=[...vehicle]; value[0].vehicleInsurance=e.target.value; setVehicle(value) }} /> */}
                <DatePicker
                  className="form-control fs-6 p-0"
                  selected={vehicle[0].vehicleInsurance}
                  onChange={(date) => {
                    let value = [...vehicle];
                    value[0].vehicleInsurance = date;
                    setVehicle(value);
                  }}
                  // showTimeSelect
                  // timeFormat="HH:mm"
                  // timeIntervals={15}
                  // timeCaption="time"
                  dateFormat="MMMM d, yyyy"
                />
                <p className="text-danger p-0">
                  {error.insurance == "vehicleInsurance" &&
                    "Please Select Vehicle Insurance Date"}
                </p>
              </div>
            </div>

            <div className="form-group row m-1">
              <label
                for="exampleInputPassword4"
                className="text-black fs-5 p-0"
              >
                Vehicle FC Date
              </label>
              {/* <input type="date" className="form-control fs-6" id="exampleInputPassword4" value={formatDate(vehicle[0].vehicleFC)} onChange={(e)=>{let value=[...vehicle]; value[0].vehicleFC=e.target.value; setVehicle(value) }} /> */}
              <DatePicker
                className="form-control fs-6 p-0"
                selected={vehicle[0].vehicleFC}
                onChange={(date) => {
                  let value = [...vehicle];
                  value[0].vehicleFC = date;
                  setVehicle(value);
                }}
                // showTimeSelect
                // timeFormat="HH:mm"
                // timeIntervals={15}
                // timeCaption="time"
                dateFormat="MMMM d, yyyy"
              />
              <p className="text-danger p-0">
                {error.fc == "fc" && "Please Select Vehicle FC Date"}
              </p>
            </div>

            <div className="form-group row m-1">
              <label
                for="exampleInputPassword4"
                className="text-black fs-5 p-0"
              >
                Polution Date
              </label>
              {/* <input type="date" className="form-control fs-6" id="exampleInputPassword4" value={formatDate(vehicle[0].vehicleFC)} onChange={(e)=>{let value=[...vehicle]; value[0].vehicleFC=e.target.value; setVehicle(value) }} /> */}
              <DatePicker
                className="form-control fs-6 p-0"
                selected={vehicle[0].pollutionDate}
                onChange={(date) => {
                  let value = [...vehicle];
                  value[0].pollutionDate = date;
                  setVehicle(value);
                }}
                // showTimeSelect
                // timeFormat="HH:mm"
                // timeIntervals={15}
                // timeCaption="time"
                dateFormat="MMMM d, yyyy"
              />
              <p className="text-danger p-0">
                {error.pollutionDate == "pollutionDate" &&
                  "Please Select Vehicle PollutionDate Date"}
              </p>
            </div>

            <div className="d-flex col-12  justify-content-center">
              <span className="col-4">
                <button
                  type="submit"
                  onClick={() => {
                    newVehicle();
                  }}
                  className="btn btn-primary mr-2"
                >
                  Submit
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleCreation;
