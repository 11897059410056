import * as ReactDOM from "react-dom";
import * as React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "./invoice_Style.css";
import { CiLocationOn } from "react-icons/ci";
import { IoMdCall } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { FaAddressCard } from "react-icons/fa";
import { Col } from "react-bootstrap";

function Invoice({ invoiceDetail }) {
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  const originalDate = new Date(invoiceDetail?.item?.createdAt);
  const formattedDate = originalDate.toLocaleDateString("en-US", {
    month: "short", // Short month name
    day: "numeric", // Day of the month
    year: "numeric", // Full year
  });
  const bookingDate = new Date(invoiceDetail?.item?.booking_date);
  const bookedDate = bookingDate.toLocaleDateString("en-GB", {
    day: "2-digit", // Two-digit day
    month: "2-digit", // Two-digit month
    year: "numeric", // Full year
  });

  // const taxableValue=()=>{
  //  if(invoiceDetail?.payment?.cgst == null){
  //   const invoiceAmt=Number(invoiceDetail?.item?.bill_amount);
  //   return invoiceAmt;
  //  }else if(invoiceDetail?.payment?.cgst){
  //   const invoiceAmt=invoiceDetail.item?.bill_amount;
  //   return Math.round(Number(invoiceDetail.item?.bill_amount)*100/105);
  //  }
  // }
  // console.log(invoiceDetail);

  return (
    <div>
      <div className="col-xxl-3 col-12 col-xl-3 col-md-8 col-sm-12">
        <Col
          ref={printRef}
          xxl={12}
          xl={12}
          md={12}
          // xxl={12}
          // xl={12}
          // md={12}
          sm={12}
          // style={{ maxWidth: "100%" }}
          className="card card-xl-6 "
        >
          <div className="card">
            <div className="card-body col-12 p-0 m-0">
              <div className="container m-0 p-0 mt-1">
                <div className="row d-flex align-items-baseline mt-1">
                  {/* <h1 className="text-center mt-4">
                  TAXINIYAS SERVICES PRIVATE LIMITED
                </h1> */}
                  <img
                    class="m-0 p-0"
                    style={{ width: "100%" }}
                    src={require("../../../src/Images/QuotationNew.png")}
                  />
                  {/* <hr style={{ borderTop: "2px solid black" }} /> */}
                </div>

                <div className="container p-0">
                  <div className="col-md-12">
                    <div className="text-center">
                      <i
                        className="fab fa-mdb fa-4x ms-0"
                        style={{ color: "#5d9fc5" }}
                      ></i>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-xl-8 col-sm-8 col-8">
                      <ul className="list-unstyled p-0 m-0">
                        <li className="text-muted d-flex">
                          <h3
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "65%" }}
                          >
                            TO :{invoiceDetail?.item?.customer?.customer_name}{" "}
                          </h3>
                        </li>
                        <li className="text-muted">
                          <h6
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "65%" }}
                          >
                            <CiLocationOn /> &nbsp;
                            {invoiceDetail?.item?.customer?.address}
                          </h6>
                        </li>
                        <li className="text-muted">
                          <h6
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "65%" }}
                          >
                            {" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {invoiceDetail?.item?.customer?.city}
                          </h6>
                        </li>
                        <li className="text-muted">
                          <h6
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "65%" }}
                          >
                            <IoMdCall /> &nbsp;
                            {invoiceDetail?.item?.customer?.phone_number}
                          </h6>
                        </li>
                        <li className="text-muted">
                          <h6
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "65%" }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GST NO: &nbsp;
                            {invoiceDetail?.item?.customer?.gst_no
                              ? invoiceDetail?.item?.customer?.gst_no
                              : ""}
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="col-xl-4 col-sm-4 col-4">
                      <h5 className="text m-0 p-0" style={{ fontSize: "65%" }}>
                        Invoice
                      </h5>
                      <ul className="list-unstyled m-0">
                        <li className="text-" style={{ fontSize: "65%" }}>
                          <i
                            className="fas fa-circle"
                            style={{ color: "#84B0CA" }}
                          ></i>{" "}
                          <span className="fw-bold">No:</span>
                          {invoiceDetail?.item?.booking_id}
                        </li>
                        <li className="text" style={{ fontSize: "50%" }}>
                          <i
                            className="fas fa-circle"
                            style={{ color: "#84B0CA" }}
                          ></i>{" "}
                          <span className="fw-bold">Date: </span>
                          {formattedDate}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <hr
                    className="p-0 m-0"
                    style={{ borderTop: "2px solid black" }}
                  /> */}
                  {/* <div className="row mt-1">
                    <div className="col-xl-8">
                      <ul className="list-unstyled p-0 m-0">
                        <li className="text-muted d-flex">
                          <h3
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "50%" }}
                          >
                            TO :{invoiceDetail?.item?.customer?.customer_name}{" "}
                          </h3>
                        </li>
                        <li className="text-muted">
                          <h6
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "50%" }}
                          >
                            <CiLocationOn /> &nbsp;
                            {invoiceDetail?.item?.customer?.address}
                          </h6>
                        </li>
                        <li className="text-muted">
                          <h6
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "50%" }}
                          >
                            {" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {invoiceDetail?.item?.customer?.city}
                          </h6>
                        </li>
                        <li className="text-muted">
                          <h6
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "50%" }}
                          >
                            <IoMdCall /> &nbsp;
                            {invoiceDetail?.item?.customer?.phone_number}
                          </h6>
                        </li>
                        <li className="text-muted">
                          <h6
                            className="p-0 m-0"
                            style={{ color: "black", fontSize: "50%" }}
                          >
                            GST NO: &nbsp;
                            {invoiceDetail?.item?.customer?.gst_no
                              ? invoiceDetail?.item?.customer?.gst_no
                              : ""}
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="row mt-1 mx-1 justify-content-center table">
                    <table className="table  table-borderless p-0 m-0">
                      <thead
                        style={{ backgroundColor: "#84B0CA" }}
                        className="text-white"
                      >
                        <tr>
                          <th
                            style={{ fontSize: "50%" }}
                            scope="col"
                            className="text-center py-1 p-0 m-0"
                          >
                            DATE
                          </th>
                          <th
                            style={{ fontSize: "50%" }}
                            scope="col"
                            className="text-center py-1 p-0 m-0"
                          >
                            DESCRIPTION
                          </th>
                          <th
                            style={{ fontSize: "50%" }}
                            scope="col"
                            className="text-center py-1 p-0 m-0"
                          >
                            AMOUNT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="col-12">
                          <td
                            className="text-center col-2 "
                            style={{ fontSize: "50%" }}
                          >
                            {bookedDate}
                          </td>
                          <td className="col-8 tableDetail p-1">
                            <p className="p-0 m-0" style={{ fontSize: "50%" }}>
                              {invoiceDetail?.item?.pickup_location} To{" "}
                              {invoiceDetail?.item?.dropoff_location}
                            </p>
                            <p className="p-0 m-0" style={{ fontSize: "50%" }}>
                              {invoiceDetail?.item?.Notes}
                            </p>
                          </td>
                          <td
                            className="col-2  text-end"
                            style={{ fontSize: "50%" }}
                          >
                            ₹
                            {invoiceDetail.payment?.cgst
                              ? Math.round(
                                  Number(
                                    (Number(invoiceDetail.item?.bill_amount) *
                                      100) /
                                      112
                                  )
                                )
                              : invoiceDetail.item?.bill_amount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex row px-3">
                    <div className="col-xl-8 col-8 col-sm-8 p-0 m-0">
                      <p className=" m-0" style={{ fontSize: "50%" }}>
                        <h6 className="m-0" style={{ fontSize: "50%" }}>
                          OUR BANK DETAILS
                        </h6>
                        <div
                          className="d-flex  mt-1"
                          style={{ fontSize: "50%" }}
                        >
                          BANK NAME
                          <h6 className="m-0" style={{ fontSize: "100%" }}>
                            : HDFC BANK
                          </h6>
                        </div>
                        <div
                          className="d-flex mt-1"
                          style={{ fontSize: "50%" }}
                        >
                          BRANCH
                          <h6 className="m-0" style={{ fontSize: "100%" }}>
                            :DINDIGUL
                          </h6>
                        </div>
                        <div
                          className="d-flex mt-1 "
                          style={{ fontSize: "50%" }}
                        >
                          IFS CODE
                          <h6 className="m-0" style={{ fontSize: "100%" }}>
                            :HDFC0001850
                          </h6>
                        </div>
                        <div
                          className="d-flex mt-1"
                          style={{ fontSize: "50%" }}
                        >
                          ACCOUNT NO
                          <h6 className="m-0" style={{ fontSize: "100%" }}>
                            :50200082343
                          </h6>
                        </div>
                        <div
                          className="d-flex mt-1"
                          style={{ fontSize: "50%" }}
                        >
                          ACCOUNT TYPE
                          <h6 className="m-0" style={{ fontSize: "100%" }}>
                            : CASH & CREDIT
                          </h6>
                        </div>
                      </p>
                    </div>
                    <div className="col-xl-4 col-4 col-sm-4 p-0 m-0">
                      <li className="text-muted  m-0 d-flex justify-content-between">
                        <td className="text-black" style={{ fontSize: "50%" }}>
                          SubTotal
                        </td>
                        <td className="text-black" style={{ fontSize: "50%" }}>
                          ₹
                          {invoiceDetail.payment?.cgst
                            ? Math.round(
                                Number(
                                  (Number(invoiceDetail.item?.bill_amount) *
                                    100) /
                                    112
                                )
                              )
                            : invoiceDetail.item?.bill_amount}{" "}
                        </td>
                      </li>
                      <hr
                        className="my-1"
                        style={{ borderTop: "2px solid black" }}
                      />
                      <li className="text-muted  m-0 d-flex justify-content-between">
                        <td className="text-black" style={{ fontSize: "50%" }}>
                          CGST @ 6%
                        </td>
                        <td className="text-black" style={{ fontSize: "50%" }}>
                          ₹
                          {invoiceDetail.payment.cgst
                            ? invoiceDetail.payment.cgst
                            : 0}{" "}
                        </td>
                      </li>
                      <li className="text-muted  m-0 d-flex justify-content-between">
                        <td className="text-black" style={{ fontSize: "50%" }}>
                          SGST @ 6%
                        </td>
                        <td className="text-black" style={{ fontSize: "50%" }}>
                          ₹
                          {invoiceDetail.payment.sgst
                            ? invoiceDetail.payment.cgst
                            : 0}{" "}
                        </td>
                      </li>

                      <hr
                        className="my-1"
                        style={{ borderTop: "2px solid black" }}
                      />
                      <p className="text-black m-0 justify-content-between d-flex">
                        <h5
                          className="text-black m-0"
                          style={{ fontSize: "50%" }}
                        >
                          Total Amount
                        </h5>
                        <h5 className=" m-0" style={{ fontSize: "50%" }}>
                          ₹{invoiceDetail?.item?.bill_amount}
                        </h5>
                      </p>
                    </div>
                  </div>
                  <hr
                    className="my-1"
                    style={{ borderTop: "2px solid black" }}
                  />
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6">
                      <h6 className="text-center" style={{ fontSize: "35%" }}>
                        TAXINIYAS SERVICES PRIVATE TIMITED
                      </h6>
                      <h6 className="text-center" style={{ fontSize: "35%" }}>
                        Authorized Signature
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>
      <div className="d-flex col-12 col-xl-4 c0l-sm-12 row justify-content-center">
        <button
          type="button"
          className="btn col-3 col-xl-2 col-sm-3"
          onClick={handleDownloadPdf}
        >
          Print
        </button>
      </div>
    </div>
  );
}

export default Invoice;
