import React from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { MdOutlinePictureAsPdf } from "react-icons/md";

import { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Dialog, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import { api } from "../api";
import { TbFileInvoice } from "react-icons/tb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { BiSolidSelectMultiple } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TripPlan = () => {
  const [data, setData] = useState([]);

  const billingData = async () => {
    const apiData = await axios.get(`${api.api}/booking/latestBookintReport`);
    const value = apiData.data.booking;
    const database = value.map((item) => {
      return {
        item,
        payment: item?.payments[0] || [],
        diesel: item?.expenses[0] || [],
        driverBata: item?.expenses[1] || [],
      };
    });
    setData(database);
  };

  const ConfirmOrder = async (rowData) => {
    const order_status = rowData?.item?.order_status == true ? false : true;
    const id = rowData?.item?.booking_id;
    const check = localStorage.getItem("login");
    if (check.toLowerCase().includes("regular")) {
      const customerActive = await axios.post(
        `${api.api}/booking/confirmOrders`,
        { order_status, id }
      );
      billingData();
    } else {
      alert("Booking Confirm Only for Authorized Person");
    }
  };

  // console.log(data);
  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("item.booking_date", {
      id: "Date",
      header: "Trip Date",
      filterVariant: "date-range",
      size: 20,
      accessorFn: (originalRow) => new Date(originalRow.item.booking_date),
      Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
    }),
    columnHelper.accessor("item.customer.customer_name", {
      header: "Customer Name",
      size: 20,
    }),
    columnHelper.accessor("item.customer.phone_number", {
      header: "MobileNo",
      size: 20,
    }),
    columnHelper.accessor("item.vehicle.vehicle_number", {
      header: "Vehicle No",
      size: 20,
    }),
    // columnHelper.accessor('item.driver.phone_number', {
    //   header: 'MobileNo',
    //   size: 20,
    // }),
    columnHelper.accessor("item.pickup_location", {
      header: "Pickup",
      size: 40,
    }),
    columnHelper.accessor("item.dropoff_location", {
      header: "Drop",
      size: 40,
    }),

    columnHelper.accessor("item.order_status", {
      header: "Confirm Order",
      size: 20,
      Cell: ({ row }, rowIndex) => {
        // console.log(row.original.item);
        return (
          <Box className="p-0">
            {row.original.item.order_status !== true ? (
              <IconButton
                variant="outlined"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Pending Order"
                onClick={() => ConfirmOrder(row.original)}
              >
                <ImCancelCircle className="text-danger" />
              </IconButton>
            ) : (
              <IconButton
                variant="outlined"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Confirm Order"
                onClick={() => ConfirmOrder(row.original)}
              >
                <BiSolidSelectMultiple className="text-primary" />
              </IconButton>
            )}
          </Box>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const value = rowData.map((n) => {
      return n;
    });
    // console.log(value);
    const item = value.map((single) => {
      return {
        "Invoice No": single.item.booking_id,
        "Booking Date & Time": new Date(
          single.item.booking_date
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        "Customer Name ": single.item.customer.customer_name,
        "Customer Mobile No ": single.item.customer.phone_number,
        "Customer Address ": single.item.customer.address,
        "Vehicle No": single.item.vehicle.vehicle_number,
        "Vehicle Type": single.item.vehicle.vehicle_type,
        "Driver Name ": single.item.driver.driver_name,
        "Driver Mobile No ": single.item.driver.phone_number,
        "Drop Date & Time": new Date(single.item.dropping_time).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Pickup Location": single.item.pickup_location,
        "Drop Location": single.item.dropoff_location,
      };
    });
    const csv = generateCsv(csvConfig)(item);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const value = data.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Invoice No": single.item.booking_id,
        "Booking Date & Time": new Date(
          single.item.booking_date
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        "Customer Name ": single.item.customer.customer_name,
        "Customer Mobile No ": single.item.customer.phone_number,
        "Customer Address ": single.item.customer.address,
        "Vehicle No": single.item.vehicle.vehicle_number,
        "Vehicle Type": single.item.vehicle.vehicle_type,
        "Driver Name ": single.item.driver.driver_name,
        "Driver Mobile No ": single.item.driver.phone_number,
        "Drop Date & Time": new Date(single.item.dropping_time).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Pickup Location": single.item.pickup_location,
        "Drop Location": single.item.dropoff_location,
      };
    });
    const csv = generateCsv(csvConfig)(item);
    download(csvConfig)(csv);
  };

  const handleExportRowsPDF = (rows) => {
    const rowData = rows.map((row) => row.original);
    // console.log(rowData);
    const value = rowData.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Invoice No": single.item.booking_id,
        "Booking Date & Time": new Date(
          single.item.booking_date
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        "Customer Name ": single.item.customer.customer_name,
        "Customer Mobile No ": single.item.customer.phone_number,
        "Customer Address ": single.item.customer.address,
        "Vehicle No": single.item.vehicle.vehicle_number,
        "Vehicle Type": single.item.vehicle.vehicle_type,
        "Driver Name ": single.item.driver.driver_name,
        "Driver Mobile No ": single.item.driver.phone_number,
        "Drop Date & Time": new Date(single.item.dropping_time).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Pickup Location": single.item.pickup_location,
        "Drop Location": single.item.dropoff_location,
      };
    });

    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
      format: [200, 400],
    });
    const tableData = item.map((row) => Object.values(row));
    const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });
    // console.log(tableHeaders);
    doc.save("Booking_Report.pdf");
  };

  const handleExportAllRowsPDF = (rows) => {
    const rowData = data.map((row) => row);
    // console.log(rowData);
    const value = rowData.map((n) => {
      return n;
    });

    const item = value.map((single) => {
      return {
        "Invoice No": single.item.booking_id,
        "Booking Date & Time": new Date(
          single.item.booking_date
        ).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        "Customer Name ": single.item.customer.customer_name,
        "Customer Mobile No ": single.item.customer.phone_number,
        "Customer Address ": single.item.customer.address,
        "Vehicle No": single.item.vehicle.vehicle_number,
        "Vehicle Type": single.item.vehicle.vehicle_type,
        "Driver Name ": single.item.driver.driver_name,
        "Driver Mobile No ": single.item.driver.phone_number,
        "Drop Date & Time": new Date(single.item.dropping_time).toLocaleString(
          "en-GB",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        ),
        "Pickup Location": single.item.pickup_location,
        "Drop Location": single.item.dropoff_location,
      };
    });

    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
      format: [200, 400],
    });
    const tableData = item.map((row) => Object.values(row));
    const tableHeaders = item.length > 0 ? Object.keys(item[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });
    // console.log(tableHeaders);
    doc.save("Booking_Report.pdf");
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 10, //adjust the size of the row select column
        grow: false, //new in v2.8 (default is false for this column)
      },
      "mrt-row-numbers": {
        size: 10,
        grow: true, //new in v2.8 (allow this column to grow to fill in remaining space)
      },
    },
    enableRowNumbers: true,
    // enableRowSelection: true,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          All
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          Filter
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportAllRowsPDF()}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          Filter
        </Button>
      </Box>
    ),
  });

  useEffect(() => {
    billingData();
  }, []);

  return (
    <div>
      <div>
        <h1 className="text-start text-danger">Trip Booking Plans</h1>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={table} />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default TripPlan;
