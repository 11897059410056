import React from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { MdOutlinePictureAsPdf } from "react-icons/md";

import { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Dialog, TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import DriverCreation from "../../Forms/DriverCreation";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import { api } from "../../api";
import { FaRegCalendarAlt } from "react-icons/fa";
import DriverAttendance from "../../Attendance/DriverAttendance";

import { useHistory, useLocation } from "react-router-dom";
import { Key } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DriverReport = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editDriver, setDriver] = useState();
  const [driverDetail, setDriverDetail] = useState([]);
  const [update, setUpdate] = useState();
  const [attendanceDetail, setAttendanceDetail] = useState();
  const [editData, setEditData] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
    density: "compact",
  });
  const [driverNavigation, setDriverNavigation] = useState("");

  const handleOpen = (data) => {
    setOpen(true);
    setEditData(data);
  };
  const handleClose = () => setOpen(false);

  let navigate = useNavigate();
  let params = useParams();
  // console.log(driverNavigation=="http://localhost:3000/home/driverReport");
  // console.log(editDriver?.driver_id);
  const checkPage = () => {
    // if(driverNavigation=="http://localhost:3000/home/driverReport"){
    //   setDriver("");
    //   setDriverDetail("")
    // }
    // console.log("Working")
  };

  // const initialChange=()=>{
  //   setDriver("");
  // }

  const handleEdit = (rowData) => {
    //  console.log(rowData);
    navigate(`/home/driverUpdate`);
    setDriver(rowData);
    setDriverDetail(rowData);
  };

  const handleSwitchChange = async (e) => {
    const status = e.is_license_issue;
    const value = status == true ? false : true;
    const id = e.driver_id;
    const driverLicense = await axios.post(`${api.api}/driver/licenseIssue`, {
      value,
      id,
    });
    setUpdate(id);
    // console.log(driverLicense);
  };

  const handleDelete = async (rowData) => {
    const status = rowData.is_active == true ? false : true;
    setDriverDetail(status);
    const id = rowData.driver_id;
    // console.log(rowData);
    // console.log(id);
    // console.log(status);
    const driver = await axios.post(`${api.api}/driver/driverActive`, {
      status,
      id,
    });
    // console.log(driver);
    setDriverDetail(id);
  };

  const driverData = async () => {
    const apiData = await axios.get(`${api.api}/driver/allDriver`);
    const attendance = await axios.get(
      `${api.api}/driver/driverAttendanceOneMonth`
    );

    const drivers = apiData.data.driver;
    const driverAttendanceData = attendance.data.driver;

    // Create a map to quickly look up attendance data by driver_id
    const attendanceMap = new Map(
      driverAttendanceData.map((entry) => [entry.driver_id, entry])
    );

    // Combine data from both API calls
    const combinedData = drivers.map((driver) => {
      const attendanceData = attendanceMap.get(driver.driver_id) || {
        totalStatus: "0",
      };

      return {
        ...driver,
        driver_attendances: {
          driver_id: driver.driver_id,
          totalStatus: attendanceData.totalStatus,
        },
      };
    });

    setData(combinedData);

    // console.log(combinedData);
  };

  const handleCalendar = async (e) => {
    navigate(`/home/driverAttendance`);
    setAttendanceDetail(e.driver_id);
    setDriver(e);
    setDriverDetail(e);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    // columnHelper.accessor('driver_id', {
    //   header: 'ID',
    //   size: 40,
    // }),
    columnHelper.accessor("driver_name", {
      header: "Name",
      size: 40,
    }),
    columnHelper.accessor("phone_number", {
      header: "Mobile No",
      size: 40,
    }),
    // columnHelper.accessor('license_number', {
    //   header: 'License No',
    //   size: 40,
    // }),
    // columnHelper.accessor('bageno', {
    //   header: 'Batch No',
    //   size: 40,
    // }),
    columnHelper.accessor("temporary_address", {
      header: "Address",
      size: 40,
    }),
    // columnHelper.accessor('permanent_address', {
    //   header: 'Permanent Address',
    //   size: 40,
    // }),
    columnHelper.accessor("is_active", {
      accessor: "action",
      header: "Working Days",
      size: 40,
      Cell: ({ row }, rowIndex) => {
        // console.log(row);
        return (
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                {row.original?.driver_attendances?.totalStatus}
              </Typography>
              <IconButton
                onClick={() => {
                  handleCalendar(row.original);
                }}
              >
                <FaRegCalendarAlt
                  className="text-secondary"
                  inputProps={{ "aria-label": "ant design" }}
                />
              </IconButton>
              {/* <Typography></Typography> */}
            </Stack>
          </Box>
        );
      },
    }),
    columnHelper.accessor("is_active", {
      accessor: "action",
      header: "License Issue",
      size: 40,
      Cell: ({ row }, rowIndex) => {
        // console.log(row);
        return (
          <Box key={row.id}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography></Typography>
              <Switch
                className="fs-6"
                defaultChecked={row.original.is_license_issue === true}
                onChange={() => {
                  handleSwitchChange(row.original);
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography>Yes</Typography>
            </Stack>
          </Box>
        );
      },
    }),
    columnHelper.accessor("is_active", {
      accessor: "action",
      header: "Action",
      size: 160,
      Cell: ({ row }, rowIndex) => {
        // console.log(row);
        return (
          <Box>
            <IconButton
              variant="outlined"
              data-toggle="tooltip"
              data-placement="bottom"
              title="View"
            >
              <VisibilityIcon
                className="text-primary fs-6"
                onClick={() => handleOpen(row)}
              />
            </IconButton>
            {row.original.is_active === true && (
              <IconButton
                variant="outlined"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Edit"
                onClick={() => handleEdit(row.original)}
              >
                <EditIcon className="fs-6" />
              </IconButton>
            )}
            <IconButton
              variant="outlined"
              data-toggle="tooltip"
              data-placement="bottom"
              title={row.original.is_active == true ? "Delete" : "Active"}
              onClick={() => handleDelete(row.original)}
            >
              {row.original.is_active === true ? (
                <CancelIcon className="text-danger fs-6" />
              ) : (
                <CheckCircleIcon className="text-success fs-6" />
              )}
            </IconButton>
          </Box>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const value = rowData.map((item) => {
      return {
        ID: item.driver_id,
        "Driver Name": item.driver_name,
        "Driver License No": item.license_number,
        "Bage No": item.bageno,
        "Driver Mobile No": item.phone_number,
        "Temporary Address": item.temporary_address,
        "Permanent Address": item.permanent_address,
        "Monthly Attendance": item.driver_attendances.totalStatus,
      };
    });
    // console.log(value);
    const csv = generateCsv(csvConfig)(value);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const value = data.map((item) => {
      return {
        ID: item.driver_id,
        "Driver Name": item.driver_name,
        "Driver License No": item.license_number,
        "Bage No": item.bageno,
        "Driver Mobile No": item.phone_number,
        "Temporary Address": item.temporary_address,
        "Permanent Address": item.permanent_address,
        "Monthly Attendance": item.driver_attendances.totalStatus,
      };
    });
    const csv = generateCsv(csvConfig)(value);
    download(csvConfig)(csv);
  };

  const handleExportRowsPDF = (rows) => {
    const rowData = rows.map((row) => row.original);
    const value = rowData.map((item) => {
      return {
        ID: item.driver_id,
        "Driver Name": item.driver_name,
        "Driver License No": item.license_number,
        "Bage No": item.bageno,
        "Driver Mobile No": item.phone_number,
        "Temporary Address": item.temporary_address,
        "Permanent Address": item.permanent_address,
        "Monthly Attendance": item.driver_attendances.totalStatus,
      };
    });
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
    });
    const tableData = value.map((row) => Object.values(row));
    const tableHeaders = value.length > 0 ? Object.keys(value[0]) : [];

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });
    // console.log(rowData);
    doc.save("Driver_Report.pdf");
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // enableRowSelection: true,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,
    rowPinningDisplayMode: "select-sticky",
    initialState: {
      pagination: { pageSize: 100, pageIndex: 0 },
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          Export
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<BackupTableTwoToneIcon className="text-success" />}
        >
          Filter
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRowsPDF(table.getPrePaginationRowModel().rows)
          }
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
          startIcon={<MdOutlinePictureAsPdf className="text-danger " />}
        >
          Filter
        </Button>
        <Button>
          <Link to="/home/driverCreation">Create</Link>
        </Button>
      </Box>
    ),
  });
  //

  // console.log(driverDetail);
  useEffect(() => {
    // console.log("Fine");
    setDriver("");
    driverData();
    setDriverNavigation(window.location.href);
  }, []);

  useEffect(() => {
    checkPage();
  }, [driverNavigation]);

  useEffect(() => {
    driverData();
  }, [driverDetail]);

  useEffect(() => {
    driverData();
  }, [update]);

  useEffect(() => {
    driverData();
  }, [driverDetail == false]);

  useEffect(() => {
    if (editDriver == false) {
      navigate("/home/driverReport");
    }
  }, [editDriver]);

  return (
    <div>
      {editDriver ? (
        <div>
          <div className="d-flex col-12 justify-content-end">
            <button
              className="btn btn-primary col-2"
              onClick={() => {
                setDriver(false);
                setAttendanceDetail("");
              }}
            >
              Back
            </button>
          </div>
          {attendanceDetail ? (
            <DriverAttendance driver={driverDetail} />
          ) : (
            <DriverCreation
              driverDetail={driverDetail}
              setDriverDetail={setDriverDetail}
              back={setDriver}
            />
          )}
        </div>
      ) : (
        <div>
          <h1 className="text-center">Driver</h1>
          <MaterialReactTable table={table} />
        </div>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <DialogContent>
              <button
                onClick={handleClose}
                className="btn text-dark bg-white btn-outline-light fs-5 col-2 p-2 m-0 mt-2"
                style={{ position: "absolute", top: "1px", right: "1px" }}
              >
                X
              </button>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Driver name"
                type="text"
                fullWidth
                defaultValue={editData.original.driver_name}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="Driver Mobile No"
                type="email"
                fullWidth
                defaultValue={editData.original.phone_number}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="Driver Temporary Address"
                type="address"
                fullWidth
                defaultValue={editData.original.temporary_address}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="Driver Permanent Address"
                type="address"
                fullWidth
                defaultValue={editData.original.permanent_address}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                id="mobile"
                label="License No"
                type="city"
                fullWidth
                defaultValue={editData.original.license_number}
                InputProps={{
                  readOnly: true,
                }}
              />
              {/* <TextField
            margin="dense"
            id="mobile"
            label="Customer Reward Points"
            type="text"
            fullWidth
            defaultValue={row.original.item.city}
            InputProps={{
              readOnly: true,
            }}
          /> */}
              {/* <Button onClick={()=>{console.log(row.original.item)}}>click</Button> */}
            </DialogContent>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default DriverReport;
