import axios from "axios";
import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { api } from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";

const DriverCreation = ({ driverDetail, setDriverDetail, back }) => {
  const [driver, setDriver] = useState([
    {
      driverName: "",
      driverMobile: "",
      driverLicenseNumber: "",
      driverLicenseIssue: false,
      driverBadgeNo: "",
      driverTemporaryAddress: "",
      driverPermanentAddress: "",
    },
  ]);
  const [error, setError] = useState({
    name: "",
    mobile: "",
    license: "",
    badge: "",
    temporary: "",
    permanent: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const newDriver = async () => {
    // Assuming 'driver' is an array
    if (driverDetail?.driver_id > 0) {
      if (driver && driver.length > 0) {
        const driverName = driver[0].driverName;
        const driverMobile = driver[0].driverMobile;
        const driverLicenseNumber = driver[0].driverLicenseNumber;
        const driverBadgeNo = driver[0].driverBadgeNo;

        // Ensure that properties are defined before accessing their 'length'
        if (
          driverName &&
          driverMobile &&
          driverLicenseNumber &&
          driverBadgeNo
        ) {
          function isValidMobileNumber(driverMobile) {
            const mobileNumberRegex = /^[6-9]\d{9}$/;
            return mobileNumberRegex.test(driverMobile);
          }

          if (isValidMobileNumber(driverMobile)) {
            if (
              driverName.length >= 3 &&
              driverLicenseNumber.length >= 3 &&
              driverBadgeNo.length >= 3
            ) {
              const value = driver[0];
              const driver_name = value.driverName;
              const license_number = value.driverLicenseNumber;
              const bageno = value.driverBadgeNo;
              const is_license_issue = value.driverLicenseIssue;
              const phone_number = value.driverMobile;
              const temporary_address = value.driverTemporaryAddress;
              const permanent_address = value.driverPermanentAddress;
              try {
                const id = driverDetail.driver_id;
                const createDriver = await axios.post(
                  `${api.api}/driver/updateDriver`,
                  {
                    driver_name,
                    license_number,
                    bageno,
                    is_license_issue,
                    phone_number,
                    temporary_address,
                    permanent_address,
                    id,
                  }
                );
                // console.log(createDriver);
                setDriverDetail("Success");
                alert("Successfully Updated");
                back(false);
              } catch (error) {
                console.error("Error creating driver:", error);
                alert("An error occurred while creating the driver.");
              }
            } else {
              validation();
              alert("Please Give Valid Information");
            }
          } else {
            validation();
            alert("Invalid mobile number");
          }
        } else {
          validation();
          alert("Please Give Valid Information");
        }
      }
    } else {
      if (driver && driver.length > 0) {
        validation();
        const driverName = driver[0].driverName;
        const driverMobile = driver[0].driverMobile;
        const driverLicenseNumber = driver[0].driverLicenseNumber;
        const driverBadgeNo = driver[0].driverBadgeNo;

        // Ensure that properties are defined before accessing their 'length'
        // if (driverName && driverMobile && driverLicenseNumber && driverBadgeNo) {
        function isValidMobileNumber(driverMobile) {
          const mobileNumberRegex = /^[6-9]\d{9}$/;
          return mobileNumberRegex.test(driverMobile);
        }

        if (driverName.trim().length >= 3) {
          if (isValidMobileNumber(driverMobile)) {
            if (driverLicenseNumber.trim().length >= 3) {
              if (driverBadgeNo.trim().length >= 3) {
                if (driver[0].driverTemporaryAddress.trim().length > 2) {
                  if (driver[0].driverPermanentAddress.trim().length > 2) {
                    const value = driver[0];
                    const driver_name = value.driverName;
                    const license_number = value.driverLicenseNumber;
                    const bageno = value.driverBadgeNo;
                    const is_license_issue = value.driverLicenseIssue;
                    const phone_number = value.driverMobile;
                    const temporary_address = value.driverTemporaryAddress;
                    const permanent_address = value.driverPermanentAddress;
                    try {
                      if (buttonDisabled == false) {
                        setButtonDisabled(true);
                        const createDriver = await axios.post(
                          `${api.api}/driver/createDriver`,
                          {
                            driver_name,
                            license_number,
                            bageno,
                            is_license_issue,
                            phone_number,
                            temporary_address,
                            permanent_address,
                          }
                        );
                        // console.log(createDriver);
                        if (createDriver.data.message == "unsuccess") {
                          alert("Already Existing Driver");

                          // setError("");
                        } else {
                          alert("Successfully Created");
                          // setError("");
                          setDriver([
                            {
                              driverName: "",
                              driverMobile: "",
                              driverLicenseNumber: "",
                              driverLicenseIssue: false,
                              driverBadgeNo: "",
                              driverTemporaryAddress: "",
                              driverPermanentAddress: "",
                            },
                          ]);
                          navigate("/home/driverReport");
                        }
                      }
                    } catch (error) {
                      console.error("Error creating driver:", error);
                      alert("Please Enter Valid Details.");
                    }
                  } else {
                    // setError("permanent")
                    validation();
                  }
                } else {
                  validation();
                  // setError("temporary")
                }
              } else {
                validation();
                // setError("badge")
              }
            } else {
              validation();
              // setError("license")
            }
          } else {
            validation();
            // setError("mobile")
          }
        } else {
          validation();
          // setError("name")
        }

        // } else {
        //   alert("Please Give Valid Information");
        // }
      } else {
        validation();
        // alert("Please Give Valid Information");
      }
    }
  };

  const validation = () => {
    const driverName = driver[0].driverName;
    const driverMobile = driver[0].driverMobile;
    const driverLicenseNumber = driver[0].driverLicenseNumber;
    const driverBadgeNo = driver[0].driverBadgeNo;

    function isValidMobileNumber(driverMobile) {
      const mobileNumberRegex = /^[6-9]\d{9}$/;
      return mobileNumberRegex.test(driverMobile);
    }

    let value = { ...error };
    if (driverName.trim().length >= 3) {
      value.name = "";
    } else {
      value.name = "name";
    }

    if (isValidMobileNumber(driverMobile)) {
      value.mobile = "";
    } else {
      value.mobile = "mobile";
    }

    if (driverLicenseNumber.trim().length >= 3) {
      value.license = "";
    } else {
      value.license = "license";
    }

    if (driverBadgeNo.trim().length >= 3) {
      value.badge = "";
    } else {
      value.badge = "badge";
    }

    if (driver[0].driverTemporaryAddress.trim().length > 2) {
      value.temporary = "";
    } else {
      value.temporary = "temporary";
    }

    if (driver[0].driverPermanentAddress.trim().length > 2) {
      value.permanent = "";
    } else {
      value.permanent = "permanent";
    }
    setError(value);
  };

  let navigate = useNavigate();
  const updateValue = () => {
    if (driverDetail?.driver_id > 0) {
      navigate("/home/driverUpdate");
      let value = [...driver];
      value[0].driverName = driverDetail.driver_name;
      value[0].driverMobile = driverDetail.phone_number;
      value[0].driverLicenseNumber = driverDetail.license_number;
      value[0].driverLicenseIssue = driverDetail.is_license_issue;
      value[0].driverBadgeNo = driverDetail.bageno;
      value[0].driverTemporaryAddress = driverDetail.temporary_address;
      value[0].driverPermanentAddress = driverDetail.permanent_address;
      setDriver(value);
    }
  };

  useEffect(() => {
    updateValue();
  }, [driverDetail]);

  const value = localStorage.getItem("taxinow")
    ? localStorage.getItem("taxinow")
    : "";

  useEffect(() => {
    if (localStorage.getItem("taxinow")) {
    } else {
      navigate("/home/customerReport");
    }
  }, [value]);

  // console.log('Initial Value:', driver[0].driverLicenseIssue);

  // console.log(driver[0]);
  // console.log(driverDetail);
  return (
    <div className="col-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h1 className="page-title text-center fs-1">
            {driverDetail?.driver_id ? "Driver Update" : "Driver Creation"}
          </h1>
          {/* <p className="card-description"> Basic form elements </p> */}
          <div className="forms-sample pt-2">
            <div className="row">
              <div className="form-group  col-md-6">
                <label for="exampleInputName1" className="text-black fs-5">
                  {" "}
                  Name
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputName1"
                  value={driver[0].driverName}
                  onChange={(e) => {
                    let value = [...driver];
                    value[0].driverName = e.target.value.replace(
                      /[^A-Za-z ]/g,
                      ""
                    );
                    setDriver(value);
                  }}
                />
                <p className="text-danger">
                  {error.name == "name" && "Please Enter Driver Name"}
                </p>
              </div>
              <div className="form-group  col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Mobile
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={driver[0].driverMobile}
                  onChange={(e) => {
                    let value = [...driver];
                    value[0].driverMobile =
                      e.target.value === "0"
                        ? ""
                        : e.target.value
                            .replace(/[eE]/g, "")
                            .replace(/[^0-9]|(?<=\d)-(?=\d)/g, "")
                            .replace(/^0/, "");
                    setDriver(value);
                  }}
                  disabled={driverDetail?.phone_number}
                />
                <p className="text-danger">
                  {error.mobile == "mobile" &&
                    "Please Enter Valid Driver Mobile No"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  {" "}
                  License Number
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={driver[0].driverLicenseNumber}
                  onChange={(e) => {
                    let value = [...driver];
                    value[0].driverLicenseNumber = e.target.value;
                    setDriver(value);
                  }}
                />
                <p className="text-danger">
                  {error.license == "license" &&
                    "Please Enter Driver License Number"}
                </p>
              </div>
              <div className="form-group col-md-6">
                <label for="exampleInputEmail3" className="text-black fs-5">
                  Badge Number
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputEmail3"
                  value={driver[0].driverBadgeNo}
                  onChange={(e) => {
                    let value = [...driver];
                    value[0].driverBadgeNo = e.target.value;
                    setDriver(value);
                  }}
                />
                <p className="text-danger">
                  {error.badge == "badge" && "Please Enter Driver Badge Number"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Permanent Address
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputPassword4"
                  value={driver[0].driverPermanentAddress}
                  onChange={(e) => {
                    let value = [...driver];
                    value[0].driverPermanentAddress = e.target.value;
                    setDriver(value);
                  }}
                />
                <p className="text-danger">
                  {error.permanent == "permanent" &&
                    "Please Enter Driver Permanent Address"}
                </p>
              </div>

              <div className="form-group col-md-6">
                <label for="exampleInputPassword4" className="text-black fs-5">
                  Temporary Address
                </label>
                <input
                  type="text"
                  className="form-control fs-6"
                  id="exampleInputPassword4"
                  value={driver[0].driverTemporaryAddress}
                  onChange={(e) => {
                    let value = [...driver];
                    value[0].driverTemporaryAddress = e.target.value;
                    setDriver(value);
                  }}
                />
                <p className="text-danger">
                  {error.temporary == "temporary" &&
                    "Please Enter Driver Temporary Address"}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label
                for="exampleInputPassword4"
                className="text-black fs-5 mt-2"
              >
                License Issue{" "}
              </label>
              <label
                for="exampleInputPassword4"
                className="ml-4 text-black fs-5"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    defaultChecked={
                      driverDetail?.is_license_issue == true
                        ? true
                        : driver[0].driverLicenseIssue == true
                    }
                    onChange={(e) => {
                      let value = [...driver];
                      value[0].driverLicenseIssue =
                        !driver[0].driverLicenseIssue;
                      setDriver(value);
                    }}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </label>
              {/* <input type="text" className="form-control fs-6" id="exampleInputPassword4" value={driver[0].driverLicenseIssue} onChange={(e)=>{let value=[...driver]; value[0].driverLicenseIssue=e.target.value; setDriver(value) }} /> */}
            </div>

            <div className="d-flex col-12  justify-content-center">
              <span className="col-4">
                <button
                  type="submit"
                  onClick={() => {
                    newDriver();
                  }}
                  className="btn btn-primary mr-2"
                  disabled={buttonDisabled ? "disabled" : ""}
                >
                  Submit
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverCreation;

// const [driver,setDriver]=useState([{driverName:"",driverMobile:"",driverLicenseNumber:"",driverLicenseIssue:"",driverBageNo:"",driverTemporaryAddress:"",driverPermanentAddress:""}]);
